import { useAuth0 } from "@auth0/auth0-react";
import { Fragment, useCallback, useContext, useEffect, useState } from "react";
import { CurrentUserContext } from "../../../contexts/userContext";
import Loader from "../../../components/Loader";
import httpsClient, { addAccessTokenInterceptor } from "../../../config/https";
import { useTranslation } from "react-i18next";
import { CalendarIcon, ChevronRightIcon, PencilIcon, CheckIcon, ChevronDownIcon } from "@heroicons/react/24/solid";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import "moment/locale/de";
import { Menu, Tab, TabGroup, TabList, TabPanel, TabPanels, Transition } from "@headlessui/react";
import { MessageType, useMessagesContext } from "../../../contexts/messagesContext";
import ConfirmationDialog, { ConfirmationDialogType } from "../../../components/ConfirmationDialog";
import Modal from "../../../components/Modal";
import TopicThesisProjects from "../../topics/TopicThesisProjects";
import TopicSupervisorInterests from "../../topics/TopicSupervisorInterests";

export default function AdminTopic() {
  const { currentUser } = useContext(CurrentUserContext);
  const { id } = useParams();
  const { user, getAccessTokenSilently, isAuthenticated, isLoading } = useAuth0();
  const [isFetching, setIsFetching] = useState(true);
  const [token, setToken] = useState("");
  const [topic, setTopic] = useState<any>({});
  const [topics, setTopics] = useState([]);
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const currentLanguage = i18n.language;
  const location = useLocation();
  const isCompanyRoute = location.pathname.includes("/company/topics");
  const { addMessage } = useMessagesContext();
  const [topicIdToDelete, setTopicIdToDelete] = useState();
  const [isDeleteTopicConfirmationOpen, setIsDeleteTopicConfirmationOpen] = useState<boolean>(false);

  const fetchToken = useCallback(async () => {
    const data = await getAccessTokenSilently();
    setToken(data);
  }, []);

  const getTopic = async (id: string) => {
    setIsFetching(true);
    await httpsClient
      .get(`/api/v2/admin/topics/${id}`)
      .then((response) => {
        setTopic(response.data);
        setIsFetching(false);
      })
      .catch((error) => {
        setIsFetching(false);
        if (error.response && error.response.status === 403) {
          navigate("/error/forbidden");
        }
        console.error("Error fetching data:", error);
      });
  };

  const deleteTopic = async () => {
    try {
      await httpsClient.delete(`/api/v1/topics/${topicIdToDelete}`);
      addMessage({
        type: MessageType.Success,
        title: "topics.topic.delete.notification.title",
        description: "topics.topic.delete.notification.description",
      });
      if (isCompanyRoute) {
        navigate("/company/topics");
      } else {
        navigate("/user/topics");
      }
    } catch {
      addMessage({
        type: MessageType.Error,
        title: "common.error.title",
        description: "common.error.description",
      });
    }
  };

  const getUserTopics = async () => {
    try {
      setIsFetching(true);
      const response = await httpsClient.get(`/api/v1/user/topics`);
      setTopics(response.data);
      setIsFetching(false);
    } catch {
      setIsFetching(false);
    }
  };

  const publishTopic = async () => {
    try {
      const response = await httpsClient.patch(`/api/v1/topics/${id}`, {
        published: true,
      });
      setTopic(response.data);
      addMessage({
        type: MessageType.Success,
        title: "topics.topic.publish.notification.title",
        description: "topics.topic.publish.notification.description",
        retain: true,
      });
    } catch {
      addMessage({
        type: MessageType.Error,
        title: "common.error.title",
        description: "common.error.description",
      });
    }
  };

  const unPublishTopic = async () => {
    try {
      const response = await httpsClient.patch(`/api/v1/topics/${id}`, {
        published: false,
      });
      setTopic(response.data);
      addMessage({
        type: MessageType.Success,
        title: "topics.topic.unpublish.notification.title",
        description: "topics.topic.unpublish.notification.description",
      });
    } catch {
      addMessage({
        type: MessageType.Error,
        title: "common.error.title",
        description: "common.error.description",
      });
    }
  };

  const closeDeleteTopicConfirmation = () => {
    setIsDeleteTopicConfirmationOpen(false);
  };

  useEffect(() => {
    addAccessTokenInterceptor(getAccessTokenSilently);
  }, [getAccessTokenSilently]);

  useEffect(() => {
    if (currentUser?.id) {
      getUserTopics();
    }
  }, [currentUser]);

  useEffect(() => {
    fetchToken();
  }, [fetchToken]);

  useEffect(() => {
    if (id) {
      getTopic(id);
    }
  }, [id]);

  const classNames = (...classes: any) => {
    return classes.filter(Boolean).join(" ");
  };

  return (
    <div className="bg-white">
      <nav className="flex" aria-label="Breadcrumb">
        <ol role="list" className="flex items-center space-x-4">
          <li>
            <div className="flex whitespace-nowrap items-center">
              <NavLink
                to={"/admin/topics"}
                className="text-sm font-medium text-gray-500 hover:text-gray-700"
              >
                {t("common.topics")}
              </NavLink>
            </div>
          </li>
        </ol>
      </nav>
      {isFetching && (
        <div className="flex justify-center items-center mt-6">
          <Loader />
        </div>
      )}
      {isAuthenticated && !isLoading && !isFetching && (
        <>
          <div className="lg:flex lg:items-start lg:justify-between lg:space-x-6">
            <div className="min-w-0 flex-1">
              <h2 className="my-2 text-2xl font-bold leading-8 text-gray-900 tracking-tight">{topic.title}</h2>
              <div className="flex flex-wrap gap-x-4 gap-y-2">
                <div className="flex items-center text-sm text-gray-400 space-x-4">
                  <CalendarIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                  {
                    topic.created === topic.updated
                      ? <>{moment(topic.created).locale(currentLanguage).format("lll")}</>
                      : <>{t("common.updated")} {moment(topic.updated).locale(currentLanguage).format("lll")}</>
                  }
                </div>
                {topic.experts?.length > 0 && (
                  <div className="ml-2 flex -space-x-1.5 overflow-hidden">
                    {topic.experts?.map((e: any) =>
                      e?.user.image ? (
                        <img
                          className="inline-block h-6 w-6 rounded-full border object-cover"
                          src={`https://resources.thesisnavigator.com/${e.user.image}`}
                          alt=""
                        />
                      ) : (
                        <img
                          className="inline-block h-6 w-6 rounded-full border object-cover"
                          src={e.user.picture}
                          alt=""
                        />
                      )
                    )}
                  </div>
                )}
                {!topic.published && (
                  <span className="inline-flex items-center rounded-full bg-yellow-100 px-3 py-1.5 text-xs font-medium text-yellow-800">
                    {t("common.draft")}
                  </span>
                )}
              </div>
            </div>
          </div>
          <div>
            <TabGroup className="mt-6">
              <div className="">
                <div className="flex-auto border-b border-gray-200">
                  <TabList className="-mb-px flex space-x-6">
                    <Tab
                      key="topic"
                      className="whitespace-nowrap border-b-2 border-transparent px-1 py-4 font-medium text-sm text-gray-500 hover:border-gray-300 data-[selected]:font-semibold data-[selected]:border-gray-900 data-[selected]:text-gray-900 outline-none flex items-center"
                    >
                      {t("topics.form.description.title")}
                    </Tab>
                    {(["owner", "admin"].includes(currentUser?.role) ||
                      topic.thesis_projects?.some((thesis_project: any) =>
                        thesis_project.thesis_project_experts?.some((e: any) => e?.experts?.user_id === currentUser?.id)
                      )) &&
                      <Tab
                        key="thesis-projects"
                        className="whitespace-nowrap border-b-2 border-transparent px-1 py-4 font-medium text-sm text-gray-500 hover:border-gray-300 data-[selected]:font-semibold data-[selected]:border-gray-900 data-[selected]:text-gray-900 outline-none flex items-center"
                        >
                        <div className="relative z-10 flex items-center">
                          {topic.thesis_projects?.length === 1 ? t("common.thesisProject") : t("common.thesisProjects")}
                          {topic.thesis_projects?.length > 0 && (
                            <span className="bg-red-500 text-white text-2xs font-medium rounded-full h-5 w-5 flex items-center justify-center absolute -top-2 -right-2.5 border-2 border-white rounded-full transform translate-x-1/2">
                              {topic.thesis_projects?.length}
                            </span>
                          )}
                        </div>
                      </Tab>}
                  </TabList>
                </div>
              </div>

              <TabPanels as={Fragment}>
                <TabPanel key={"topic"} className="space-y-6 mt-3">
                  <dl className="grid grid-cols-1 sm:grid-cols-2 whitespace-pre-line linkify">
                    {topic.internship && (
                      <div className="py-6 sm:col-span-1">
                        <dt className="font-semibold text-gray-900">{t("topics.form.internship.title")}</dt>
                        <dd className="mt-1 text-sm/6 text-gray-700 sm:mt-2">{t(`topics.form.internship.${topic.internship}`)}</dd>
                      </div>
                    )}
                    {topic.levels?.length > 0 && (
                      <div className="border-t sm:border-none border-gray-100 py-6 sm:col-span-1">
                        <dt className="font-semibold text-gray-900">{t("Level")}</dt>
                        <dd className="mt-1 text-sm/6 text-gray-700 sm:mt-2">{topic.levels.map((e: any) => t(`level.${e}`)).join(", ")}</dd>
                      </div>
                    )}
                    {topic.description && (
                      <div className="border-t border-gray-100 py-6 sm:col-span-2">
                        <dt className="font-semibold text-gray-900">{t("topics.form.description.title")}</dt>
                        <dd className="mt-1 text-sm/6 text-gray-700 sm:mt-2">
                          <p className="editor" dangerouslySetInnerHTML={{ __html: t(topic.description) }} />
                        </dd>
                      </div>
                    )}
                    {topic.fields?.length > 0 && (
                      <div className="border-t border-gray-100 py-6 sm:col-span-2">
                        <div className="font-semibold text-gray-900">{t("topics.form.fields.title")}</div>
                        <div className="flex flex-wrap gap-x-4 gap-y-2 mt-3">
                          {topic.fields?.map((field: any) =>
                            <span
                              key={field.id}
                              className="items-center rounded-full px-3 py-1.5 text-xs font-medium border border-gray-300 text-gray-900"
                            >
                              {t(`fields.${field.name}`)}
                            </span>
                          )}
                        </div>
                      </div>
                    )}
                    {topic.requirements && (
                      <div className="border-t border-gray-100 py-6 sm:col-span-2">
                        <dt className="font-semibold text-gray-900">{t("topics.form.requirements.title")}</dt>
                        <dd className="mt-1 text-sm/6 text-gray-700 sm:mt-2">
                          <p className="editor" dangerouslySetInnerHTML={{ __html: t(topic.requirements) }} />
                        </dd>
                      </div>
                    )}
                  </dl>
                  {topic.supervisor_topic_interests?.length > 0 && (
                    <TopicSupervisorInterests interests={topic.supervisor_topic_interests} />
                  )}
                </TabPanel>
                {(["owner", "admin"].includes(currentUser?.role) ||
                  topic.thesis_projects?.some((thesis_project: any) =>
                    thesis_project.thesis_project_experts?.some((e: any) => e?.experts?.user.id === currentUser?.id)
                  )) && <TabPanel key={"thesis-projects"} className="space-y-6">
                    <TopicThesisProjects thesisProjects={topic.thesis_projects} />
                  </TabPanel>}
              </TabPanels>
            </TabGroup>

          </div>
        </>
      )}
      <Modal isOpen={isDeleteTopicConfirmationOpen} onClose={closeDeleteTopicConfirmation}>
        <ConfirmationDialog
          type={ConfirmationDialogType.Delete}
          title={t("topics.topic.delete.confirmation.title")}
          body={t("topics.topic.delete.confirmation.description")}
          cancel={t("common.cancel")}
          save={t("common.delete")}
          onCancel={closeDeleteTopicConfirmation}
          onSave={deleteTopic}
        />
      </Modal>
    </div>
  );
}
