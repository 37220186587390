/* tslint:disable */
/* eslint-disable */
/**
 * Thesis Navigator API
 * All documentation for the Thesis Navigator API.
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import type { AdminCompanyCreate } from '../model';
// @ts-ignore
import type { AdminCompanyUpdate } from '../model';
// @ts-ignore
import type { Company } from '../model';
// @ts-ignore
import type { CompanyPage } from '../model';
/**
 * AdminCompaniesApi - axios parameter creator
 * @export
 */
export const AdminCompaniesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create a Company.
         * @param {AdminCompanyCreate} adminCompanyCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAdminCompany: async (adminCompanyCreate: AdminCompanyCreate, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'adminCompanyCreate' is not null or undefined
            assertParamExists('createAdminCompany', 'adminCompanyCreate', adminCompanyCreate)
            const localVarPath = `/api/v2/admin/companies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminCompanyCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a Company by ID
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAdminCompany: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteAdminCompany', 'id', id)
            const localVarPath = `/api/v2/admin/companies/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find a Company by ID.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAdminCompanyById: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findAdminCompanyById', 'id', id)
            const localVarPath = `/api/v2/admin/companies/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find all Companies
         * @param {string} [search] Search terms.
         * @param {number} [page] Page number, default is 1
         * @param {number} [size] Page size, default is 50
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllAdminCompanies: async (search?: string, page?: number, size?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v2/admin/companies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a Company by ID.
         * @param {string} id 
         * @param {AdminCompanyUpdate} adminCompanyUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAdminCompany: async (id: string, adminCompanyUpdate: AdminCompanyUpdate, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateAdminCompany', 'id', id)
            // verify required parameter 'adminCompanyUpdate' is not null or undefined
            assertParamExists('updateAdminCompany', 'adminCompanyUpdate', adminCompanyUpdate)
            const localVarPath = `/api/v2/admin/companies/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminCompanyUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminCompaniesApi - functional programming interface
 * @export
 */
export const AdminCompaniesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminCompaniesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create a Company.
         * @param {AdminCompanyCreate} adminCompanyCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAdminCompany(adminCompanyCreate: AdminCompanyCreate, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Company>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createAdminCompany(adminCompanyCreate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminCompaniesApi.createAdminCompany']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Delete a Company by ID
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAdminCompany(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAdminCompany(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminCompaniesApi.deleteAdminCompany']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Find a Company by ID.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAdminCompanyById(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Company>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAdminCompanyById(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminCompaniesApi.findAdminCompanyById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Find all Companies
         * @param {string} [search] Search terms.
         * @param {number} [page] Page number, default is 1
         * @param {number} [size] Page size, default is 50
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAllAdminCompanies(search?: string, page?: number, size?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAllAdminCompanies(search, page, size, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminCompaniesApi.findAllAdminCompanies']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update a Company by ID.
         * @param {string} id 
         * @param {AdminCompanyUpdate} adminCompanyUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAdminCompany(id: string, adminCompanyUpdate: AdminCompanyUpdate, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Company>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAdminCompany(id, adminCompanyUpdate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminCompaniesApi.updateAdminCompany']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AdminCompaniesApi - factory interface
 * @export
 */
export const AdminCompaniesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminCompaniesApiFp(configuration)
    return {
        /**
         * 
         * @summary Create a Company.
         * @param {AdminCompaniesApiCreateAdminCompanyRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAdminCompany(requestParameters: AdminCompaniesApiCreateAdminCompanyRequest, options?: RawAxiosRequestConfig): AxiosPromise<Company> {
            return localVarFp.createAdminCompany(requestParameters.adminCompanyCreate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a Company by ID
         * @param {AdminCompaniesApiDeleteAdminCompanyRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAdminCompany(requestParameters: AdminCompaniesApiDeleteAdminCompanyRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteAdminCompany(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find a Company by ID.
         * @param {AdminCompaniesApiFindAdminCompanyByIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAdminCompanyById(requestParameters: AdminCompaniesApiFindAdminCompanyByIdRequest, options?: RawAxiosRequestConfig): AxiosPromise<Company> {
            return localVarFp.findAdminCompanyById(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find all Companies
         * @param {AdminCompaniesApiFindAllAdminCompaniesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllAdminCompanies(requestParameters: AdminCompaniesApiFindAllAdminCompaniesRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<CompanyPage> {
            return localVarFp.findAllAdminCompanies(requestParameters.search, requestParameters.page, requestParameters.size, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a Company by ID.
         * @param {AdminCompaniesApiUpdateAdminCompanyRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAdminCompany(requestParameters: AdminCompaniesApiUpdateAdminCompanyRequest, options?: RawAxiosRequestConfig): AxiosPromise<Company> {
            return localVarFp.updateAdminCompany(requestParameters.id, requestParameters.adminCompanyUpdate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createAdminCompany operation in AdminCompaniesApi.
 * @export
 * @interface AdminCompaniesApiCreateAdminCompanyRequest
 */
export interface AdminCompaniesApiCreateAdminCompanyRequest {
    /**
     * 
     * @type {AdminCompanyCreate}
     * @memberof AdminCompaniesApiCreateAdminCompany
     */
    readonly adminCompanyCreate: AdminCompanyCreate
}

/**
 * Request parameters for deleteAdminCompany operation in AdminCompaniesApi.
 * @export
 * @interface AdminCompaniesApiDeleteAdminCompanyRequest
 */
export interface AdminCompaniesApiDeleteAdminCompanyRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminCompaniesApiDeleteAdminCompany
     */
    readonly id: string
}

/**
 * Request parameters for findAdminCompanyById operation in AdminCompaniesApi.
 * @export
 * @interface AdminCompaniesApiFindAdminCompanyByIdRequest
 */
export interface AdminCompaniesApiFindAdminCompanyByIdRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminCompaniesApiFindAdminCompanyById
     */
    readonly id: string
}

/**
 * Request parameters for findAllAdminCompanies operation in AdminCompaniesApi.
 * @export
 * @interface AdminCompaniesApiFindAllAdminCompaniesRequest
 */
export interface AdminCompaniesApiFindAllAdminCompaniesRequest {
    /**
     * Search terms.
     * @type {string}
     * @memberof AdminCompaniesApiFindAllAdminCompanies
     */
    readonly search?: string

    /**
     * Page number, default is 1
     * @type {number}
     * @memberof AdminCompaniesApiFindAllAdminCompanies
     */
    readonly page?: number

    /**
     * Page size, default is 50
     * @type {number}
     * @memberof AdminCompaniesApiFindAllAdminCompanies
     */
    readonly size?: number
}

/**
 * Request parameters for updateAdminCompany operation in AdminCompaniesApi.
 * @export
 * @interface AdminCompaniesApiUpdateAdminCompanyRequest
 */
export interface AdminCompaniesApiUpdateAdminCompanyRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminCompaniesApiUpdateAdminCompany
     */
    readonly id: string

    /**
     * 
     * @type {AdminCompanyUpdate}
     * @memberof AdminCompaniesApiUpdateAdminCompany
     */
    readonly adminCompanyUpdate: AdminCompanyUpdate
}

/**
 * AdminCompaniesApi - object-oriented interface
 * @export
 * @class AdminCompaniesApi
 * @extends {BaseAPI}
 */
export class AdminCompaniesApi extends BaseAPI {
    /**
     * 
     * @summary Create a Company.
     * @param {AdminCompaniesApiCreateAdminCompanyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminCompaniesApi
     */
    public createAdminCompany(requestParameters: AdminCompaniesApiCreateAdminCompanyRequest, options?: RawAxiosRequestConfig) {
        return AdminCompaniesApiFp(this.configuration).createAdminCompany(requestParameters.adminCompanyCreate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a Company by ID
     * @param {AdminCompaniesApiDeleteAdminCompanyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminCompaniesApi
     */
    public deleteAdminCompany(requestParameters: AdminCompaniesApiDeleteAdminCompanyRequest, options?: RawAxiosRequestConfig) {
        return AdminCompaniesApiFp(this.configuration).deleteAdminCompany(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find a Company by ID.
     * @param {AdminCompaniesApiFindAdminCompanyByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminCompaniesApi
     */
    public findAdminCompanyById(requestParameters: AdminCompaniesApiFindAdminCompanyByIdRequest, options?: RawAxiosRequestConfig) {
        return AdminCompaniesApiFp(this.configuration).findAdminCompanyById(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find all Companies
     * @param {AdminCompaniesApiFindAllAdminCompaniesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminCompaniesApi
     */
    public findAllAdminCompanies(requestParameters: AdminCompaniesApiFindAllAdminCompaniesRequest = {}, options?: RawAxiosRequestConfig) {
        return AdminCompaniesApiFp(this.configuration).findAllAdminCompanies(requestParameters.search, requestParameters.page, requestParameters.size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a Company by ID.
     * @param {AdminCompaniesApiUpdateAdminCompanyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminCompaniesApi
     */
    public updateAdminCompany(requestParameters: AdminCompaniesApiUpdateAdminCompanyRequest, options?: RawAxiosRequestConfig) {
        return AdminCompaniesApiFp(this.configuration).updateAdminCompany(requestParameters.id, requestParameters.adminCompanyUpdate, options).then((request) => request(this.axios, this.basePath));
    }
}

