export enum UserType {
  Student = "student",
  Expert = "expert",
  Supervisor = "supervisor"
}

export enum ThesisProjectState {
  Applied = "applied",
  Withdrawn = "withdrawn",
  Rejected = "rejected",
  Agreed = "agreed",
  Canceled = "canceled",
  Completed = "completed",
}

export const studentThesisProjectStateFlow: any = {
  applied: [ThesisProjectState.Withdrawn],
  agreed: [ThesisProjectState.Completed, ThesisProjectState.Canceled],
};

export const expertThesisProjectStateFlow: any = {
  applied: [ThesisProjectState.Agreed, ThesisProjectState.Rejected],
  agreed: [ThesisProjectState.Canceled],
};

export const thesisProjectStates: any = {
  applied: "text-green-700 bg-green-100",
  withdrawn: "text-yellow-800 bg-yellow-100",
  rejected: "text-red-700 bg-red-100",
  agreed: "text-green-700 bg-green-100",
  canceled: "text-gray-600 bg-gray-100",
  completed: "text-green-700 bg-green-100",
};

export const languages = [
  { id: "en", title: "English" },
  { id: "de", title: "German" },
];

export const level = [
  {
    id: "bsc",
    title: "Bachelor’s Thesis",
  },
  {
    id: "msc",
    title: "Master’s Thesis",
  },
  {
    id: "phd",
    title: "Ph. D. Thesis",
  },
];

export const internships = [{ id: "no" }, { id: "open" }, { id: "yes" }];
