import Linkify from "linkify-react";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const TruncateText: React.FC<{ maxLength: number; text: string }> = ({ maxLength, text }) => {
  const [isTruncated, setIsTruncated] = useState(true);
  const { t } = useTranslation();

  const toggleTruncate = () => {
    setIsTruncated(!isTruncated);
  };

  return (
    <div>
      <p className={"inline linkify"}>
        <p className="editor" dangerouslySetInnerHTML={{ __html: isTruncated && text?.length >= maxLength ? `${text?.slice(0, maxLength)}...` : text }} />
      </p>
      {text?.length >= maxLength && (
        <div className="text-right">
        <button type="button" onClick={toggleTruncate} className="rounded bg-gray-50 px-2 py-1 mt-2 text-xs font-semibold text-gray-900 shadow-sm hover:bg-gray-100">
          {isTruncated ? t("common.readMore") : t("common.readLess")}
        </button>
        </div>
      )}
    </div>
  );
};

export default TruncateText;
