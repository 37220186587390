/* tslint:disable */
/* eslint-disable */
/**
 * Thesis Navigator API
 * All documentation for the Thesis Navigator API.
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import type { SupervisorTopicCreate } from '../model';
// @ts-ignore
import type { SupervisorTopicUpdate } from '../model';
// @ts-ignore
import type { Topic } from '../model';
// @ts-ignore
import type { TopicPage } from '../model';
/**
 * SupervisorTopicsApi - axios parameter creator
 * @export
 */
export const SupervisorTopicsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create a Topic
         * @param {SupervisorTopicCreate} supervisorTopicCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSupervisorTopic: async (supervisorTopicCreate: SupervisorTopicCreate, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'supervisorTopicCreate' is not null or undefined
            assertParamExists('createSupervisorTopic', 'supervisorTopicCreate', supervisorTopicCreate)
            const localVarPath = `/api/v2/supervisor/topics`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(supervisorTopicCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a Topic by ID
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSupervisorTopic: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteSupervisorTopic', 'id', id)
            const localVarPath = `/api/v2/supervisor/topics/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find all Topics
         * @param {Array<FindAllSupervisorTopicsLevelsEnum>} [levels] The level of the topic. Possible values: bsc, msc, phd
         * @param {Array<string>} [industries] Industries ID
         * @param {FindAllSupervisorTopicsTypeEnum} [type] Filter type, possible values: me, company
         * @param {string} [search] Search terms.
         * @param {string} [university] University ID
         * @param {string} [faculty] Faculty ID
         * @param {boolean} [bookmarked] Is Topic Bookmarked
         * @param {boolean} [quantitative] Is Topic quantitative
         * @param {number} [page] Page number, default is 1
         * @param {number} [size] Page size, default is 50
         * @param {boolean} [published] If the topic is published
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllSupervisorTopics: async (levels?: Array<FindAllSupervisorTopicsLevelsEnum>, industries?: Array<string>, type?: FindAllSupervisorTopicsTypeEnum, search?: string, university?: string, faculty?: string, bookmarked?: boolean, quantitative?: boolean, page?: number, size?: number, published?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v2/supervisor/topics`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (levels) {
                localVarQueryParameter['levels'] = levels;
            }

            if (industries) {
                localVarQueryParameter['industries'] = industries;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (university !== undefined) {
                localVarQueryParameter['university'] = university;
            }

            if (faculty !== undefined) {
                localVarQueryParameter['faculty'] = faculty;
            }

            if (bookmarked !== undefined) {
                localVarQueryParameter['bookmarked'] = bookmarked;
            }

            if (quantitative !== undefined) {
                localVarQueryParameter['quantitative'] = quantitative;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (published !== undefined) {
                localVarQueryParameter['published'] = published;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find a Topic by ID
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findSupervisorTopicById: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findSupervisorTopicById', 'id', id)
            const localVarPath = `/api/v2/supervisor/topics/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a Topic by ID
         * @param {string} id 
         * @param {SupervisorTopicUpdate} supervisorTopicUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSupervisorTopic: async (id: string, supervisorTopicUpdate: SupervisorTopicUpdate, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateSupervisorTopic', 'id', id)
            // verify required parameter 'supervisorTopicUpdate' is not null or undefined
            assertParamExists('updateSupervisorTopic', 'supervisorTopicUpdate', supervisorTopicUpdate)
            const localVarPath = `/api/v2/supervisor/topics/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(supervisorTopicUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SupervisorTopicsApi - functional programming interface
 * @export
 */
export const SupervisorTopicsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SupervisorTopicsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create a Topic
         * @param {SupervisorTopicCreate} supervisorTopicCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createSupervisorTopic(supervisorTopicCreate: SupervisorTopicCreate, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Topic>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createSupervisorTopic(supervisorTopicCreate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SupervisorTopicsApi.createSupervisorTopic']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Delete a Topic by ID
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteSupervisorTopic(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteSupervisorTopic(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SupervisorTopicsApi.deleteSupervisorTopic']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Find all Topics
         * @param {Array<FindAllSupervisorTopicsLevelsEnum>} [levels] The level of the topic. Possible values: bsc, msc, phd
         * @param {Array<string>} [industries] Industries ID
         * @param {FindAllSupervisorTopicsTypeEnum} [type] Filter type, possible values: me, company
         * @param {string} [search] Search terms.
         * @param {string} [university] University ID
         * @param {string} [faculty] Faculty ID
         * @param {boolean} [bookmarked] Is Topic Bookmarked
         * @param {boolean} [quantitative] Is Topic quantitative
         * @param {number} [page] Page number, default is 1
         * @param {number} [size] Page size, default is 50
         * @param {boolean} [published] If the topic is published
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAllSupervisorTopics(levels?: Array<FindAllSupervisorTopicsLevelsEnum>, industries?: Array<string>, type?: FindAllSupervisorTopicsTypeEnum, search?: string, university?: string, faculty?: string, bookmarked?: boolean, quantitative?: boolean, page?: number, size?: number, published?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TopicPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAllSupervisorTopics(levels, industries, type, search, university, faculty, bookmarked, quantitative, page, size, published, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SupervisorTopicsApi.findAllSupervisorTopics']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Find a Topic by ID
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findSupervisorTopicById(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Topic>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findSupervisorTopicById(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SupervisorTopicsApi.findSupervisorTopicById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update a Topic by ID
         * @param {string} id 
         * @param {SupervisorTopicUpdate} supervisorTopicUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSupervisorTopic(id: string, supervisorTopicUpdate: SupervisorTopicUpdate, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Topic>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSupervisorTopic(id, supervisorTopicUpdate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SupervisorTopicsApi.updateSupervisorTopic']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * SupervisorTopicsApi - factory interface
 * @export
 */
export const SupervisorTopicsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SupervisorTopicsApiFp(configuration)
    return {
        /**
         * 
         * @summary Create a Topic
         * @param {SupervisorTopicsApiCreateSupervisorTopicRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSupervisorTopic(requestParameters: SupervisorTopicsApiCreateSupervisorTopicRequest, options?: RawAxiosRequestConfig): AxiosPromise<Topic> {
            return localVarFp.createSupervisorTopic(requestParameters.supervisorTopicCreate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a Topic by ID
         * @param {SupervisorTopicsApiDeleteSupervisorTopicRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSupervisorTopic(requestParameters: SupervisorTopicsApiDeleteSupervisorTopicRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteSupervisorTopic(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find all Topics
         * @param {SupervisorTopicsApiFindAllSupervisorTopicsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllSupervisorTopics(requestParameters: SupervisorTopicsApiFindAllSupervisorTopicsRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<TopicPage> {
            return localVarFp.findAllSupervisorTopics(requestParameters.levels, requestParameters.industries, requestParameters.type, requestParameters.search, requestParameters.university, requestParameters.faculty, requestParameters.bookmarked, requestParameters.quantitative, requestParameters.page, requestParameters.size, requestParameters.published, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find a Topic by ID
         * @param {SupervisorTopicsApiFindSupervisorTopicByIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findSupervisorTopicById(requestParameters: SupervisorTopicsApiFindSupervisorTopicByIdRequest, options?: RawAxiosRequestConfig): AxiosPromise<Topic> {
            return localVarFp.findSupervisorTopicById(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a Topic by ID
         * @param {SupervisorTopicsApiUpdateSupervisorTopicRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSupervisorTopic(requestParameters: SupervisorTopicsApiUpdateSupervisorTopicRequest, options?: RawAxiosRequestConfig): AxiosPromise<Topic> {
            return localVarFp.updateSupervisorTopic(requestParameters.id, requestParameters.supervisorTopicUpdate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createSupervisorTopic operation in SupervisorTopicsApi.
 * @export
 * @interface SupervisorTopicsApiCreateSupervisorTopicRequest
 */
export interface SupervisorTopicsApiCreateSupervisorTopicRequest {
    /**
     * 
     * @type {SupervisorTopicCreate}
     * @memberof SupervisorTopicsApiCreateSupervisorTopic
     */
    readonly supervisorTopicCreate: SupervisorTopicCreate
}

/**
 * Request parameters for deleteSupervisorTopic operation in SupervisorTopicsApi.
 * @export
 * @interface SupervisorTopicsApiDeleteSupervisorTopicRequest
 */
export interface SupervisorTopicsApiDeleteSupervisorTopicRequest {
    /**
     * 
     * @type {string}
     * @memberof SupervisorTopicsApiDeleteSupervisorTopic
     */
    readonly id: string
}

/**
 * Request parameters for findAllSupervisorTopics operation in SupervisorTopicsApi.
 * @export
 * @interface SupervisorTopicsApiFindAllSupervisorTopicsRequest
 */
export interface SupervisorTopicsApiFindAllSupervisorTopicsRequest {
    /**
     * The level of the topic. Possible values: bsc, msc, phd
     * @type {Array<'bsc' | 'msc' | 'phd'>}
     * @memberof SupervisorTopicsApiFindAllSupervisorTopics
     */
    readonly levels?: Array<FindAllSupervisorTopicsLevelsEnum>

    /**
     * Industries ID
     * @type {Array<string>}
     * @memberof SupervisorTopicsApiFindAllSupervisorTopics
     */
    readonly industries?: Array<string>

    /**
     * Filter type, possible values: me, company
     * @type {'me' | 'university'}
     * @memberof SupervisorTopicsApiFindAllSupervisorTopics
     */
    readonly type?: FindAllSupervisorTopicsTypeEnum

    /**
     * Search terms.
     * @type {string}
     * @memberof SupervisorTopicsApiFindAllSupervisorTopics
     */
    readonly search?: string

    /**
     * University ID
     * @type {string}
     * @memberof SupervisorTopicsApiFindAllSupervisorTopics
     */
    readonly university?: string

    /**
     * Faculty ID
     * @type {string}
     * @memberof SupervisorTopicsApiFindAllSupervisorTopics
     */
    readonly faculty?: string

    /**
     * Is Topic Bookmarked
     * @type {boolean}
     * @memberof SupervisorTopicsApiFindAllSupervisorTopics
     */
    readonly bookmarked?: boolean

    /**
     * Is Topic quantitative
     * @type {boolean}
     * @memberof SupervisorTopicsApiFindAllSupervisorTopics
     */
    readonly quantitative?: boolean

    /**
     * Page number, default is 1
     * @type {number}
     * @memberof SupervisorTopicsApiFindAllSupervisorTopics
     */
    readonly page?: number

    /**
     * Page size, default is 50
     * @type {number}
     * @memberof SupervisorTopicsApiFindAllSupervisorTopics
     */
    readonly size?: number

    /**
     * If the topic is published
     * @type {boolean}
     * @memberof SupervisorTopicsApiFindAllSupervisorTopics
     */
    readonly published?: boolean
}

/**
 * Request parameters for findSupervisorTopicById operation in SupervisorTopicsApi.
 * @export
 * @interface SupervisorTopicsApiFindSupervisorTopicByIdRequest
 */
export interface SupervisorTopicsApiFindSupervisorTopicByIdRequest {
    /**
     * 
     * @type {string}
     * @memberof SupervisorTopicsApiFindSupervisorTopicById
     */
    readonly id: string
}

/**
 * Request parameters for updateSupervisorTopic operation in SupervisorTopicsApi.
 * @export
 * @interface SupervisorTopicsApiUpdateSupervisorTopicRequest
 */
export interface SupervisorTopicsApiUpdateSupervisorTopicRequest {
    /**
     * 
     * @type {string}
     * @memberof SupervisorTopicsApiUpdateSupervisorTopic
     */
    readonly id: string

    /**
     * 
     * @type {SupervisorTopicUpdate}
     * @memberof SupervisorTopicsApiUpdateSupervisorTopic
     */
    readonly supervisorTopicUpdate: SupervisorTopicUpdate
}

/**
 * SupervisorTopicsApi - object-oriented interface
 * @export
 * @class SupervisorTopicsApi
 * @extends {BaseAPI}
 */
export class SupervisorTopicsApi extends BaseAPI {
    /**
     * 
     * @summary Create a Topic
     * @param {SupervisorTopicsApiCreateSupervisorTopicRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SupervisorTopicsApi
     */
    public createSupervisorTopic(requestParameters: SupervisorTopicsApiCreateSupervisorTopicRequest, options?: RawAxiosRequestConfig) {
        return SupervisorTopicsApiFp(this.configuration).createSupervisorTopic(requestParameters.supervisorTopicCreate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a Topic by ID
     * @param {SupervisorTopicsApiDeleteSupervisorTopicRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SupervisorTopicsApi
     */
    public deleteSupervisorTopic(requestParameters: SupervisorTopicsApiDeleteSupervisorTopicRequest, options?: RawAxiosRequestConfig) {
        return SupervisorTopicsApiFp(this.configuration).deleteSupervisorTopic(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find all Topics
     * @param {SupervisorTopicsApiFindAllSupervisorTopicsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SupervisorTopicsApi
     */
    public findAllSupervisorTopics(requestParameters: SupervisorTopicsApiFindAllSupervisorTopicsRequest = {}, options?: RawAxiosRequestConfig) {
        return SupervisorTopicsApiFp(this.configuration).findAllSupervisorTopics(requestParameters.levels, requestParameters.industries, requestParameters.type, requestParameters.search, requestParameters.university, requestParameters.faculty, requestParameters.bookmarked, requestParameters.quantitative, requestParameters.page, requestParameters.size, requestParameters.published, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find a Topic by ID
     * @param {SupervisorTopicsApiFindSupervisorTopicByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SupervisorTopicsApi
     */
    public findSupervisorTopicById(requestParameters: SupervisorTopicsApiFindSupervisorTopicByIdRequest, options?: RawAxiosRequestConfig) {
        return SupervisorTopicsApiFp(this.configuration).findSupervisorTopicById(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a Topic by ID
     * @param {SupervisorTopicsApiUpdateSupervisorTopicRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SupervisorTopicsApi
     */
    public updateSupervisorTopic(requestParameters: SupervisorTopicsApiUpdateSupervisorTopicRequest, options?: RawAxiosRequestConfig) {
        return SupervisorTopicsApiFp(this.configuration).updateSupervisorTopic(requestParameters.id, requestParameters.supervisorTopicUpdate, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const FindAllSupervisorTopicsLevelsEnum = {
    Bsc: 'bsc',
    Msc: 'msc',
    Phd: 'phd'
} as const;
export type FindAllSupervisorTopicsLevelsEnum = typeof FindAllSupervisorTopicsLevelsEnum[keyof typeof FindAllSupervisorTopicsLevelsEnum];
/**
 * @export
 */
export const FindAllSupervisorTopicsTypeEnum = {
    Me: 'me',
    University: 'university'
} as const;
export type FindAllSupervisorTopicsTypeEnum = typeof FindAllSupervisorTopicsTypeEnum[keyof typeof FindAllSupervisorTopicsTypeEnum];
