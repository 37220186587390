import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

export default function Forbidden() {
  const { t } = useTranslation();
  return (
    <>
      {/*
        This example requires updating your template:

        ```
        <html class="h-full">
        <body class="h-full">
        ```
      */}
      <main className="grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
        <div className="text-center">
          <p className="text-base font-semibold text-gray-900">403</p>
          <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">{t("error.403.title")}</h1>
          <p className="mt-6 text-base leading-7 text-gray-600">{t("error.403.description")}</p>
          <div className="mt-10 flex items-center justify-center gap-x-6">
            <NavLink
              to="/"
              className="rounded-md bg-gray-900 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-gray-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
            >
              {t("error.403.button")}
            </NavLink>
            <NavLink to="/feedback" className="text-sm font-semibold text-gray-900 underline">
              {t("error.403.secondaryButton")} <span aria-hidden="true">&rarr;</span>
            </NavLink>
          </div>
        </div>
      </main>
    </>
  );
}
