import { useAuth0 } from "@auth0/auth0-react";
import { useTranslation } from "react-i18next";
import { useContext, useEffect, useState } from "react";
import httpsClient from "../../config/https";
import marc from "../../static/images/marc.jpeg";
import "moment/locale/de";
import { CurrentUserContext } from "src/contexts/userContext";
import { Controller, useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { object, array } from "yup";
import { MessageType, useMessagesContext } from "src/contexts/messagesContext";
import { useNavigate } from "react-router-dom";
import Loader from "src/components/Loader";

export default function ExpertOnboarding() {
  const { isAuthenticated, isLoading } = useAuth0();
  const { t } = useTranslation();
  const { currentUser, setCurrentUser } = useContext(CurrentUserContext);
  const [fields, setFields] = useState<any>([]);
  const { addMessage } = useMessagesContext();
  const [isUpdating, setIsUpdating] = useState(false);
  const navigate = useNavigate();

  const validationSchema = object().shape({
    fields: array().min(1).max(3).required(t("common.required")),
  });
  const {
    control,
    handleSubmit
  } = useForm({
    resolver: yupResolver(validationSchema),
  });
  const watchedFields = useWatch({
    control,
    name: "fields",
    defaultValue: [],
  });

  const getFields = async () => {
    await httpsClient
      .get(`/api/v2/fields`)
      .then((response) => {
        setFields(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const updateUser = async (data: any) => {
    try {
      setIsUpdating(true);
      await httpsClient.patch("/api/v1/user", {
        user_fields: {
          deleteMany: {
            user_id: currentUser?.id,
          },
          createMany: {
            data: data?.fields.map((e: any) => ({ field_id: e })),
            skipDuplicates: true,
          },
        },
      });
      setCurrentUser({
        ...currentUser,
        fields: data?.fields,
      });
      addMessage({
        type: MessageType.Success,
        title: "profile.notification.update.title",
        description: "profile.notification.update.description",
      });
      navigate("/topics");
    } catch {
      setIsUpdating(false);
      addMessage({
        type: MessageType.Error,
        title: "common.error.title",
        description: "common.error.description",
      });
    }
  };

  const onSubmit = (data: any) => {
    updateUser(data);
  };

  useEffect(() => {
    getFields();
  }, [currentUser]);

  return (
    <div className="bg-white">
      {isAuthenticated && !isLoading && (
        <div className="mx-auto max-w-4xl sm:py-12">
          <div className="text-center">
            <img src={marc} alt="" className="mx-auto h-20 w-20 sm:h-28 sm:w-28 rounded-full border object-cover" />
            <h1 className="text-gray-900 mt-6 sm:mt-12 font-bold tracking-tight text-gray-900 text-2xl sm:text-3xl">
              {t("onboarding.supervisor.fields.title", { value: currentUser.first_name })}
            </h1>
            <p className="mt-6 sm:mt-12 text-sm sm:text-base font-medium text-gray-500">
              {t("onboarding.supervisor.fields.subtitle")}
            </p>
            <form autoComplete="off" className="mt-12 sm:mt-24 items-center justify-center gap-x-6">
              <div className="whitespace-pre-line">
                <Controller
                  name="fields"
                  control={control}
                  render={({ field }) => {
                    const fieldValue = Array.isArray(field.value) ? field.value : [];
                    return (
                      <div className="w-full flex flex-wrap gap-2">
                        {fields?.map((e) => {
                          const isSelected = fieldValue.includes(e.id);
                          const isDisabled = !isSelected && fieldValue.length >= 3;

                          const handleClick = () => {
                            if (!isDisabled) {
                              const newValue = isSelected
                                ? fieldValue.filter((id) => id !== e.id)
                                : [...fieldValue, e.id];
                              field.onChange(newValue);
                            }
                          };
                          return (
                            <button
                              key={e.id}
                              type="button"
                              onClick={handleClick}
                              disabled={isDisabled}
                              className={`items-center rounded-full px-3 py-1.5 text-xs font-medium border
                  ${isSelected ? 'bg-gray-900 text-white border-transparent' : 'text-gray-900 border-gray-300'}
                  ${isDisabled ? 'opacity-50 cursor-not-allowed' : ''}`}
                            >
                              {t(`fields.${e.name}`)}
                            </button>
                          );
                        })}
                      </div>
                    );
                  }}
                />
              </div>
              {!isUpdating && (
                <button
                  type="submit"
                  onClick={handleSubmit(onSubmit)}
                  disabled={!watchedFields || watchedFields.length === 0}
                  className="mt-12 sm:mt-24 rounded-md bg-gray-900 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900 disabled:opacity-50 disabled:cursor-not-allowed"
                >
                  {t("onboarding.supervisor.fields.action")}
                </button>
              )}
              {isUpdating && (
                <div className="flex justify-center items-center mt-12 sm:mt-24">
                  <Loader />
                </div>
              )}
            </form>
          </div>
          <p className="mt-12 text-pretty text-xs text-gray-600 text-center">
            {t("onboarding.footer")}
          </p>
        </div>
      )}
    </div>
  );
}
