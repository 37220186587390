import { useTranslation } from "react-i18next";
import moment from "moment";
import "moment/locale/de";
import { AcademicCapIcon } from "@heroicons/react/24/solid";
import Modal from "../../components/Modal";
import { useState } from "react";
import { ChevronRightIcon } from "@heroicons/react/20/solid";
import TopicSupervisor from "./TopicSupervisor";

interface Props {
  interests: any;
}

const TopicSupervisorInterests: React.FC<Props> = ({ interests }) => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const [supervisor, setSupervisor] = useState<any>();
  const [isSupervisorModalOpen, setSupervisorModalOpen] = useState<boolean>(false);
  return (
    <div className="grid grid-cols-1 gap-2 sm:grid-cols-1 border-l-4 border-yellow-400 pl-4 pb-2 mb-6">
      <div className="border-b border-gray-100 py-3">
        <h3 className="font-semibold leading-6 text-gray-900"><AcademicCapIcon
          className="text-yellow-400 hover:text-yellow-300 w-5 inline mr-2"
          aria-hidden="true"
        /> {t("topics.topic.supervisor.title")}</h3>
        <p className="mt-1 text-sm text-gray-500">
          {t("topics.topic.supervisor.description")}
        </p>
      </div>
      <div className="divide-y divide-gray-100 pt-2">
        {interests.map((interest: any) => (
          <div key={interest.id} className="relative sm:flex items-center justify-between gap-x-6 py-3 hover:opacity-75 cursor-pointer" onClick={() => { setSupervisor(interest.supervisor); setSupervisorModalOpen(true) }}>
            <div className="flex min-w-0 gap-x-4 flex-1 items-center">
              <img
                className="h-12 w-12 flex-none rounded-full border object-cover"
                src={
                  interest.supervisor.user.image
                    ? `https://resources.thesisnavigator.com/${interest.supervisor.user.image}`
                    : interest.supervisor.user.picture
                }
                alt=""
              />
              <div className="min-w-0 flex-auto">
                <p className="text-sm font-semibold leading-6 text-gray-900">
                  <span className="absolute inset-x-0 -top-px bottom-0" />
                  {interest.supervisor.user.first_name} {interest.supervisor.user.last_name}
                </p>
              </div>
            </div>
            <div className="flex shrink-0 items-center gap-x-4 sm:flex-1 sm:text-right mt-3 sm:mt-0">
              <div className="sm:flex sm:flex-col sm:items-end w-full">
                <p className="text-sm leading-6 text-gray-900">
                  {interest.supervisor.university.name}
                </p>
                <p className="mt-1 items-center text-xs leanding-5 text-gray-500">
                  {moment(interest.created).locale(currentLanguage).format("MMM DD, YYYY")}
                </p>
              </div>
              <ChevronRightIcon className="h-5 w-5 flex-none text-gray-400" aria-hidden="true" />
            </div>
          </div>
        ))}
      </div>
      <Modal isOpen={isSupervisorModalOpen} onClose={() => setSupervisorModalOpen(false)}>
        <TopicSupervisor supervisor={supervisor} />
      </Modal>
    </div>
  );
};

export default TopicSupervisorInterests;
