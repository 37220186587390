/* tslint:disable */
/* eslint-disable */
/**
 * Thesis Navigator API
 * All documentation for the Thesis Navigator API.
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface AdminCompanyExpert
 */
export interface AdminCompanyExpert {
    /**
     * The role of the expert.
     * @type {string}
     * @memberof AdminCompanyExpert
     */
    'role'?: AdminCompanyExpertRoleEnum;
    /**
     * The id of the expert.
     * @type {string}
     * @memberof AdminCompanyExpert
     */
    'id': string;
    /**
     * The user email.
     * @type {string}
     * @memberof AdminCompanyExpert
     */
    'email': string;
    /**
     * The user first name.
     * @type {string}
     * @memberof AdminCompanyExpert
     */
    'firstName': string;
    /**
     * The user last name.
     * @type {string}
     * @memberof AdminCompanyExpert
     */
    'lastName': string;
    /**
     * The last login date of the expert.
     * @type {string}
     * @memberof AdminCompanyExpert
     */
    'lastLogin'?: string | null;
}

export const AdminCompanyExpertRoleEnum = {
    Owner: 'owner',
    Admin: 'admin'
} as const;

export type AdminCompanyExpertRoleEnum = typeof AdminCompanyExpertRoleEnum[keyof typeof AdminCompanyExpertRoleEnum];


