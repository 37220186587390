import { BuildingOffice2Icon } from "@heroicons/react/24/solid";
import { useAuth0 } from "@auth0/auth0-react";
import { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CurrentUserContext } from "../../contexts/userContext";
import Loader from "../../components/Loader";
import httpsClient, { addAccessTokenInterceptor } from "../../config/https";
import { useParams } from "react-router-dom";

export default function Company() {
  const { id } = useParams();
  const { currentUser, setCurrentUser } = useContext(CurrentUserContext);
  const { user, getAccessTokenSilently, isAuthenticated, isLoading } = useAuth0();
  const [isFetching, setIsFetching] = useState(true);
  const [token, setToken] = useState("");
  const [company, setCompany] = useState<any>();
  const [countries, setCountries] = useState<any>([]);
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const fetchToken = useCallback(async () => {
    const data = await getAccessTokenSilently();
    setToken(data);
  }, []);

  const getCompany = async () => {
    const response = await httpsClient.get(`/api/v1/companies/${id}`);
    response.data.name = response.data.name;
    response.data.description = response.data.description;
    response.data.uid = response.data.uid;
    setCompany(response.data);
    setIsFetching(false);
  };

  const getCountries = async () => {
    const response = await httpsClient.get(`/api/v1/countries?lang=${currentLanguage}`);
    setCountries(response.data);
  };

  const handleLanguageChange = (event: any) => {
    i18n.changeLanguage(event.currentTarget.value);
  };

  const classNames = (...classes: any) => {
    return classes.filter(Boolean).join(" ");
  };

  useEffect(() => {
    fetchToken();
  }, [fetchToken]);

  useEffect(() => {
    addAccessTokenInterceptor(getAccessTokenSilently);
  }, [getAccessTokenSilently]);

  useEffect(() => {
    getCountries();
  }, [currentLanguage]);

  useEffect(() => {
    getCompany();
  }, [currentUser]);

  return (
    <>
      {isFetching && (
        <div className="flex justify-center items-center">
          <Loader />
        </div>
      )}
      {isAuthenticated && !isLoading && !isFetching && (
        <>
          <div className="-mx-6 sm:-mx-8 p-6 sm:p-6 lg:p-8 -mt-10">
            <div className="pb-6 rounded-md">
              <div className="relative h-32 w-full lg:h-48 rounded-t-md bg-gray-100">
                {company.background && (
                  <img
                    src={`https://resources.thesisnavigator.com/${company.background}`}
                    className="h-32 w-full lg:h-48 object-cover rounded-t-md"
                    alt=""
                  />
                )}
              </div>
              <div className="px-4 sm:px-6 lg:px-8">
                <div className="sm:flex items-start sm:space-x-5">
                  <div className="sm:flex -mt-14 sm:-mt-16 relative bg-white ring-1 ring-gray-100 rounded-md p-2 inline-block">
                    {company.picture && (
                      <img
                        className="h-24 w-24 sm:h-28 sm:w-28 object-contain"
                        src={`https://resources.thesisnavigator.com/${company.picture}`}
                        alt=""
                      />
                    )}
                    {!company.picture && (
                      <div className="h-24 w-24 sm:h-28 sm:w-28 p-6 rounded-md">
                        <BuildingOffice2Icon className="fill-gray-900" />
                      </div>
                    )}
                  </div>
                  <div className="mt-6 sm:flex sm:min-w-0 sm:flex-1 sm:items-start sm:space-x-6">
                    <div className="min-w-0 flex-1">
                      <h1 className="truncate text-2xl font-bold text-gray-900">{company.name}</h1>
                      {company?.size && (
                        <div className="text-sm text-gray-500 leading-6">{t(`company.size.type.${company.size}`)}</div>
                      )}
                    </div>
                  </div>
                </div>
                {company.fields?.length > 0 && (
                    <div className="mt-10">
                      <div className="flex flex-wrap gap-2">
                        {company.fields?.map((field: any) =>
                          <span
                            key={field.id}
                            className="items-center rounded-full px-3 py-1.5 text-xs font-medium border border-gray-300 text-gray-900"
                          >
                            {t(`fields.${field.name}`)} <span className="bg-gray-200 text-gray-900 ml-1.5 rounded-full px-2 py-0.5 text-xs font-medium inline-block">{field.count}</span>
                          </span>
                        )}
                      </div>
                    </div>
                )}
                <dl className="mt-12 grid grid-cols-1 sm:grid-cols-6">
                  {company.description && (
                    <div className="sm:col-span-4 lg:col-span-5 sm:pr-12">
                      <p
                        className="editor text-sm leading-6 text-gray-600"
                        dangerouslySetInnerHTML={{ __html: company.description }}
                      />
                    </div>
                  )}
                  <div className="sm:col-span-2 lg:col-span-1 text-sm">
                    <dd className="text-gray-500 leading-6">
                      <span className="font-semibold text-gray-900">{company.name}</span>
                      <br />
                      {company.company_domains[0] && (
                        <a
                          href={`https://${company.company_domains[0]?.domain}`}
                          target="_blank"
                          className="font-medium text-gray-900 underline"
                        >{`https://${company.company_domains[0]?.domain}`}</a>
                      )}
                      <br />
                      <br />
                      {company.addresses && (
                        <>
                          {company.addresses.street} {company.addresses.number}
                          <br />
                          {company.addresses.postal_code} {company.addresses.city}
                          <br />
                          {countries.filter((e: any) => e.code === company.addresses.country)[0]?.value}
                          <br />
                        </>
                      )}
                      {company.uid}
                    </dd>
                  </div>
                </dl>
              </div>
              <div className="px-4 sm:px-6 lg:px-8">
                <div className="mt-12">
                  <h2 className="text-xl font-bold tracking-tight text-gray-900">{t("common.experts")}</h2>
                </div>
                <ul
                  role="list"
                  className="mx-auto mt-12 grid max-w-2xl grid-cols-2 gap-x-8 gap-y-16 text-center md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 lg:mx-0 lg:max-w-none"
                >
                  {company?.company_experts?.map((e: any) => (
                    <li key={e.id}>
                      {e.experts.users.image ? (
                        <img
                          className="mx-auto h-20 w-20 rounded-full border object-cover"
                          src={`https://resources.thesisnavigator.com/${e.experts.users.image}`}
                          alt=""
                        />
                      ) : (
                        <img
                          className="mx-auto h-20 w-20 rounded-full border object-cover"
                          src={e.experts.users.picture}
                          alt=""
                        />
                      )}
                      <h3 className="mt-6 text-base font-semibold leading-7 tracking-tight text-gray-900">
                        {(e.experts.users.first_name || e.experts.users.last_name) && (
                          <>
                            {e.experts.users.first_name} {e.experts.users.last_name}
                          </>
                        )}
                        {!e.experts.users.first_name && !e.experts.users.last_name && (
                          <>{e.experts.users.email.split("@")[0]}</>
                        )}
                      </h3>
                      <p className="text-sm leading-6 text-gray-600">{e.experts.title}</p>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}
