import { useTranslation } from "react-i18next";
import "moment/locale/de";
import { ThesisProjectState } from "../../models";
import { CheckIcon } from "@heroicons/react/24/solid";

const steps = [
  { id: "1", name: "applied", items: ["item1", "item2", "item3"] },
  { id: "2", name: "agreed", items: ["item1", "item2"] },
  { id: "3", name: "completed" },
];
interface Props {
  state: ThesisProjectState;
}

const ThesisProjectStep: React.FC<Props> = ({ state }) => {
  const { t } = useTranslation();

  const isCompleted = (step: any) => {
    const currentStep = steps.find((e: any) => e.name === state);
    if (step.id < (currentStep?.id || 0) || state === ThesisProjectState.Completed) {
      return true;
    }
    return false;
  };

  return (
    <nav aria-label="Progress">
      <ol
        role="list"
        className="divide-y divide-white/5 shadow-sm ring-1 ring-gray-900/5 rounded-lg md:flex md:divide-y-0"
      >
        {steps.map((step, stepIdx) => (
          <li key={step.name} className="relative md:flex md:flex-1">
            {isCompleted(step) ? (
              <div className="group flex w-full items-start">
                <span className="flex items-start px-6 py-4 text-sm font-medium">
                  <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full bg-gray-900">
                    <CheckIcon className="h-5 w-5 text-white" aria-hidden="true" />
                  </span>
                  <span className="ml-4 mt-0.5 flex min-w-0 flex-col">
                    <span className="text-sm font-semibold text-gray-900 pt-2">
                      {t(`thesisProjects.state.${step.name}`)}
                    </span>
                    {step.items && (
                      <ul className="list-disc text-gray-500 font-normal text-xs pt-3 pl-6 gap-2 leading-4">
                        {step.items.map((item) => (
                          <li key={`step-${step.name}-${item}`} className="pb-1">
                            {t(`thesisProjects.steps.${step.name}.${item}`)}
                          </li>
                        ))}
                      </ul>
                    )}
                  </span>
                </span>
              </div>
            ) : step.name === state ? (
              <div className="flex items-start px-6 py-4 text-sm font-medium" aria-current="step">
                <span className="flex-shrink-0">
                  <span className="flex h-10 w-10 items-center justify-center rounded-full border-2 border-gray-900">
                    <span className="text-gray-900 font-semibold">{step.id}</span>
                  </span>
                </span>
                <span className="ml-4 mt-0.5 flex min-w-0 flex-col">
                  <span className="text-sm font-semibold text-gray-900 pt-2">
                    {t(`thesisProjects.state.${step.name}`)}
                  </span>
                  {step.items && (
                    <ul className="list-disc text-gray-500 font-normal text-xs pt-3 pl-6 gap-2 leading-4">
                      {step.items.map((item) => (
                        <li key={`step-${step.name}-${item}`} className="pb-1">
                          {t(`thesisProjects.steps.${step.name}.${item}`)}
                        </li>
                      ))}
                    </ul>
                  )}
                </span>
              </div>
            ) : (
              <div className="flex items-start px-6 py-4 text-sm font-medium" aria-current="step">
                <span className="flex-shrink-0">
                  <span className="flex h-10 w-10 items-center justify-center rounded-full border-2 border-gray-300">
                    <span className="text-gray-500 font-semibold">{step.id}</span>
                  </span>
                </span>
                <span className="ml-4 mt-0.5 flex min-w-0 flex-col">
                  <span className="text-sm font-semibold text-gray-500 pt-2">
                    {t(`thesisProjects.state.${step.name}`)}
                  </span>
                  {step.items && (
                    <ul className="list-disc text-gray-500 font-normal text-xs pt-3 pl-6 gap-2 leading-4">
                      {step.items.map((item) => (
                        <li key={`step-${step.name}-${item}`} className="pb-1">
                          {t(`thesisProjects.steps.${step.name}.${item}`)}
                        </li>
                      ))}
                    </ul>
                  )}
                </span>
              </div>
            )}

            {stepIdx !== steps.length - 1 ? (
              <>
                <div className="absolute right-0 top-0 hidden h-full w-5 md:block" aria-hidden="true">
                  <svg
                    className="h-full w-full text-gray-200"
                    viewBox="0 0 22 80"
                    fill="none"
                    preserveAspectRatio="none"
                  >
                    <path
                      d="M0 -2L20 40L0 82"
                      vectorEffect="non-scaling-stroke"
                      stroke="currentcolor"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              </>
            ) : null}
          </li>
        ))}
      </ol>
    </nav>
  );
};

export default ThesisProjectStep;
