import { useAuth0 } from "@auth0/auth0-react";
import { useState, useEffect, useContext } from "react";
import { NavLink } from "react-router-dom";
import httpsClient, { addAccessTokenInterceptor } from "../../config/https";
import { AcademicCapIcon, CheckCircleIcon } from "@heroicons/react/24/solid";
import { PlusIcon, ChevronRightIcon } from "@heroicons/react/20/solid";
import { useTranslation } from "react-i18next";
import { PencilIcon, UserGroupIcon } from "@heroicons/react/24/outline";
import { CurrentUserContext } from "../../contexts/userContext";
import Loader from "../../components/Loader";

export default function SupervisorDashboard() {
  const { currentUser } = useContext(CurrentUserContext);
  const { user, getAccessTokenSilently, isAuthenticated, isLoading } = useAuth0();
  const [isFetching, setIsFetching] = useState(true);
  const [topics, setTopics] = useState([]);
  const [publishedTopics, setPublishedTopics] = useState([]);
  const [draftTopics, setDraftTopics] = useState([]);
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const getUserTopics = async () => {
    try {
      setIsFetching(true);
      const response = await httpsClient.get(`/api/v1/user/topics`);
      setTopics(response.data);
      setPublishedTopics(response.data.filter((e: any) => e.published));
      setDraftTopics(response.data.filter((e: any) => !e.published));
      setIsFetching(false);
    } catch {
      setIsFetching(false);
    }
  };

  useEffect(() => {
    addAccessTokenInterceptor(getAccessTokenSilently);
  }, [getAccessTokenSilently]);

  useEffect(() => {
    if (currentUser?.id) {
      getUserTopics();
    }
  }, [currentUser]);

  return (
    <div className="bg-white">
      <div className="mx-auto max-w-2xl text-center pb-12">
        <h2 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">
          {t("dashboard.supervisors.welcome.title")}
        </h2>
        <p
          className="mx-auto mt-6 leading-6 text-gray-500"
        // dangerouslySetInnerHTML={{ __html: t("dashboard.students.welcome.description") }}
        >
          {t("dashboard.supervisors.welcome.description")}
        </p>
      </div>
      <div className="text-center py-12">
        <div className="mx-auto">
          <div className="grid grid-cols-1 lg:grid-cols-1 lg:items-start justify-start lg:gap-12">
            {/* <div className="pb-12 lg:pr-6 lg:pb-0">
              <h2 className="text-xl font-bold tracking-tight text-gray-900 sm:text-2xl">
                {t("dashboard.supervisors.profile.title")}
              </h2>
              <p className="mx-auto mt-6 leading-6 text-gray-500 text-left text-sm">
                {t("dashboard.supervisors.profile.description")}
              </p>
              <ul role="list" className="mt-6 max-w-xl space-y-6 text-gray-600 text-left">
                <li className="flex gap-x-3">
                  <CheckCircleIcon className="mt-0.5 h-5 w-5 flex-none text-gray-900" aria-hidden="true" />
                  <span>
                    <strong className="font-semibold text-gray-900 text-sm">
                      {t("dashboard.supervisors.profile.item1.title")}
                    </strong>
                    <p className="text-sm text-gray-500">{t("dashboard.supervisors.profile.item1.description")}</p>
                  </span>
                </li>
                <li className="flex gap-x-3">
                  <CheckCircleIcon className="mt-0.5 h-5 w-5 flex-none text-gray-900" aria-hidden="true" />
                  <span>
                    <strong className="font-semibold text-sm text-gray-900">
                      {t("dashboard.supervisors.profile.item2.title")}
                    </strong>
                    <p className="text-sm text-gray-500">{t("dashboard.supervisors.profile.item2.description")}</p>
                  </span>
                </li>
                <li className="flex gap-x-3">
                  <CheckCircleIcon className="mt-0.5 h-5 w-5 flex-none text-gray-900" aria-hidden="true" />
                  <span>
                    <strong className="font-semibold text-sm text-gray-900">
                      {t("dashboard.supervisors.profile.item3.title")}
                    </strong>
                    <p className="text-sm text-gray-500">{t("dashboard.supervisors.profile.item3.description")}</p>
                  </span>
                </li>
              </ul>
              <div className="mt-12 text-center">
                <NavLink
                  to="/user/profile"
                  className="rounded-md bg-gray-900 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-gray-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
                >
                  {t("dashboard.supervisors.profile.button")}
                </NavLink>
              </div>
            </div> */}
            <div className="">
              <h2 className="text-xl font-bold tracking-tight text-gray-900 sm:text-2xl">
                {t("dashboard.supervisors.activity.title")}
              </h2>
              {isAuthenticated && !isLoading && isFetching && (
                <div className="my-6 flex justify-center items-center">
                  <Loader />
                </div>
              )}
              {isAuthenticated && !isLoading && !isFetching && (
                <div className="text-left pt-8">
                  <ul role="list" className="divide-y divide-gray-200 border-b border-gray-200">
                    <li key={"1"}>
                      <div className="group relative flex items-start space-x-3 pb-4">
                        <div className="flex-shrink-0">
                          <span className="inline-flex h-10 w-10 items-center justify-center rounded-lg bg-pink-500">
                            <AcademicCapIcon className="h-6 w-6 text-white" aria-hidden="true" />
                          </span>
                        </div>
                        <div className="min-w-0 flex-1">
                          <div className="text-sm font-semibold text-gray-900">
                            <NavLink to="/topics">
                              <span className="absolute inset-0" aria-hidden="true" />
                              {t("dashboard.supervisors.activity.topics.title")}
                            </NavLink>
                          </div>
                          <p className="text-sm text-gray-500">
                            {t("dashboard.supervisors.activity.topics.description")}
                          </p>
                        </div>
                        <div className="flex-shrink-0 self-center">
                          <ChevronRightIcon
                            className="h-5 w-5 text-gray-400 group-hover:text-gray-500"
                            aria-hidden="true"
                          />
                        </div>
                      </div>
                    </li>
                    <li key={"2"}>
                      <div className="group relative flex items-start space-x-3 py-4">
                        <div className="flex-shrink-0">
                          <span className="inline-flex h-10 w-10 items-center justify-center rounded-lg bg-yellow-500">
                            <UserGroupIcon className="h-6 w-6 text-white" aria-hidden="true" />
                          </span>
                        </div>
                        <div className="min-w-0 flex-1">
                          <div className="text-sm font-semibold text-gray-900">
                            <NavLink to="/university/supervisors">
                              <span className="absolute inset-0" aria-hidden="true" />
                              {t("dashboard.supervisors.activity.inviteSupervisors.title")}
                            </NavLink>
                          </div>
                          <p className="text-sm text-gray-500">
                            {t("dashboard.supervisors.activity.inviteSupervisors.description")}
                          </p>
                        </div>
                        <div className="flex-shrink-0 self-center">
                          <ChevronRightIcon
                            className="h-5 w-5 text-gray-400 group-hover:text-gray-500"
                            aria-hidden="true"
                          />
                        </div>
                      </div>
                    </li>
                  </ul>
                  <div className="mt-6 flex">
                    <NavLink to="/topics" className="text-sm font-medium text-gray-900 hover:text-gray-800 underline">
                      {t("dashboard.supervisors.activity.previewAllTopics")}
                      <span aria-hidden="true"> &rarr;</span>
                    </NavLink>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
