import { array, object, string } from "yup";
import { useAuth0 } from "@auth0/auth0-react";
import { useContext, useEffect, useState } from "react";
import { CurrentUserContext } from "../../contexts/userContext";
import Loader from "../../components/Loader";
import { Controller, useForm } from "react-hook-form";
import { internships } from "../../models";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import { InformationCircleIcon } from "@heroicons/react/24/outline";
import { MessageType, useMessagesContext } from "../../contexts/messagesContext";
import { TextEditor } from "../../components/TipTap/TextEditor";
import {Field, SupervisorTopicCreate, SupervisorTopicUpdate, Topic, TopicLevelsEnum} from "@api/generated";
import Api from "../../api/client";
import {Breadcrumb, BreadcrumbList, BreadcrumbListItem, BreadcrumbSeparator} from "../../components/breadcrumb";

const levelOptions = [
  { value: "bsc", label: "bsc", checked: false },
  { value: "msc", label: "msc", checked: false },
  { value: "phd", label: "phd", checked: false },
];

export const SupervisorTopicForm: React.FC<any> = () => {
  const { id } = useParams();
  const { currentUser } = useContext(CurrentUserContext);
  const { isAuthenticated, isLoading } = useAuth0();
  const [isFetching, setIsFetching] = useState(id ? true : false);
  const [topic, setTopic] = useState<Topic | undefined>(undefined);
  const [fields, setFields] = useState<Field[]>([]);
  const { t, i18n } = useTranslation();
  const [isUpdating, setIsUpdating] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const isUniversityRoute = location.pathname.includes("/university/topics");
  const { addMessage } = useMessagesContext();

  const validationSchema = object().shape({
    title: string().required(t("common.required")),
    description: string().required(t("common.required")).notOneOf(['<p></p>'], t("common.required")).trim(),
    internship: string(),
    requirements: string().nullable(),
    levels: array().of(string()).min(1).required(),
    fields: array().of(string()).min(1).max(3).required(),
  });

  const {
    control,
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      title: "",
      description: "",
      internship: "no",
      requirements: "",
      levels: ["bsc", "msc", "phd"],
      fields: currentUser.fields || [],
    },
    mode: "onChange"
  });

  const getTopic = async () => {
    setIsFetching(true);
    await Api.SupervisorTopics.findSupervisorTopicById({
      id: id
    })
      .then((response)=> {
        if (
          currentUser.universityId !== response.data.university.id ||
          !response.data.supervisors?.some(user => user.id === currentUser.id)
        ) {
          navigate("/error/forbidden");
        }
        setTopic(response.data);
        setIsFetching(false);
      })
      .catch((error) => {
        setIsFetching(false);
        if (error.response && error.response.status === 403) {
          navigate("/error/forbidden");
        } else {
          addMessage({
            type: MessageType.Error,
            title: "common.error.title",
            description: "common.error.description",
          });
        }
        console.error("Error fetching data:", error);
      });
  };

  const getFields = async () => {
    await Api.CommonFields.findAllFields()
      .then((response) => {
        setFields(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const createTopic = async (data: SupervisorTopicCreate) => {
    try {
      setIsUpdating(true);
      const sanitizedData: SupervisorTopicCreate = {
        ...data,
        description: data.description === '<p></p>' ? '' : data.description,
        requirements: data.requirements === '<p></p>' ? '' : data.requirements,
      };
      const response = await Api.SupervisorTopics.createSupervisorTopic({
        supervisorTopicCreate: {
          title: sanitizedData.title,
          description: sanitizedData.description,
          internship: sanitizedData.internship,
          requirements: sanitizedData.requirements,
          fields: sanitizedData.fields,
          levels: sanitizedData.levels,
          published: false,
        }
      })
      setIsUpdating(false);
      reset();
      addMessage({
        type: MessageType.Success,
        title: "topics.topic.create.notification.title",
        description: "topics.topic.create.notification.description",
      });
      if (isUniversityRoute) {
        navigate(`/university/topics/${response.data.id}`);
      } else {
        navigate(`/supervisor/topics/${response.data.id}`);
      }
    } catch {
      setIsUpdating(false);
      addMessage({
        type: MessageType.Error,
        title: "common.error.title",
        description: "common.error.description",
      });
    }
  };

  const updateTopic = async (data: SupervisorTopicUpdate) => {
    setIsFetching(true);
    const sanitizedData: SupervisorTopicUpdate = {
      ...data,
      description: data.description === '<p></p>' ? '' : data.description,
      requirements: data.requirements === '<p></p>' ? '' : data.requirements,
    };
    await Api.SupervisorTopics.updateSupervisorTopic({
      id: id,
      supervisorTopicUpdate: {
        title: sanitizedData.title,
        description: sanitizedData.description,
        internship: sanitizedData.internship,
        requirements: sanitizedData.requirements,
        fields: sanitizedData.fields,
        levels: sanitizedData.levels,
      }
    })
      .then(() => {
        setIsUpdating(false);
        addMessage({
          type: MessageType.Success,
          title: "topics.topic.update.notification.title",
          description: "topics.topic.update.notification.description",
        });
        if (isUniversityRoute) {
          navigate(`/university/topics/${id}`);
        } else {
          navigate(`/supervisor/topics/${id}`);
        }
      })
      .catch((error) => {
        setIsFetching(false);
        if (error.response && error.response.status === 403) {
          navigate("/error/forbidden");
        } else {
          addMessage({
            type: MessageType.Error,
            title: "common.error.title",
            description: "common.error.description",
          });
        }
        console.error("Error fetching data:", error);
      });
  };

  const onCancel = () => {
    if (id) {
      if (isUniversityRoute) {
        navigate(`/university/topics/${id}`);
      } else {
        navigate(`/supervisor/topics/${id}`);
      }
    } else {
      if (isUniversityRoute) {
        navigate(`/university/topics`);
      } else {
        navigate(`/supervisor/topics`);
      }
    }
  };

  const onSubmit = (data) => {
    if (id) {
      updateTopic(data);
    } else {
      createTopic(data);
    }
  };

  useEffect(() => {
    if (topic?.id) {
      const defaultValues: any = {};
      defaultValues.title = topic?.title;
      defaultValues.description = topic?.description;
      defaultValues.internship = topic.internship;
      defaultValues.fields = topic.fields?.map(e => e.id) || [];
      defaultValues.requirements = topic.requirements;
      defaultValues.levels = topic.levels;
      reset({ ...defaultValues });
    }
  }, [topic]);

  useEffect(() => {
    if (currentUser) {
      getFields();
    }
    if (currentUser.type === 'supervisor' && id) {
      getTopic();
    }
  }, [currentUser, id]);

  const classNames = (...classes: any) => {
    return classes.filter(Boolean).join(" ");
  };

  return (
    <div className="bg-white">
      <Breadcrumb>
        <BreadcrumbList>
          <BreadcrumbListItem>
            <NavLink to={isUniversityRoute ? `/university/profile` : `/user/profile`}>
              {isUniversityRoute ? t("company.profile") : t("common.myProfile")}
            </NavLink>
          </BreadcrumbListItem>
          <BreadcrumbSeparator/>
          <BreadcrumbListItem>
            <NavLink to={isUniversityRoute ? "/university/topics" : "/supervisor/topics"}>
              {isUniversityRoute ? t("company.topics") : t("common.myTopics")}
            </NavLink>
          </BreadcrumbListItem>
          {id && !isFetching && (
            <>
              <BreadcrumbSeparator/>
              <BreadcrumbListItem>
                <NavLink to={isUniversityRoute ? `/university/topics/${id}` : `/supervisor/topics/${id}`}>
                  {topic?.title}
                </NavLink>
              </BreadcrumbListItem>
            </>
          )}
        </BreadcrumbList>
      </Breadcrumb>
      {isFetching && (
        <div className="flex justify-center items-center mt-6">
          <Loader />
        </div>
      )}
      {isAuthenticated && !isLoading && !isFetching && (
        <form autoComplete="off">
          <div className="pb-6">
            <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl tracking-tight mt-2">
              {id ? t("topics.form.editTopicTitle") : t("topics.form.newTopicTitle")}
            </h2>
            <p className="mt-1 text-sm leading-6 text-gray-500">{t("topics.form.subtitle")}</p>
            <div className="mt-12 grid grid-cols-1 gap-x-6 gap-y-6 sm:grid-cols-6">
              <div className="sm:col-span-full">
                <label htmlFor="comment" className="block text-sm font-medium leading-6 text-gray-900">
                  {t("topics.form.title.title")}
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    placeholder={t("topics.form.title.placeholder")}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-900 text-sm sm:leading-6"
                    defaultValue={""}
                    {...register("title")}
                  />
                  {errors.title && <p className="mt-2 text-xs text-red-600">{errors.title?.message?.toString()}</p>}
                </div>
              </div>

              <div className="sm:col-span-full">
                <label className="block text-sm font-medium leading-6 text-gray-900">{t("Level")}</label>
                <div className="mt-2 sm:flex sm:space-x-6">
                  {levelOptions.map((option, optionIdx) => (
                    <div key={option.value} className="flex items-center mt-2 sm:mt-0">
                      <input
                        id={`level-${optionIdx}`}
                        name={`levels[${option.value}]`}
                        defaultValue={option.value}
                        type="checkbox"
                        {...register("levels")}
                        className="h-4 w-4 flex-shrink-0 rounded border-gray-300 text-gray-900 focus:ring-gray-800 cursor-pointer"
                      />
                      <label
                        htmlFor={`level-${optionIdx}`}
                        className="ml-3 min-w-0 flex-1 text-sm font-medium leading-6 text-gray-900 cursor-pointer"
                      >
                        {t(`level.${option.label}`)}
                      </label>
                    </div>
                  ))}
                </div>
                {errors.levels && <p className="mt-2 text-xs text-red-600">{errors.levels?.message?.toString()}</p>}
              </div>

              <div className="sm:col-span-full">
                <label className="block text-sm font-medium leading-6 text-gray-900">
                  {t("topics.form.internship.title")}
                </label>
                <p className="text-sm text-gray-500">{t("topics.form.internship.subtitle")}</p>
                <fieldset className="mt-4">
                  <legend className="sr-only">{t("topics.form.internship.title")}</legend>
                  <div className="flex space-x-6">
                    {internships.map((e) => (
                      <div key={e.id} className="flex items-center">
                        <input
                          id={e.id}
                          type="radio"
                          value={e.id}
                          // defaultChecked={e.id === "yes"}
                          className="h-4 w-4 border-gray-300 text-gray-900 focus:ring-gray-900"
                          {...register("internship")}
                        />
                        <label htmlFor={e.id} className="ml-3 text-sm font-medium leading-6 text-gray-900">
                          {t(`topics.form.internship.${e.id}`)}
                        </label>
                      </div>
                    ))}
                  </div>
                </fieldset>
              </div>

              <div className="sm:col-span-full">
                <div className="flex items-center gap-x-2">
                  <label htmlFor="comment" className="block text-sm font-medium leading-6 text-gray-900">
                    {t("topics.form.description.title")}
                  </label>
                  <div className="inline-block relative group/info">
                    <InformationCircleIcon
                      className="text-gray-300 hover:text-gray-400 w-5 flex-shrink-0 z-10 cursor-pointer"
                      aria-hidden="true"
                    />
                    <div
                      className="absolute transition delay-300 duration-300 left-1/2 bottom-8 text-center transform -translate-x-1/2 bg-gray-800 text-white rounded-md px-2.5 py-1.5 text-xs font-medium w-60 z-50 pointer-events-none opacity-0 transition-opacity group-hover/info:opacity-100">
                      {t("topics.form.description.placeholder")}
                      <div
                        className="invisible absolute left-1/2 -translate-x-1 bg-inherit before:visible before:absolute before:h-2.5 before:w-2.5 before:rotate-45 before:bg-inherit before:content-['']"></div>
                    </div>
                  </div>
                </div>
                <div className="mt-2 whitespace-pre-line">
                  <Controller
                    control={control}
                    render={({field}) => (
                      <TextEditor
                        content={field.value}
                        placeholder={t("topics.form.description.placeholder")}
                        onChange={field.onChange}
                      />
                    )}
                    name="description"
                  />
                  {errors.description && (
                    <p className="mt-2 text-xs text-red-600">{errors.description?.message?.toString()}</p>
                  )}
                </div>
              </div>

              <div className="sm:col-span-full">
                <label htmlFor="comment" className="block text-sm font-medium leading-6 text-gray-900">
                  {t("topics.form.fields.title")}
                </label>
                <p className="text-sm text-gray-500">{t("topics.form.fields.subtitle")}</p>

                <div className="mt-4 whitespace-pre-line">
                  <Controller
                    name="fields"
                    control={control}
                    render={({field}) => (
                      <div className="w-full flex flex-wrap gap-2">
                        {fields.map((e) => {
                          const isSelected = field.value?.includes(e.id);
                          const isDisabled = !isSelected && field.value.length >= 3;
                          return (
                            <button
                              key={e.id}
                              type="button"
                              onClick={() => {
                                if (!isDisabled) {
                                  const newValue = isSelected
                                    ? field.value.filter((id) => id !== e.id)
                                    : [...field.value, e.id];
                                  field.onChange(newValue);
                                }
                              }}
                              disabled={isDisabled}
                              className={`items-center rounded-full px-3 py-1.5 text-xs font-medium border ${isSelected ? 'bg-gray-900 text-white border-transparent' : 'text-gray-900 border-gray-300'} ${isDisabled ? 'opacity-50 cursor-not-allowed' : ''}`}
                            >
                              {t(`fields.${e.name}`)}
                            </button>
                          );
                        })}
                      </div>
                    )}
                  />
                  {errors.fields && <p className="mt-2 text-xs text-red-600">{errors.fields.message}</p>}
                </div>
              </div>

              <div className="sm:col-span-full">
                <div className="flex items-center gap-x-2">
                  <label htmlFor="comment" className="block text-sm font-medium leading-6 text-gray-900">
                    {t("topics.form.requirements.title")}
                  </label>
                  <div className="inline-block relative group/info">
                    <InformationCircleIcon
                      className="text-gray-300 hover:text-gray-400 w-5 flex-shrink-0 z-10 cursor-pointer"
                      aria-hidden="true"
                    />
                    <div
                      className="absolute transition delay-300 duration-300 left-1/2 bottom-8 text-center transform -translate-x-1/2 bg-gray-800 text-white rounded-md px-2.5 py-1.5 text-xs font-medium w-60 z-50 pointer-events-none opacity-0 transition-opacity group-hover/info:opacity-100">
                      {t("topics.form.requirements.placeholder")}
                      <div
                        className="invisible absolute left-1/2 -translate-x-1 bg-inherit before:visible before:absolute before:h-2.5 before:w-2.5 before:rotate-45 before:bg-inherit before:content-['']"></div>
                    </div>
                  </div>
                </div>
                <div className="mt-2 whitespace-pre-line">
                  <Controller
                    control={control}
                    render={({field}) => (
                      <TextEditor
                        content={field.value}
                        placeholder={t("topics.form.requirements.placeholder")}
                        onChange={field.onChange}
                      />
                    )}
                    name="requirements"
                  />
                  {errors.requirements && (
                    <p className="mt-2 text-xs text-red-600">{errors.requirements?.message?.toString()}</p>
                  )}
                </div>
              </div>

            </div>
          </div>

          <div className="mt-6 flex items-center justify-end gap-x-6">
            {isUpdating && <Loader/>}
            {!isUpdating && (
              <>
                <button type="button" onClick={onCancel} className="text-sm font-semibold leading-6 text-gray-900">
                  {t("common.cancel")}
                </button>
                <button
                  type="submit"
                  onClick={handleSubmit(onSubmit)}
                  className="rounded-md bg-gray-900 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
                >
                  {t("common.save")}
                </button>
              </>
            )}
          </div>
        </form>
      )}
    </div>
  );
};
