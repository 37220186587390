import { useTranslation } from "react-i18next";
import moment from "moment";
import "moment/locale/de";
import { AcademicCapIcon } from "@heroicons/react/24/solid";

interface Props {
  supervisor: any;
}

const TopicSupervisor: React.FC<Props> = ({ supervisor }) => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  return (
    <div className="divide-y divide-gray-100 overflow-y-auto ">
      <div className="flex-none p-6 text-center">
        <img src={supervisor?.user.image
          ? `https://resources.thesisnavigator.com/${supervisor?.user.image}`
          : supervisor?.user.picture.imageUrl} alt="" className="mx-auto h-16 w-16 rounded-full border object-cover" />
        {(supervisor?.user.first_name || supervisor?.user.first_name) && (
          <h2 className="mt-3 font-bold text-gray-900">{supervisor?.user.first_name} {supervisor?.user.last_name}</h2>
        )}
        {(supervisor?.title) && (
          <p className="text-sm leading-6 text-gray-500">{supervisor?.title}</p>
        )}
        <p className="text-xs leading-6 text-gray-500">{supervisor?.university.name}</p>
      </div>
      <div className="flex flex-auto flex-col justify-between p-6">
        <dl className="grid grid-cols-1 gap-x-6 gap-y-3 text-sm text-gray-700">
          {supervisor?.research_area && (
            <>
              <dt className="col-end-1 font-semibold text-gray-900">{t("profile.supervisor.expertise.research.title")}</dt>
              <dd>{supervisor?.research_area}</dd>
            </>
          )}
          {supervisor?.professional_network_profile && (
            <>
              <dt className="col-end-1 font-semibold text-gray-900">{t("profile.supervisor.expertise.professionalNetworkProfile.title")}</dt>
              <dd><a href={supervisor?.professional_network_profile} target="_blank" className="text-gray-900 underline">
                {supervisor?.professional_network_profile}
              </a></dd>
            </>
          )}
          {supervisor?.research_network_profile && (
            <>
              <dt className="col-end-1 font-semibold text-gray-900">{t("profile.supervisor.expertise.researchNetworkProfile.title")}</dt>
              <dd><a href={supervisor?.research_network_profile} target="_blank" className="text-gray-900 underline">
                {supervisor?.research_network_profile}
              </a></dd>
            </>
          )}
          <dt className="col-end-1 font-semibold text-gray-900">{t("profile.user.email")}</dt>
          <dd className="truncate">
            <a href={`mailto:${supervisor?.user.email}`} target="_blank" className="text-gray-900 underline">
              {supervisor?.user.email}
            </a>
          </dd>
        </dl>
      </div>
    </div>
  );
};

export default TopicSupervisor;
