import React, {PropsWithChildren} from "react";
import {CalendarIcon} from "@heroicons/react/24/solid";
import moment from "moment/moment";
import {useTranslation} from "react-i18next";
import {User} from "@api/generated";

// Define the prop types
interface ListItemLineStatusProps {
  created?: Date | string;
  updated?: Date | string;
  users?: User[] | null;
  experts?: number | null;
  badges?: ListItemLineStatusBadge[];
  className?: string;
}

export interface ListItemLineStatusBadge {
  label: string;
  color?: 'red' | 'green' | 'yellow' | "gray";
}

// Functional component with props
const ListItemLineStatus: React.FC<PropsWithChildren<ListItemLineStatusProps>> = (props) => {
  const {t, i18n} = useTranslation();
  const currentLanguage = i18n.language;

  return (
    <div className={`flex flex-wrap gap-x-4 gap-y-2 items-center ${props.className}`}>
      <div className="flex items-center text-sm text-gray-400 space-x-4">
        <CalendarIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true"/>
        {(!props.updated || props.created === props.updated) && <>{moment(props.created).locale(currentLanguage).format("lll")}</>}
        {props.updated && props.created !== props.updated && (
          <>
            {t("common.updated")} {moment(props.updated).locale(currentLanguage).format("lll")}
          </>
        )}
      </div>
      {props.users && (
        <div className="flex overflow-hidden -space-x-1.5 flex-shrink-0">
          {props.users.map((user) =>
            user.image ? (
              <img
                className="inline-block h-6 w-6 rounded-full border object-cover"
                src={`https://resources.thesisnavigator.com/${user.image}`}
                alt=""
              />
            ) : (
              <img
                className="inline-block h-6 w-6 rounded-full border object-cover"
                src={user.picture || undefined}
                alt=""
              />
            )
          )}
        </div>
      )}
      {props.experts > 0 && (
        <div className="flex items-center text-sm text-gray-400">
          <svg viewBox="0 0 2 2" className="h-0.5 w-0.5 fill-gray-300">
            <circle cx={1} cy={1} r={1}/>
          </svg>
          <span className="px-1">
            {props.experts}
          </span>
          {props.experts === 1 ? t("common.expert") : t("common.experts")}
        </div>
      )}
      {props.badges && (
        props.badges.map((badge, index) => {
            let color = "border border-gray-300 text-gray-900";
            switch (badge.color) {
              case "red": {
                color = "bg-pink-50 text-pink-700";
                break;
              }
              case "green": {
                color = "bg-green-100 text-green-700";
                break;
              }
              case "yellow": {
                color = "bg-yellow-100 text-yellow-800";
                break;
              }
              case "gray": {
                color = "bg-gray-100 text-gray-600";
                break;
              }
            }
            return (
              <p key={index}
                 className={`inline-flex items-center rounded-full px-3 py-1.5 text-xs font-medium ${color}`}>
                {badge.label}
              </p>
            )
          }
        )
      )}
    </div>
  );
};

// Export the component
export default React.memo(ListItemLineStatus);
