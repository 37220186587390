import { useAuth0 } from "@auth0/auth0-react";
import { Fragment, useCallback, useContext, useEffect, useRef, useState } from "react";
import { CurrentUserContext } from "../../contexts/userContext";
import Loader from "../../components/Loader";
import httpsClient, { addAccessTokenInterceptor } from "../../config/https";
import { useTranslation } from "react-i18next";
import {
  CalendarIcon,
  PencilIcon,
  ChevronDownIcon,
  CheckBadgeIcon,
  EllipsisVerticalIcon,
  CheckCircleIcon as CheckCircleIconSolid,
  XCircleIcon,
  ChevronRightIcon,
  PaperClipIcon,
} from "@heroicons/react/24/solid";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import "moment/locale/de";
import { Menu, Transition } from "@headlessui/react";
import {
  ThesisProjectState,
  UserType,
  expertThesisProjectStateFlow,
  studentThesisProjectStateFlow,
} from "../../models";
import { object, string } from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import Linkify from "linkify-react";
import { thesisProjectStates } from "../../models/index";
import { CheckCircleIcon } from "@heroicons/react/24/outline";
import Modal from "../../components/Modal";
import { ThesisProjectStateForm } from "./ThesisProjectStateForm";
import { MessageType, useMessagesContext } from "../../contexts/messagesContext";
import TruncateText from "../../components/TruncateText";
import { isMobile } from "react-device-detect";
import ThesisProjectStep from "./ThesisProjectStep";

export default function ThesisProject() {
  const { currentUser } = useContext(CurrentUserContext);
  const { id } = useParams();
  const { user, getAccessTokenSilently, isAuthenticated, isLoading } = useAuth0();
  const [isFetching, setIsFetching] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [token, setToken] = useState("");
  const [isEditable, setIsEditable] = useState<boolean>(true);
  const [thesisProject, setThesisProject] = useState<any>({});
  const [thesisProjectState, setThesisProjectState] = useState(null);
  const [activity, setActivity] = useState<any>([]);
  const [editingCommentId, setEditingCommentId] = useState(null);
  const [editedComment, setEditedComment] = useState("");
  const [eligibleStates, setEligibleStates] = useState<any>([]);
  const commentRef = useRef<any>(null);
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const currentLanguage = i18n.language;
  const isStudent = currentUser?.type === UserType.Student;
  const isExpert = currentUser?.type === UserType.Expert;
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [topicTitle, setTopicTitle] = useState("");
  const location = useLocation();
  const isCompanyRoute = location.pathname.includes("/company/thesis-projects");
  const { addMessage } = useMessagesContext();

  const validationSchema = object().shape({
    comment: object().shape({
      description: string().required(),
    }),
  });

  const {
    reset,
    register,
    handleSubmit,
    formState: { isValid },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const isWithinWeek = function (date: any) {
    return moment().diff(date, "weeks") < 1;
  };

  const fetchToken = useCallback(async () => {
    const data = await getAccessTokenSilently();
    setToken(data);
  }, []);

  const getThesisProject = async (silently?: boolean) => {
    if (!silently) {
      setIsFetching(true);
    }
    await httpsClient
      .get(`/api/v1/thesis-projects/${id}`)
      .then((response) => {
        setThesisProject(response.data);
        if (isStudent) {
          setEligibleStates(studentThesisProjectStateFlow[response.data.state]);
        }
        if (isExpert) {
          setEligibleStates(expertThesisProjectStateFlow[response.data.state]);
        }
        if (
          ["owner", "admin"].includes(currentUser?.role) ||
          response.data?.thesis_project_experts.some((e: any) => e?.experts?.user_id === currentUser?.id)
        ) {
          setIsEditable(true);
        }
        setIsFetching(false);
      })
      .catch((error) => {
        setIsFetching(false);
        if (error.response && error.response.status === 403) {
          navigate("/error/forbidden");
        }
        console.error("Error fetching data:", error);
      });
  };

  const getThesisProjectActivity = async (silently?: boolean) => {
    if (!silently) {
      setIsFetching(true);
    }
    await httpsClient
      .get(`/api/v1/thesis-projects/${id}/activity`)
      .then((response) => {
        setActivity(response.data);
        setIsFetching(false);
      })
      .catch((error) => {
        setIsFetching(false);
        console.error("Error fetching data:", error);
      });
  };

  const createThesisProjectComment = async (comment: any) => {
    setIsUpdating(true);
    await httpsClient
      .post(`/api/v1/thesis-projects/${id}/comments`, { description: comment?.description })
      .then((response) => {
        setActivity([
          ...activity,
          {
            id: response.data.id,
            type: "comment",
            comment: response.data,
          },
        ]);
        reset();
        setIsUpdating(false);
      })
      .catch((error) => {
        setIsUpdating(false);
        addMessage({
          type: MessageType.Error,
          title: "common.error.title",
          description: "common.error.description",
        });
      });
  };

  const updateThesisProjectComment = async () => {
    setIsUpdating(true);
    await httpsClient
      .patch(`/api/v1/thesis-projects/${id}/comments/${editingCommentId}`, { description: editedComment })
      .then((response) => {
        setActivity([
          ...activity.map((e: any) =>
            e.id === editingCommentId
              ? {
                  id: response.data.id,
                  type: "comment",
                  comment: response.data,
                }
              : e
          ),
        ]);
        setEditingCommentId(null);
        setEditedComment("");
        setIsUpdating(false);
      })
      .catch((error) => {
        setIsUpdating(false);
        addMessage({
          type: MessageType.Error,
          title: "common.error.title",
          description: "common.error.description",
        });
      });
  };

  const deleteThesisProjectComment = async (commentId: string) => {
    setIsUpdating(true);
    await httpsClient
      .delete(`/api/v1/thesis-projects/${id}/comments/${commentId}`)
      .then(() => {
        setActivity([...activity.filter((e: any) => e.id !== commentId)]);
        setIsUpdating(false);
      })
      .catch((error) => {
        setIsUpdating(false);
        addMessage({
          type: MessageType.Error,
          title: "common.error.title",
          description: "common.error.description",
        });
      });
  };

  const getUserImage = function (user: any) {
    return user?.image ? `https://resources.thesisnavigator.com/${user.image}` : user?.picture;
  };

  const getResourcePresignedUrl = async (key: string) => {
    await httpsClient
      .post(`/api/v1/thesis-projects/resources/presignedUrl`, { key })
      .then((response) => {
        window.open(response.data.signedUrl, "_blank");
      })
      .catch(() => {});
  };

  const onCommentSubmit = (data: any) => {
    createThesisProjectComment(data.comment);
  };

  const onEditedCommentSubmit = () => {
    updateThesisProjectComment();
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const onCancel = () => {
    closeModal();
  };

  const onSave = (data: any) => {
    setThesisProject(data);
    if (isStudent) {
      setEligibleStates(studentThesisProjectStateFlow[data.state]);
    }
    if (isExpert) {
      setEligibleStates(expertThesisProjectStateFlow[data.state]);
    }
    getThesisProjectActivity(true);
    closeModal();
  };

  useEffect(() => {
    addAccessTokenInterceptor(getAccessTokenSilently);
  }, [getAccessTokenSilently]);

  useEffect(() => {
    getThesisProject();
    getThesisProjectActivity();
  }, [currentUser]);

  useEffect(() => {
    if (thesisProject && activity) {
      const title =
        thesisProject?.topics?.title ||
        activity.find((e: any) => e.type === "thesis-project")?.thesis_project?.title ||
        thesisProject?.title;
      setTopicTitle(title);
    }
  }, [thesisProject, activity]);

  useEffect(() => {
    fetchToken();
  }, [fetchToken]);

  useEffect(() => {
    const pollingId = setInterval(() => {
      getThesisProject(true);
      getThesisProjectActivity(true);
    }, 5000);
    return () => clearInterval(pollingId);
  }, []);

  useEffect(() => {
    if (editingCommentId !== null) {
      const comment = commentRef?.current;
      comment.focus();
      comment.selectionStart = comment.selectionEnd = comment.value.length;
      comment.scrollTop = comment.scrollHeight;
    }
  }, [editingCommentId]);

  const classNames = (...classes: any) => {
    return classes.filter(Boolean).join(" ");
  };

  return (
    <div className="bg-white">
      <nav className="flex" aria-label="Breadcrumb">
        <ol role="list" className="flex items-center space-x-4">
          <li>
            <div className="flex">
              <NavLink
                to={isCompanyRoute ? `/company/profile` : `/user/profile`}
                className="text-sm font-medium text-gray-500 hover:text-gray-700"
              >
                {isCompanyRoute ? t("company.profile") : t("common.myProfile")}
              </NavLink>
            </div>
          </li>
          <li>
            <div className="flex items-center">
              <ChevronRightIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
              <NavLink
                to={isCompanyRoute ? "/company/thesis-projects" : "/user/thesis-projects"}
                className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
              >
                {isCompanyRoute ? t("company.thesisProjects") : t("common.myThesisProjects")}
              </NavLink>
            </div>
          </li>
        </ol>
      </nav>
      {isFetching && (
        <div className="flex justify-center items-center mt-6">
          <Loader />
        </div>
      )}
      {isAuthenticated && !isLoading && !isFetching && (
        <>
          <div className="lg:flex lg:items-start lg:justify-between lg:space-x-6">
            <div className="min-w-0 flex-1">
              <div className="mt-6 flex items-start gap-x-6">
                {thesisProject?.companies?.picture && (
                  <img
                    className="h-14 w-14 sm:h-20 sm:w-20 object-contain"
                    src={`https://resources.thesisnavigator.com/${thesisProject?.companies?.picture}`}
                    alt=""
                  />
                )}
                <div>
                  <>
                    <NavLink
                      to={`/companies/${thesisProject?.companies?.id}`}
                      className="whitespace-nowrap flex items-center gap-x-1 mb-2 hover:opacity-75"
                    >
                      <span className="font-semibold text-xs text-gray-900">{thesisProject?.companies?.name}</span>{" "}
                      <CheckBadgeIcon className="h-5 text-yellow-400" />
                    </NavLink>
                    <h1 className="text-2xl font-bold leading-8 text-gray-900 tracking-tight">
                      {thesisProject?.title}
                    </h1>
                  </>
                  <div className="mt-2 flex items-center text-sm text-gray-400 space-x-4">
                    <CalendarIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                    {!thesisProject.updated && (
                      <>{moment(thesisProject.created).locale(currentLanguage).format("lll")}</>
                    )}
                    {thesisProject.updated && (
                      <>
                        {t("common.updated")} {moment(thesisProject.updated).locale(currentLanguage).format("lll")}
                      </>
                    )}
                    {thesisProject.thesis_project_experts?.length > 0 && (
                      <div className="ml-2 flex -space-x-1.5 overflow-hidden flex-shrink-0">
                        {thesisProject.thesis_project_experts?.map((e: any) =>
                          e?.experts.users?.image ? (
                            <img
                              className="inline-block h-6 w-6 rounded-full border object-cover"
                              src={`https://resources.thesisnavigator.com/${e.experts.users.image}`}
                              alt=""
                            />
                          ) : (
                            <img
                              className="inline-block h-6 w-6 rounded-full border object-cover"
                              src={e.experts.users.picture}
                              alt=""
                            />
                          )
                        )}
                      </div>
                    )}{" "}
                    <span
                      className={classNames(
                        thesisProjectStates[thesisProject.state],
                        "inline-flex items-center rounded-full px-3 py-1.5 text-xs font-medium ml-2 hidden sm:block"
                      )}
                    >
                      {t(`thesisProjects.state.${thesisProject.state}`)}
                    </span>
                  </div>
                  <span
                    className={classNames(
                      thesisProjectStates[thesisProject.state],
                      "items-center rounded-full px-3 py-1.5 text-xs font-medium mt-3 truncate sm:hidden inline-block"
                    )}
                  >
                    {t(`thesisProjects.state.${thesisProject.state}`)}
                  </span>
                </div>
              </div>
            </div>
            {isEditable && eligibleStates?.length > 0 && (
              <div className="flex mt-6 lg:ml-4 space-x-3">
                <span className="sm:block">
                  <button
                    type="button"
                    className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                    onClick={() =>
                      navigate(
                        isCompanyRoute
                          ? `/company/thesis-projects/${thesisProject.id}/edit`
                          : `/user/thesis-projects/${thesisProject.id}/edit`
                      )
                    }
                  >
                    <PencilIcon className="-ml-0.5 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                    {t("common.edit")}
                  </button>
                </span>
                <div className="inline-flex rounded-md shadow-sm divide-x divide-white">
                  <span className="sm:block">
                    <div className="inline-flex items-center bg-gray-900 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900 rounded-l-md">
                      {t(`thesisProjects.state.${thesisProject.state}`)}
                    </div>
                  </span>
                  <Menu as="div" className="relative -ml-px block">
                    <Menu.Button className="inline-flex items-center rounded-r-md bg-gray-900 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900 disabled">
                      <span className="sr-only">Open options</span>
                      <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
                    </Menu.Button>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute right-0 z-10 mt-3 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none divide-y divide-gray-100">
                        <div className="py-1">
                          {eligibleStates?.map((state: any) => (
                            <Menu.Item>
                              {({ active }) => (
                                <button
                                  className={classNames(
                                    active ? "bg-gray-50" : "",
                                    "block w-full px-4 py-1 text-sm leading-6 text-gray-900 text-left"
                                  )}
                                  onClick={() => {
                                    setThesisProjectState(state);
                                    setIsModalOpen(true);
                                  }}
                                >
                                  {t(`thesisProjects.action.state.${state}`)}
                                </button>
                              )}
                            </Menu.Item>
                          ))}
                        </div>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
              </div>
            )}
          </div>

          {(thesisProject.state === ThesisProjectState.Applied ||
            thesisProject.state === ThesisProjectState.Agreed ||
            thesisProject.state === ThesisProjectState.Completed) && (
            <div className="mt-12">
              {/* <ThesisProjectStep state={thesisProject.state} /> */}
              <ThesisProjectStep state={ThesisProjectState.Agreed} />
            </div>
          )}

          <div className="grid grid-cols-1 lg:grid-cols-5 lg:gap-12">
            <div className="col-span-3">
              <h2 className="pt-12 pb-6 font-semibold leading-6 text-gray-900">
                {t("thesisProjects.project.title")}
              </h2>
              <div className="shadow-sm ring-1 ring-gray-900/5 rounded-lg p-6 sm:p-8">
                <div className="text-sm leading-6 text-gray-600 lg:col-span-7 whitespace-pre-line linkify">
                  <TruncateText maxLength={isMobile ? 100 : 600} text={thesisProject.description} />
                </div>
              </div>
              <h2 className="pt-12 pb-6 font-semibold leading-6 text-gray-900">
                {t("thesisProjects.application.title")}
              </h2>
              <div className="shadow-sm ring-1 ring-gray-900/5 rounded-lg p-6 sm:p-8">
                {isExpert && thesisProject.students && (
                  <>
                    <div className="border-b border-gray-200 pb-5 mb-6">
                      <h3 className="text-sm font-semibold leading-6 text-gray-900">Student</h3>
                    </div>
                    <div className="relative sm:flex justify-between gap-x-6">
                      <div className="flex min-w-0 gap-x-4 flex-1">
                        <img
                          className="h-12 w-12 flex-none rounded-full border object-cover"
                          src={getUserImage(thesisProject.students.users)}
                          alt=""
                        />
                        <div className="min-w-0 flex-auto">
                          <p className="text-sm font-semibold leading-6 text-gray-900">
                            <span className="absolute inset-x-0 -top-px bottom-0" />
                            {thesisProject.students.users.first_name} {thesisProject.students.users.last_name}
                          </p>
                          <p className="mt-1 flex text-xs leading-5 text-gray-500">
                            <span className="relative">{thesisProject.students.users.email}</span>
                          </p>
                        </div>
                      </div>
                      <div className="flex shrink-0 items-center gap-x-4 flex-1 sm:text-right mt-6 sm:mt-0">
                        <div className="sm:flex sm:flex-col sm:items-end w-full">
                          <p className="text-sm leading-6 text-gray-900">{thesisProject.students.universities.name}</p>
                          <div className="mt-1 flex items-center text-xs leanding-5 text-gray-500">
                            {thesisProject.students.field_of_study}
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
                {isStudent && thesisProject?.thesis_project_experts?.length > 0 && (
                  <>
                    <div className="border-b border-gray-200 pb-5 mb-6">
                      <h3 className="text-sm font-semibold leading-6 text-gray-900">{t("common.experts")}</h3>
                    </div>
                    <div className="grid grid-cols-1 gap-4 sm:grid-cols-1">
                      {thesisProject?.thesis_project_experts.map((expert: any) => (
                        <div key={expert.id} className="relative flex items-center space-x-3">
                          <div className="flex-shrink-0">
                            <img
                              className="h-10 w-10 rounded-full border object-cover"
                              src={getUserImage(expert.experts.users)}
                              alt=""
                            />
                          </div>
                          <div className="min-w-0 flex-auto">
                            <p className="text-sm font-semibold leading-6 text-gray-900">
                              {expert.experts.users.first_name} {expert.experts.users.last_name}
                            </p>
                            <p className="mt-1 flex text-xs leading-5 text-gray-500">
                              <span className="relative truncate">{expert.experts.title}</span>
                            </p>
                          </div>
                        </div>
                      ))}
                    </div>
                  </>
                )}
                <div className="border-b border-gray-200 pb-5 mt-12 mb-6">
                  <h3 className="text-sm font-semibold leading-6 text-gray-900">{t("thesisProjects.cv.title")}</h3>
                </div>
                <div className="flex items-center">
                  <PaperClipIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                  <button
                    onClick={() => getResourcePresignedUrl(thesisProject.cv_file)}
                    className="font-medium text-sm text-gray-900 hover:text-gray-800 ml-2 text-left underline"
                  >
                    {thesisProject.cv_filename}
                  </button>
                </div>
                <div className="border-b border-gray-200 pb-5 mt-12 mb-6">
                  <h3 className="text-sm font-semibold leading-6 text-gray-900">
                    {t("thesisProjects.motivation.title")}
                  </h3>
                </div>
                <div className="text-sm leading-6 text-gray-600 whitespace-pre-line linkify">
                  <p className="editor" dangerouslySetInnerHTML={{ __html: t(thesisProject.motivation) }} />
                </div>
                <div className="border-b border-gray-200 pb-5 mt-12 mb-6">
                  <h3 className="text-sm font-semibold leading-6 text-gray-900">{t("thesisProjects.notes.title")}</h3>
                </div>
                <div className="text-sm leading-6 text-gray-600 whitespace-pre-line linkify">
                  <p className="editor" dangerouslySetInnerHTML={{ __html: t(thesisProject.notes) }} />
                </div>
              </div>
            </div>
            <div className="col-span-2">
              <h3 className="pt-12 pb-6 text-base font-semibold leading-6 text-gray-900">
                {t("thesisProjects.activity.title")}
              </h3>
              <ul role="list" className="space-y-6">
                <li key={thesisProject.id} className="relative flex gap-x-4">
                  {isEditable && (
                    <div className="-bottom-6 absolute left-0 top-0 flex w-6 justify-center">
                      <div className="w-px bg-gray-200" />
                    </div>
                  )}
                  <>
                    <div className="relative flex h-6 w-6 flex-none items-center justify-center bg-white">
                      <div className="h-1.5 w-1.5 rounded-full bg-gray-100 ring-1 ring-gray-300" />
                    </div>
                    <div className="flex-auto rounded-md px-3">
                      <div className="flex justify-between gap-x-4">
                        <div className="py-0.5 text-xs leading-5 text-gray-500">
                          <span className="font-semibold text-gray-900">
                            {thesisProject?.students?.users?.first_name}
                          </span>{" "}
                          <span
                            dangerouslySetInnerHTML={{
                              __html: t("thesisProjects.activity.applied", {
                                value: topicTitle,
                              }),
                            }}
                          />
                        </div>
                        <time className="flex-none py-0.5 text-xs leading-5 text-gray-500">
                          {moment(thesisProject?.created).locale(currentLanguage).fromNow()}
                        </time>
                      </div>
                    </div>
                  </>
                </li>
                {activity?.map((e: any, index: any) => (
                  <>
                    {e.type === "thesis-project" && e.thesis_project.changes?.length > 0 && (
                      <li key={e.id} className="relative flex gap-x-4">
                        <div className="-bottom-6 absolute left-0 top-0 flex w-6 justify-center">
                          <div className="w-px bg-gray-200" />
                        </div>
                        <>
                          <div className="relative flex h-6 w-6 flex-none items-center justify-center bg-white">
                            {e.thesis_project?.changes?.find((change: any) => change.field === "state")?.new ===
                            ThesisProjectState.Agreed ? (
                              <CheckCircleIcon className="h-7 w-7 text-green-600" aria-hidden="true" />
                            ) : e.thesis_project?.changes?.find((change: any) => change.field === "state")?.new ===
                              ThesisProjectState.Completed ? (
                              <CheckCircleIconSolid className="h-7 w-7 text-green-600" aria-hidden="true" />
                            ) : e.thesis_project?.changes?.find((change: any) => change.field === "state")?.new ===
                              ThesisProjectState.Rejected ? (
                              <XCircleIcon className="h-7 w-7 text-red-500" aria-hidden="true" />
                            ) : e.thesis_project?.changes?.find((change: any) => change.field === "state")?.new ===
                                ThesisProjectState.Withdrawn ||
                              e.thesis_project?.changes?.find((change: any) => change.field === "state")?.new ===
                                ThesisProjectState.Canceled ? (
                              <XCircleIcon className="h-7 w-7 text-gray-400" aria-hidden="true" />
                            ) : (
                              <div className="h-1.5 w-1.5 rounded-full bg-gray-100 ring-1 ring-gray-300" />
                            )}
                          </div>
                          <div className="flex-auto rounded-md px-3">
                            <div className="flex justify-between gap-x-4">
                              <div className="py-0.5 text-xs leading-5 text-gray-500">
                                <span className="font-semibold text-gray-900">
                                  {e.thesis_project?.users?.first_name
                                    ? e.thesis_project?.users?.first_name
                                    : t("common.expert")}
                                </span>{" "}
                                {e.thesis_project?.changes?.every((change: any) => change.field !== "state") && (
                                  <>
                                    {t("thesisProjects.activity.updated.title")}{" "}
                                    {e.thesis_project?.changes
                                      .filter((change: any) => change.field !== "cv_filename")
                                      .map((change: any) => t(`thesisProjects.activity.updated.fields.${change.field}`))
                                      .join(", ")}
                                  </>
                                )}
                                {e.thesis_project?.changes?.some((change: any) => change.field === "state") && (
                                  <>
                                    <span
                                      dangerouslySetInnerHTML={{
                                        __html: t("thesisProjects.activity.updatedState", {
                                          value: t(
                                            `thesisProjects.state.${
                                              e.thesis_project?.changes.find((change: any) => change.field === "state")
                                                ?.new
                                            }`
                                          ),
                                        }),
                                      }}
                                    />
                                  </>
                                )}
                                {e.thesis_project
                                  ?.thesis_project_state_reasons_thesis_project_history_new_state_reason_idTothesis_project_state_reasons && (
                                  <span className="font-semibold text-gray-900">
                                    {" "}
                                    (
                                    {t(
                                      `thesisProjects.updateState.reason.${
                                        e.thesis_project?.changes.find((change: any) => change.field === "state")?.new
                                      }.${
                                        e.thesis_project
                                          ?.thesis_project_state_reasons_thesis_project_history_new_state_reason_idTothesis_project_state_reasons
                                          .reason
                                      }`
                                    )}
                                    )
                                  </span>
                                )}
                              </div>
                              <time className="flex-none py-0.5 text-xs leading-5 text-gray-500">
                                {moment(e.date).locale(currentLanguage).fromNow()}
                              </time>
                            </div>
                            {e.thesis_project?.changes?.some(
                              (change: any) => change.field === "state_reason_notes"
                            ) && (
                              <p className="text-xs leading-5 text-gray-600 whitespace-pre-line py-2 linkify">
                                <Linkify options={{ target: "blank" }}>
                                  {
                                    e.thesis_project?.changes.find(
                                      (change: any) => change.field === "state_reason_notes"
                                    )?.new
                                  }
                                </Linkify>
                              </p>
                            )}
                          </div>
                        </>
                      </li>
                    )}
                    {e.type === "comment" && (
                      <li key={e.id} className="relative flex gap-x-4">
                        <div
                          className={classNames(
                            index === activity.length - 1 ? "h-6" : "-bottom-6",
                            "absolute left-0 top-0 flex w-6 justify-center"
                          )}
                        >
                          <div className="w-px bg-gray-200" />
                        </div>
                        <>
                          {e.comment.experts?.users || e.comment.students?.users ? (
                            <img
                              src={
                                e.comment.experts?.users
                                  ? getUserImage(e.comment.experts?.users)
                                  : getUserImage(e.comment.students?.users)
                              }
                              alt=""
                              className="relative mt-3 h-6 w-6 flex-none rounded-full border object-cover"
                            />
                          ) : (
                            <div className="relative mt-3 h-6 w-6 flex-none" aria-hidden="true" />
                          )}
                          <div className="flex-auto rounded-md p-3 ring-1 ring-inset ring-gray-200">
                            <div className="flex justify-between gap-x-4">
                              <div className="py-0.5 text-xs leading-5 text-gray-500">
                                <span className="font-semibold text-gray-900">
                                  {e.comment.experts?.users?.first_name ||
                                    e.comment.students?.users?.first_name ||
                                    t("common.expert")}
                                </span>{" "}
                                {t("thesisProjects.activity.commented")}
                              </div>
                              <time className="flex-none py-0.5 text-xs leading-5 text-gray-500">
                                {moment(e.comment.created).locale(currentLanguage).fromNow()}
                              </time>
                            </div>
                            {editingCommentId !== e.comment.id && (
                              <p className="text-xs leading-5 text-gray-600 whitespace-pre-line py-2 linkify">
                                <Linkify options={{ target: "blank" }}>{e.comment.description}</Linkify>
                              </p>
                            )}
                            {editingCommentId === e.comment.id && (
                              <>
                                <textarea
                                  ref={commentRef}
                                  rows={4}
                                  id={`comment-${e.comment.id}`}
                                  className="block w-full resize-none border-0 bg-transparent text-gray-900 placeholder:text-gray-400 focus:ring-0 text-xs leading-5 px-0 py-2.5"
                                  placeholder={t("thesisProjects.activity.comment.placeholder")}
                                  defaultValue={e.comment.description}
                                  onChange={(e: any) => setEditedComment(e.target.value)}
                                />
                                <div className="flex justify-end">
                                  {isUpdating && <Loader />}
                                  {!isUpdating && (
                                    <>
                                      <button
                                        type="button"
                                        className="px-2.5 py-1.5 text-xs font-semibold text-gray-900"
                                        onClick={() => setEditingCommentId(null)}
                                      >
                                        {t("common.cancel")}
                                      </button>
                                      <button
                                        type="button"
                                        className="rounded-md bg-white ml-2.5 px-2.5 py-1.5 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:opacity-25"
                                        onClick={onEditedCommentSubmit}
                                        disabled={!editedComment || editedComment === e.comment.description}
                                      >
                                        {t("common.save")}
                                      </button>
                                    </>
                                  )}
                                </div>
                              </>
                            )}
                          </div>
                          {(currentUser.id === e.comment.students?.user_id ||
                            currentUser.id === e.comment.experts?.user_id) && (
                            <Menu as="div" className="relative flex-none items-center mx-auto my-auto">
                              <Menu.Button className="-m-5 block p-2.5 text-gray-500 hover:text-gray-900">
                                <span className="sr-only">Open options</span>
                                <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
                              </Menu.Button>
                              <Transition
                                as={Fragment}
                                enter="transition ease-out duration-100"
                                enterFrom="transform opacity-0 scale-95"
                                enterTo="transform opacity-100 scale-100"
                                leave="transition ease-in duration-75"
                                leaveFrom="transform opacity-100 scale-100"
                                leaveTo="transform opacity-0 scale-95"
                              >
                                <Menu.Items className="absolute right-0 z-10 mt-2 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                                  <Menu.Item>
                                    {({ active }) => (
                                      <button
                                        className={classNames(
                                          active ? "bg-gray-50" : "",
                                          "block w-full px-3 py-1 text-sm leading-6 text-gray-900 text-left"
                                        )}
                                        onClick={() => {
                                          setEditingCommentId(e.comment.id);
                                          setEditedComment(e.comment.description);
                                        }}
                                      >
                                        {t("common.edit")}
                                      </button>
                                    )}
                                  </Menu.Item>
                                  <Menu.Item>
                                    {({ active }) => (
                                      <button
                                        className={classNames(
                                          active ? "bg-gray-50" : "",
                                          "block w-full px-3 py-1 text-sm leading-6 text-red-600 text-left"
                                        )}
                                        onClick={() => deleteThesisProjectComment(e.comment.id)}
                                      >
                                        {t("common.delete")}
                                      </button>
                                    )}
                                  </Menu.Item>
                                </Menu.Items>
                              </Transition>
                            </Menu>
                          )}
                        </>
                      </li>
                    )}
                  </>
                ))}
              </ul>
              {isEditable && (
                <div className="py-6 flex gap-x-4">
                  <img
                    src={
                      currentUser.image ? `https://resources.thesisnavigator.com/${currentUser.image}` : user?.picture
                    }
                    alt=""
                    className="mt-1 h-6 w-6 flex-none rounded-full border object-cover"
                  />
                  <form action="#" className="relative flex-auto">
                    <div className="overflow-hidden rounded-lg pb-12 shadow-sm ring-1 ring-inset ring-gray-200 focus-within:ring-gray-300">
                      <label htmlFor="comment" className="sr-only">
                        Add your comment
                      </label>
                      <textarea
                        rows={4}
                        id="comment"
                        className="block w-full resize-none border-0 bg-transparent py-2.5 text-gray-900 placeholder:text-gray-400 focus:ring-0 text-xs leading-5"
                        placeholder={t("thesisProjects.activity.comment.placeholder")}
                        defaultValue={""}
                        {...register("comment.description")}
                      />
                    </div>

                    <div className="absolute inset-x-0 bottom-0 flex justify-between py-2 pl-3 pr-2">
                      <div className="flex items-center ml-auto space-x-5">
                        {isUpdating && <Loader />}
                        {!isUpdating && (
                          <>
                            <button
                              type="submit"
                              className="rounded-md bg-white px-2.5 py-1.5 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:opacity-25"
                              onClick={handleSubmit(onCommentSubmit)}
                              disabled={!isValid}
                            >
                              {t("common.send")}
                            </button>
                          </>
                        )}
                      </div>
                    </div>
                  </form>
                </div>
              )}
            </div>
          </div>
        </>
      )}
      <Modal isOpen={isModalOpen} onClose={closeModal}>
        <ThesisProjectStateForm
          title={thesisProject.title}
          state={thesisProjectState}
          onCancel={onCancel}
          onSave={onSave}
        />
      </Modal>
    </div>
  );
}
