import { Fragment, useContext, useEffect, useState } from "react";
import { Dialog, Menu, MenuButton, MenuItem, MenuItems, Transition } from "@headlessui/react";
import {
  Bars3Icon,
  BellIcon,
  XMarkIcon,
  LanguageIcon,
  ChevronDownIcon as ChevronDownIcon24,
  ArrowLeftEndOnRectangleIcon,
} from "@heroicons/react/24/solid";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { NavLink, Outlet } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import Loader from "./Loader";
import { CurrentUserContext } from "../contexts/userContext";
import { useTranslation } from "react-i18next";
import logo from "./../static/images/logo.svg";

const classNames = (...classes: any) => {
  return classes.filter(Boolean).join(" ");
};

export default function SidebarLayout({
  navbar,
  sidebarOpen,
  setSidebarOpen,
  children,
}: React.PropsWithChildren<{ navbar: React.ReactNode; sidebarOpen: boolean; setSidebarOpen: any; }>) {
  const { user, isAuthenticated, loginWithRedirect, logout, isLoading } = useAuth0();
  const { currentUser } = useContext(CurrentUserContext);
  const { t, i18n } = useTranslation();
  const [language, setLanguage] = useState(i18n.language);
  const currentLanguage = i18n.language;

  useEffect(() => {
    setLanguage(currentLanguage);
  }, [currentLanguage]);

  const logoutWithRedirect = () =>
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });

  const handleLanguageChange = async (language: string) => {
    await i18n.changeLanguage(language);
    setLanguage(language);
    document.documentElement.lang = language;
  };

  return (
    <div>
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50 lg:hidden" onClose={setSidebarOpen}>
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-900/80" />
          </Transition.Child>

          <div className="fixed inset-0 flex w-72">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative flex flex-1">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                    <button type="button" className="-m-2.5 p-2.5 outline-none" onClick={() => setSidebarOpen(false)}>
                      <span className="sr-only">Close sidebar</span>
                      <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                    </button>
                  </div>
                </Transition.Child>
                {/* Sidebar component, swap this element with another sidebar if you like */}
                <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-white px-6 pb-4">
                  <Menu as="div" className="-mx-2 rounded-md mt-2">
                    <MenuButton className="flex items-center justify-between p-1.5 rounded-md-mx-2 group flex rounded-md p-2 text-sm leading-6 font-semibold text-sm leading-6 w-full">
                      <img
                        className="h-8"
                        src={logo}
                        alt="Thesis Navigator"
                      />
                      <ChevronDownIcon24 className="ml-2 h-5 w-5 text-gray-400" aria-hidden="true" />
                    </MenuButton>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <MenuItems className="absolute left-4 mt-2 z-20 w-72 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none divide-y divide-gray-100">
                        <MenuItem>
                          <NavLink to="/" onClick={() => setSidebarOpen(false)}>
                            <span
                              className="block px-4 py-2 text-sm text-gray-900 hover:bg-gray-50">
                              {t("common.dashboard")}
                            </span>
                          </NavLink>
                        </MenuItem>
                        <MenuItem>
                          <a href="https://thesisnavigator.com" target="_blank" className="block px-4 py-2 text-sm text-gray-900 hover:bg-gray-50">
                            {t("Thesis Navigator")}
                          </a>
                        </MenuItem>
                      </MenuItems>
                    </Transition>
                  </Menu>
                  {navbar}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
        <div className="flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-100 px-6 pb-4 bg-white">
          <Menu as="div" className="-mx-2 rounded-md mt-2">
            <MenuButton className="flex items-center justify-between p-1.5 hover:text-gray-600 hover:bg-gray-50 rounded-md-mx-2 text-gray-900 hover:text-gray-600 hover:bg-gray-50 group flex  rounded-md p-2 text-sm leading-6 font-semibold text-sm leading-6 w-full">
              <img
                className="h-8"
                src={logo}
                alt="Thesis Navigator"
              />
              <ChevronDownIcon24 className="ml-2 h-5 w-5 text-gray-400" aria-hidden="true" />
            </MenuButton>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <MenuItems className="absolute left-4 mt-2 z-20 w-72 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none divide-y divide-gray-100">
                <MenuItem>
                  <NavLink to="/">
                    <span
                      className="block px-4 py-2 text-sm text-gray-900 hover:bg-gray-50">
                      {t("common.dashboard")}
                    </span>
                  </NavLink>
                </MenuItem>
                <MenuItem>
                  <a href="https://thesisnavigator.com" target="_blank" className="block px-4 py-2 text-sm text-gray-900 hover:bg-gray-50">
                    {t("Thesis Navigator")}
                  </a>
                </MenuItem>
              </MenuItems>
            </Transition>
          </Menu>
          {navbar}
        </div>
      </div>

      <div className="lg:pl-72">
        <div className="mx-auto flex w-full items-center justify-between gap-x-6 px-6 py-4 lg:px-8 border-b border-gray-100 sticky top-0 bg-white z-20">
          <Menu as="div" className="rounded-md lg:hidden">
            <MenuButton className="flex items-center justify-between rounded-md text-sm leading-6 font-semibold text-sm leading-6">
              <img
                className="h-8"
                src={logo}
                alt="Thesis Navigator"
              />
              <ChevronDownIcon24 className="ml-2 h-5 w-5 text-gray-400" aria-hidden="true" />
            </MenuButton>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <MenuItems className="absolute left-6 mt-2 z-20 w-72 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none divide-y divide-gray-100">
                <MenuItem>
                  <NavLink to="/">
                    <span
                      className="block px-4 py-2 text-sm text-gray-900 hover:bg-gray-50">
                      {t("common.dashboard")}
                    </span>
                  </NavLink>
                </MenuItem>
                <MenuItem>
                  <a href="https://thesisnavigator.com" target="_blank" className="block px-4 py-2 text-sm text-gray-900 hover:bg-gray-50">
                    {t("Thesis Navigator")}
                  </a>
                </MenuItem>
              </MenuItems>
            </Transition>
          </Menu>
          <div className="flex flex-1 items-center justify-end gap-x-6">
            <Menu as="div" className="relative">
              <MenuButton className="flex items-center">
                <span className="sm:flex sm:items-center">
                  <span
                    className="hidden sm:block ml-4 text-sm font-semibold leading-6 text-gray-900"
                    aria-hidden="true"
                  >
                    {t(`languages.${language}`)}
                  </span>
                  <ChevronDownIcon className="hidden sm:block ml-2 h-5 w-5 text-gray-400" aria-hidden="true" />
                  <LanguageIcon className="sm:hidden ml-2 h-6 w-6" aria-hidden="true" />
                </span>
              </MenuButton>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <MenuItems className="absolute right-0 z-20 mt-2 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none divide-y divide-gray-100">
                  <MenuItem>
                    {({ active }) => (
                      <button
                        onClick={() => handleLanguageChange("en")}
                        className={classNames(
                          active ? "bg-gray-50" : "",
                          "block px-4 py-2 text-sm text-gray-900 w-full text-left"
                        )}
                      >
                        {t("languages.en")}
                      </button>
                    )}
                  </MenuItem>
                  <MenuItem>
                    {({ active }) => (
                      <button
                        onClick={() => handleLanguageChange("de")}
                        className={classNames(
                          active ? "bg-gray-50" : "",
                          "block px-4 py-2 text-sm text-gray-900 w-full text-left"
                        )}
                      >
                        {t("languages.de")}
                      </button>
                    )}
                  </MenuItem>
                </MenuItems>
              </Transition>
            </Menu>
            <div className="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-50" aria-hidden="true" />
            {isLoading && <Loader />}
            {!isAuthenticated && !isLoading && (
              <button
                onClick={() =>
                  loginWithRedirect({
                    authorizationParams: { ui_locales: language },
                  })
                }
                className="hidden sm:block text-sm font-semibold leading-6 text-gray-900"
              >
                {t("common.login")}
              </button>
            )}
            {!isAuthenticated && !isLoading && (
              <button
                onClick={() =>
                  loginWithRedirect({
                    authorizationParams: { ui_locales: language, screen_hint: "signup" },
                  })
                }
                className="rounded-md bg-gray-900 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
              >
                {t("common.sign_up")}
              </button>
            )}
            {isAuthenticated && !isLoading && user && (
              <>
                <button type="button" className="hidden sm:block -m-2.5 p-2.5 text-gray-400 hover:text-gray-500">
                  <span className="sr-only">View notifications</span>
                  <BellIcon className="h-6 w-6" aria-hidden="true" />
                </button>

                <div className="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-50" aria-hidden="true" />
                <Menu as="div" className="relative">
                  <MenuButton className="-m-1.5 flex items-center">
                    <span className="sr-only">Open user menu</span>
                    {(currentUser?.image || user?.picture) &&
                      (currentUser?.image ? (
                        <img
                          className="w-8 h-8 rounded-full border object-cover"
                          src={`https://resources.thesisnavigator.com/${currentUser.image}`}
                          alt=""
                        />
                      ) : (
                        <img className="w-8 h-8 rounded-full" src={user?.picture} alt="" />
                      ))}
                    <span className="hidden lg:flex lg:items-center">
                      {/* <span className="ml-4 text-sm font-semibold leading-6 text-gray-900" aria-hidden="true">
                      {username}
                    </span> */}
                      <ChevronDownIcon className="ml-2 h-5 w-5 text-gray-400" aria-hidden="true" />
                    </span>
                  </MenuButton>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <MenuItems className="absolute right-0 z-20 mt-2 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none divide-y divide-gray-100 w-40">
                      <MenuItem>
                        <NavLink to="/">
                          <span
                            className="block px-4 py-2 text-sm text-gray-900 hover:bg-gray-50">
                            {t("common.dashboard")}
                          </span>
                        </NavLink>
                      </MenuItem>
                      <MenuItem>
                        <NavLink to="/user/profile">
                          <span
                            className="block px-4 py-2 text-sm text-gray-900 hover:bg-gray-50">
                            {t("common.myProfile")}
                          </span>
                        </NavLink>
                      </MenuItem>
                      <MenuItem>
                        <NavLink to="feedback">
                          <span
                            className="block px-4 py-2 text-sm text-gray-900 hover:bg-gray-50">
                            {t("common.feedback")}
                          </span>
                        </NavLink>
                      </MenuItem>
                      <MenuItem>
                        <button
                          onClick={() => logoutWithRedirect()}
                          className="group flex items-center gap-x-3 px-4 py-2 text-sm leading-6 text-gray-900 hover:bg-gray-50 hover:text-gray-600 w-full"
                        >
                          <ArrowLeftEndOnRectangleIcon
                            className="h-5 shrink-0 text-gray-400 group-hover:text-gray-600"
                            aria-hidden="true"
                          />
                          {t("common.sign_out")}
                        </button>
                      </MenuItem>
                    </MenuItems>
                  </Transition>
                </Menu>
              </>
            )}
          </div>
          <div className="flex lg:hidden">
            <button
              type="button"
              className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-900 outline-none"
              onClick={() => setSidebarOpen(true)}
            >
              <span className="sr-only">Open main menu</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
        </div>

        <main className="pt-10 pb-20 mx-auto max-w-screen-2xl">
          <div className="px-6 sm:px-6 lg:px-8">
            <Outlet />
          </div>
        </main>
      </div>
    </div>
  )
}
