import { useContext } from "react";
import { CurrentUserContext } from "../../contexts/userContext";
import { UserType } from "../../models";
import { useAuth0 } from "@auth0/auth0-react";
import StudentOnboarding from "../student/StudentOnboarding";
import ExpertOnboarding from "../expert/ExpertOnboarding";
import SupervisorOnboarding from "../supervisor/SupervisorOnboarding";

export default function Dashboard() {
  const { isAuthenticated, isLoading } = useAuth0();
  const { currentUser } = useContext(CurrentUserContext);
  return (
    <>
      {isAuthenticated && !isLoading && currentUser && currentUser.type === UserType.Student && <StudentOnboarding />}
      {isAuthenticated && !isLoading && currentUser && currentUser.type === UserType.Expert && <ExpertOnboarding />}
      {isAuthenticated && !isLoading && currentUser && currentUser.type === UserType.Supervisor && <SupervisorOnboarding />}
    </>
  );
}
