import { object, string } from "yup";
import { useAuth0 } from "@auth0/auth0-react";
import { Fragment, useContext, useEffect, useState } from "react";
import { CurrentUserContext } from "../../contexts/userContext";
import Loader from "../../components/Loader";
import httpsClient, { addAccessTokenInterceptor } from "../../config/https";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import { useParams } from "react-router-dom";
import { CheckIcon, ChevronUpDownIcon, FolderIcon } from "@heroicons/react/24/solid";
import { Listbox, Transition } from "@headlessui/react";
import { thesisProjectStates } from "../../models";
import { MessageType, useMessagesContext } from "../../contexts/messagesContext";

export const ThesisProjectStateForm: React.FC<any> = ({ title, state, onCancel, onSave }) => {
  const { id } = useParams();
  const { currentUser } = useContext(CurrentUserContext);
  const { getAccessTokenSilently, isAuthenticated, isLoading } = useAuth0();
  const [isFetching, setIsFetching] = useState(id ? true : false);
  const [stateReasons, setStateReasons] = useState<any>([]);
  const [stateReason, setStateReason] = useState();
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const [isUpdating, setIsUpdating] = useState(false);
  const { addMessage } = useMessagesContext();

  const validationSchema = object().shape({
    state_reason_id: string().required(t("common.required")),
  });

  const {
    control,
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: stateReasons.length > 0 ? yupResolver(validationSchema) : yupResolver(object().shape({})),
    defaultValues: {
      state_reason_id: null,
      state_reason_notes: null,
    },
  });

  const getThesisProjectStateReasons = async () => {
    try {
      setIsFetching(true);
      const response = await httpsClient.get(`/api/v1/thesis-projects/state/reasons?state=${state}`);
      setStateReasons(response.data);
      setIsFetching(false);
    } catch {
      setIsFetching(false);
    }
  };

  const updateThesisProjectState = async (data: any) => {
    try {
      setIsUpdating(true);
      const response = await httpsClient.patch(`/api/v1/thesis-projects/${id}`, { ...data, state });
      onSave(response.data);
      setIsUpdating(false);
    } catch {
      setIsUpdating(false);
      addMessage({
        type: MessageType.Error,
        title: "common.error.title",
        description: "common.error.description",
      });
    }
  };

  const onSubmit = (data: any) => {
    updateThesisProjectState(data);
  };

  useEffect(() => {
    addAccessTokenInterceptor(getAccessTokenSilently);
  }, [getAccessTokenSilently]);

  useEffect(() => {
    if (id) {
      getThesisProjectStateReasons();
    }
  }, [id]);

  const classNames = (...classes: any) => {
    return classes.filter(Boolean).join(" ");
  };

  return (
    <div className="bg-white">
      {isFetching && (
        <div className="flex justify-center items-center mt-6">
          <Loader />
        </div>
      )}
      {isAuthenticated && !isLoading && !isFetching && (
        <form autoComplete="off">
          <div className="pb-6 border-b border-gray-900/10">
            <h2 className="text-xl font-bold leading-7 text-gray-900 tracking-tight mt-2">{title}</h2>
            <p className="mt-2 flex text-sm text-gray-500 gap-x-2">
              <span
                className={classNames(
                  thesisProjectStates[state],
                  "inline-flex items-center rounded-full px-3 py-1.5 text-xs font-medium"
                )}
              >
                {t(`thesisProjects.state.${state}`)}
              </span>
            </p>
            <div className="mt-6 grid grid-cols-1 gap-x-6 gap-y-6 sm:grid-cols-6">
              {stateReasons?.length > 0 && (
                <div className="sm:col-span-full">
                  <label htmlFor="comment" className="block text-sm font-medium leading-6 text-gray-900">
                    {t("thesisProjects.updateState.reason.title")}
                  </label>
                  <div className="mt-2">
                    <Controller
                      control={control}
                      name="state_reason_id"
                      render={({ field: { onChange } }) => (
                        <Listbox
                          value={stateReason}
                          onChange={(e) => {
                            onChange(e);
                          }}
                        >
                          {({ open }) => (
                            <>
                              <div className="relative mt-2">
                                <Listbox.Button className="truncate relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-900 text-sm sm:leading-6 cursor-pointer disabled:opacity-75 disabled:bg-gray-100">
                                  <span className="block truncate">
                                    {stateReason ? (
                                      <>
                                        {t(
                                          `thesisProjects.updateState.reason.${state}.${
                                            stateReasons?.filter((e: any) => e.id === stateReason)[0]?.reason
                                          }`
                                        )}
                                      </>
                                    ) : (
                                      t("thesisProjects.updateState.reason.placeholder")
                                    )}
                                  </span>
                                  <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                    <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                  </span>
                                </Listbox.Button>
                                <Transition
                                  show={open}
                                  as={Fragment}
                                  leave="transition ease-in duration-100"
                                  leaveFrom="opacity-100"
                                  leaveTo="opacity-0"
                                >
                                  <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none text-sm">
                                    {stateReasons.map((e: any) => (
                                      <Listbox.Option
                                        key={e.id}
                                        className={({ active }) =>
                                          classNames(
                                            active ? "bg-gray-900 text-white" : "text-gray-900",
                                            "relative cursor-default select-none py-2 pl-3 pr-9 cursor-pointer"
                                          )
                                        }
                                        value={e.id}
                                        onClick={() => setStateReason(e.id)}
                                      >
                                        {({ stateReason, active }: any) => (
                                          <>
                                            <span
                                              className={classNames(
                                                stateReason ? "font-semibold" : "font-normal",
                                                "block truncate"
                                              )}
                                            >
                                              {t(`thesisProjects.updateState.reason.${state}.${e.reason}`)}
                                            </span>
                                            {stateReason ? (
                                              <span
                                                className={classNames(
                                                  active ? "text-white" : "text-indigo-900",
                                                  "absolute inset-y-0 right-0 flex items-center pr-4"
                                                )}
                                              >
                                                <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                              </span>
                                            ) : null}
                                          </>
                                        )}
                                      </Listbox.Option>
                                    ))}
                                  </Listbox.Options>
                                </Transition>
                              </div>
                            </>
                          )}
                        </Listbox>
                      )}
                    />
                    {errors.state_reason_id && (
                      <p className="mt-2 text-xs text-red-600">{errors.state_reason_id?.message?.toString()}</p>
                    )}
                  </div>
                </div>
              )}

              <div className="sm:col-span-full">
                <label htmlFor="comment" className="block text-sm font-medium leading-6 text-gray-900">
                  {t("thesisProjects.updateState.notes.title")}
                </label>
                <div className="mt-2">
                  <textarea
                    rows={5}
                    placeholder={t("thesisProjects.updateState.notes.placeholder")}
                    className="block w-full rounded-md border-0 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-900 text-sm sm:leading-6 whitespace-pre-line"
                    defaultValue={""}
                    {...register("state_reason_notes")}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="mt-6 flex items-center justify-end gap-x-6">
            {isUpdating && <Loader />}
            {!isUpdating && (
              <>
                <button type="button" onClick={onCancel} className="text-sm font-semibold leading-6 text-gray-900">
                  {t("common.cancel")}
                </button>
                <button
                  type="submit"
                  onClick={handleSubmit(onSubmit)}
                  className="rounded-md bg-gray-900 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
                >
                  {t("common.save")}
                </button>
              </>
            )}
          </div>
        </form>
      )}
    </div>
  );
};
