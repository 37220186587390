import React, { useEffect } from "react";
import ReactModal from "react-modal";
import Modal from "../Modal";
import { useTranslation } from "react-i18next";
import { XMarkIcon } from "@heroicons/react/24/outline";

interface IProps extends ReactModal.Props {
  url: string;
  closeModal: () => void;
  onChangeUrl: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onSaveLink: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onRemoveLink: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

export function LinkModal(props: IProps) {
  const { url, isOpen, closeModal, onChangeUrl, onSaveLink, onRemoveLink } = props;
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const input = document.getElementById("your-input-id");
    if (input) {
      input.focus();
    }
  }, []);

  return (
    <Modal isOpen={isOpen} onClose={closeModal}>
      <h3 className="text-xl text-center font-bold leading-6 text-gray-900 mb-6">{t("common.edit")}</h3>{" "}
      <div className="absolute right-0 top-0 p-6 sm:block">
        <button
          type="button"
          className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none"
          onClick={closeModal}
        >
          <span className="sr-only">Close</span>
          <XMarkIcon className="h-6 w-6" aria-hidden="true" />
        </button>
      </div>
      <input
        type="text"
        autoFocus
        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-900 text-sm sm:leading-6"
        value={url}
        onChange={onChangeUrl}
        placeholder={"https://"}
      />
      <div className="mt-6 flex items-center justify-end gap-x-6">
        <button type="button" onClick={onRemoveLink} className="text-sm font-semibold leading-6 text-gray-900">
          {t("common.remove")}
        </button>
        <button
          type="submit"
          onClick={onSaveLink}
          className="rounded-md bg-gray-900 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
        >
          {t("common.save")}
        </button>
      </div>
    </Modal>
  );
}
