import { useAuth0 } from "@auth0/auth0-react";
import DashboardNewFeatures from "./DashboardNewFeatures";
import { NavLink } from "react-router-dom";
import i18n from "../../i18n";
import { useTranslation } from "react-i18next";
import { useContext, useEffect, useState } from "react";
import httpsClient from "../../config/https";
import { CheckCircleIcon as CheckCircleIconSolid, BookmarkIcon, CheckBadgeIcon, AcademicCapIcon } from "@heroicons/react/24/solid";
import Loader from "../../components/Loader";
import NoSavedItems from "../../components/NoSavedItems";
import uzh from "../../static/images/uzh.svg";
import ethz from "../../static/images/ethz.svg";
import hsg from "../../static/images/hsg.png";
import moment from "moment";
import "moment/locale/de";
import thesisnavigator_en from "../../static/images/thesisnavigator-en.png";
import thesisnavigator_de from "../../static/images/thesisnavigator-de.png";
import { CurrentUserContext } from "src/contexts/userContext";

export default function StudentDashboard(props: any) {
  const { loginWithRedirect, isAuthenticated, isLoading } = useAuth0();
  const [courses, setCourses] = useState([]);
  const [courseMetrics, setCourseMetrics] = useState([]);
  const [durations, setDurations] = useState([]);
  const [isFetchingCourses, setIsFetchingCourses] = useState(true);
  const [topics, setTopics] = useState([]);
  const [isFetchingTopics, setIsFetchingTopics] = useState(true);
  const { t } = useTranslation();
  const currentLanguage = i18n.language;
  const { currentUser } = useContext(CurrentUserContext);

  const getCourses = async () => {
    setIsFetchingCourses(true);
    await httpsClient
      .get(`/api/v1/courses?lang=${currentLanguage}&bookmarked=true`)
      .then((response) => {
        setCourses(response.data);
        setIsFetchingCourses(false);
      })
      .catch(() => {
        setIsFetchingCourses(false);
      });
  };

  const getCourseMetrics = async () => {
    await httpsClient
      .get(`/api/v1/courses/metrics`)
      .then((response) => {
        setCourseMetrics(response.data);
      })
      .catch(() => { });
  };

  const getCourseMetric = (course: any): any => {
    return courseMetrics.find((e: any) => e.course_id === course.id);
  };

  const getCourseDuration = (resource: string) => {
    const e: any = durations?.filter((e: any) => e.resource === resource);
    if (e[0]) {
      const absoluteMinutes = Math.floor(e[0]?.duration / 60);
      const absoluteSeconds = e[0]?.duration % 60;
      return `${String(absoluteMinutes).padStart(2, "0")}:${String(absoluteSeconds).padStart(2, "0")}`;
    }
    return "";
  };

  const getCourseDurationInSeconds = (resource: string) => {
    const e: any = durations?.filter((e: any) => e.resource === resource);
    if (e[0]) {
      return e[0]?.duration;
    }
    return 0;
  };

  const getTopics = async () => {
    setIsFetchingTopics(true);
    await httpsClient
      .get(`/api/v1/topics?bookmarked=true`)
      .then((response) => {
        setTopics(response.data.topics);
        setIsFetchingTopics(false);
      })
      .catch(() => {
        setIsFetchingTopics(false);
      });
  };

  useEffect(() => {
    const fetchResources = async () => {
      const extractedDurations: any = await Promise.all(
        courses
          .map((e: any) => e.resource)
          .filter(Boolean)
          .map(async (resource) => {
            if (resource) {
              const response = await httpsClient.get(
                `${process.env.REACT_APP_RESOURCES_URL}/${resource.replace(/\.(?=[^.]+$)/, "hls.")}`
              );
              const lines = response?.data?.split("\n");
              let duration = 0;
              lines.forEach((line: any) => {
                if (line.startsWith("#EXTINF:")) {
                  const segmentDuration = parseFloat(line.split(":")[1].split(",")[0]);
                  duration += segmentDuration;
                }
              });
              return {
                resource,
                duration,
              };
            }
          })
      );
      setDurations(extractedDurations);
    };

    if (courses?.length > 0) {
      fetchResources();
    }
  }, [courses]);

  useEffect(() => {
    getCourses();
    getCourseMetrics();
    getTopics();
  }, [currentLanguage]);

  return (
    <div className="bg-white">
      {!isAuthenticated && !isLoading && (
        <div className="pb-12 mx-auto max-w-4xl">
          <div className="grid grid-cols-1 items-start gap-y-16 lg:grid-cols-2 gap-x-12">
            <div>
              <h1 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">
                {t("dashboard.students.welcome.title")}
              </h1>
              <p
                className="mt-6 leading-6 text-gray-500"
                dangerouslySetInnerHTML={{ __html: t("dashboard.students.welcome.description") }}
              />
              <div className="mt-10 flex items-center">
                <button
                  onClick={() =>
                    loginWithRedirect({
                      authorizationParams: { ui_locales: currentLanguage, screen_hint: "signup" },
                    })
                  }
                  className="btn-primary"
                >
                  {t("common.sign_up")}
                </button>
              </div>
            </div>
            <div className="flex justify-end items-start">
              <img
                src={currentLanguage === "en" ? thesisnavigator_en : thesisnavigator_de}
                alt="ThesisNavigator"
                className="lg:max-h-80 border border-gray-200 rounded-lg"
              />
            </div>
          </div>
        </div>
      )}
      {isAuthenticated && !isLoading && (
        <div className="text-center pb-12 mx-auto max-w-6xl">
          <h2 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">
            {t("dashboard.students.welcome.title")}
          </h2>
          <p
            className="mx-auto mt-6 leading-6 text-gray-500"
            dangerouslySetInnerHTML={{ __html: t("dashboard.students.welcome.description") }}
          />
        </div>
      )}
      <div className="text-center py-12">
        <div className="mx-auto">
          <div className="grid grid-cols-1 lg:grid-cols-2 lg:items-start justify-start lg:gap-12">
            <div className="pb-12 lg:pr-6 lg:pb-0">
              <h2 className="text-xl font-bold tracking-tight text-gray-900 sm:text-2xl">
                {t("dashboard.students.topics.title")}
              </h2>
              <p className="mx-auto mt-6 leading-6 text-gray-500 text-sm">
                {t("dashboard.students.topics.description")}
              </p>
              {isAuthenticated && !isLoading && isFetchingTopics && (
                <div className="my-10 flex justify-center items-center">
                  <Loader />
                </div>
              )}
              {isAuthenticated && !isLoading && !isFetchingTopics && (
                <>
                  <div className="flex justify-between mt-10 mb-2">
                    <div className="flex items-center font-bold">
                      <BookmarkIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-yellow-400" aria-hidden="true" />
                      {t("dashboard.students.topics.favorites.title")}
                    </div>
                    {topics.length > 1 && (
                      <NavLink
                        to="/topics?bookmarked=true"
                        className="text-sm underline font-semibold text-gray-900 hover:text-gray-800 sm:block"
                      >
                        {t("dashboard.students.topics.favorites.viewAll")}
                      </NavLink>
                    )}
                  </div>
                  {topics.length === 0 && <NoSavedItems />}
                  <ul role="list" className="divide-y divide-gray-100">
                    {topics.slice(0, 3).map((topic: any) => (
                      <li key={topic.id}>
                        <NavLink
                          to={`/topics?id=${topic.id}&bookmarked=true`}
                          className="flex items-center justify-between text-left gap-x-6 py-5 group"
                        >
                          <div className="flex min-w-0 gap-x-4">
                            {topic.company && topic.company?.picture && (
                              <div className="flex-shrink-0 ">
                                {topic.company?.picture && (
                                  <img
                                    className="h-12 w-12 object-contain"
                                    src={`https://resources.thesisnavigator.com/${topic.company?.picture}`}
                                    alt=""
                                  />
                                )}
                              </div>
                            )}
                            {topic.university && (
                              <div className="flex-shrink-0 ">
                                <img
                                  className="h-12 w-12 object-contain"
                                  src={
                                    topic.university === "Universität St. Gallen (HSG)"
                                      ? hsg
                                      : topic.university === "Universität Zürich"
                                        ? uzh
                                        : topic.university === "Eidgenössische Technische Hochschule Zürich (ETHZ)"
                                          ? ethz
                                          : ""
                                  }
                                  alt=""
                                />
                              </div>
                            )}
                            {topic.university && (
                              <div className="w-full">
                                <div className="flex items-start gap-x-4 text-xs">
                                  <div className="flex flex-wrap items-center gap-x-2 text-xs leading-6 text-gray-500">
                                    <p className="whitespace-nowrap font-semibold text-gray-900">{topic.university}</p>
                                    <svg viewBox="0 0 2 2" className="h-0.5 w-0.5 fill-gray-300">
                                      <circle cx={1} cy={1} r={1} />
                                    </svg>
                                    <p className="text-gray-500">
                                      {topic.faculty.abbreviation}{" "}
                                      <span className="hidden sm:inline-block">({topic.faculty.name})</span>
                                    </p>
                                  </div>
                                </div>
                                <div className="group relative overflow-hidden">
                                  <h3 className="text-sm font-bold leading-6 text-gray-900 mt-1">{topic.title}</h3>
                                  <div className="flex flex-wrap items-center gap-x-2 gap-y-2 text-xs mt-3 text-gray-500">
                                    <time className="text-gray-400 mr-2">
                                      {!topic.updated
                                        ? moment(topic.created).locale(currentLanguage).format("MMM DD, YYYY")
                                        : moment(topic.updated).locale(currentLanguage).format("MMM DD, YYYY")}
                                    </time>
                                    {topic.university && !topic.active && <>({t("topics.search.inactive")})</>}
                                    <div className="relative rounded-full bg-gray-100 px-3 py-1.5 font-medium text-gray-600 truncate">
                                      {topic.research_field}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                            {topic.company && (
                              <div className="group relative w-full">
                                <div className="flex items-start gap-x-2 text-xs text-gray-500">
                                  <div className="flex flex-wrap items-center gap-x-2">
                                    <NavLink
                                      to={`/companies/${topic.company?.id}`}
                                      className="whitespace-nowrap flex items-center gap-x-1 hover:opacity-75"
                                    >
                                      <span className="font-semibold text-gray-900">{topic.company?.name}</span>{" "}
                                      <CheckBadgeIcon className="h-5 text-yellow-400" />
                                    </NavLink>
                                    {topic.company?.addresses && (
                                      <>
                                        <svg viewBox="0 0 2 2" className="h-0.5 w-0.5 fill-gray-300 hidden sm:flex">
                                          <circle cx={1} cy={1} r={1} />
                                        </svg>
                                        <div className="hidden sm:flex flex-wrap items-center gap-x-2">
                                          <span className="truncate leading-6 text-gray-500">
                                            {topic.company?.addresses?.city}
                                          </span>
                                        </div>
                                      </>
                                    )}
                                    {topic.company?.size && (
                                      <>
                                        <svg viewBox="0 0 2 2" className="h-0.5 w-0.5 fill-gray-300 hidden sm:flex">
                                          <circle cx={1} cy={1} r={1} />
                                        </svg>
                                        <div className="hidden sm:flex flex-wrap items-center gap-x-2 ">
                                          <span className="leading-6 text-gray-500">
                                            {t(`company.size.type.${topic.company?.size}`)}
                                          </span>
                                        </div>
                                      </>
                                    )}
                                    {currentUser.fields?.some((item: any) => topic.topic_fields?.map((e: any) => e.fields.id)?.includes(item)) && (
                                      <div className="sm:flex flex-wrap items-center ml-2">
                                        <span className="items-center rounded-full px-3 py-1 text-xs font-medium bg-gray-900 text-white">
                                          Match
                                        </span>
                                      </div>
                                    )}
                                  </div>
                                </div>
                                <h3 className="text-sm font-bold leading-6 text-gray-900 mt-1">{topic.title}</h3>
                                <div className="flex flex-wrap items-center gap-x-2 gap-y-2 text-xs mt-3 text-gray-500">
                                  <time className="text-gray-400 mr-2">
                                    {!topic.updated
                                      ? moment(topic.created).locale(currentLanguage).format("lll")
                                      : moment(topic.updated).locale(currentLanguage).format("MMM DD, YYYY")}
                                  </time>
                                  {topic.topic_experts && topic.topic_experts[0] && (
                                    <div className="flex shrink-0 items-center gap-x-6">
                                      <div className="flex min-w-0 flex-1 items-center space-x-3">
                                        <div className="flex-shrink-0">
                                          {topic.topic_experts[0]?.experts.users.image ? (
                                            <img
                                              className="w-6 h-6 rounded-full border object-cover"
                                              src={`https://resources.thesisnavigator.com/${topic.topic_experts[0]?.experts.users.image}`}
                                              alt=""
                                            />
                                          ) : (
                                            <img className="w-6 h-6 rounded-full" src={topic.topic_experts[0]?.experts.users.picture} alt="" />
                                          )}
                                        </div>
                                        <div className="min-w-0 flex-1">
                                          {topic.topic_experts[0] && (
                                            <div className="flex items-center space-x-3">
                                              <p className="truncate text-xs text-gray-500 leading-6">
                                                {topic.topic_experts[0]?.experts.users.first_name}{" "}
                                                {topic.topic_experts[0]?.experts.users.last_name}{" "}
                                              </p>
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                  {topic.university && !topic.active && <>({t("topics.search.inactive")})</>}
                                  {topic.topic_fields?.map((field: any) => (
                                    <span
                                      key={field.fields.id}
                                      className="items-center rounded-full px-3 py-1.5 text-xs font-medium border border-gray-300 text-gray-900"
                                    >
                                      {t(`fields.${field.fields.name}`)}
                                    </span>
                                  ))}
                                </div>
                                {topic.supervisor_topic_interests?.length > 0 && (
                                  <div className="mt-3 flex items-center space-x-4">
                                    <span className="relative group/bookmark">
                                      <AcademicCapIcon
                                        className="text-yellow-400 hover:text-yellow-300 w-5 flex-shrink-0 z-10"
                                        aria-hidden="true"
                                      />
                                      <div className="hidden sm:block absolute transition delay-300 duration-300 left-1/2 bottom-8 text-center transform -translate-x-1/2 bg-gray-800 text-white rounded-md px-2.5 py-1.5 text-xs font-medium w-28 z-20 pointer-events-none opacity-0 transition-opacity group-hover/bookmark:opacity-100">
                                        {t("topics.supervisor.interestedInSupervising")}
                                        <div className="invisible absolute left-12 bg-inherit before:visible before:absolute before:h-2.5 before:w-2.5 before:rotate-45 before:bg-inherit before:content-['']"></div>
                                      </div>
                                    </span>
                                    <div className="flex -space-x-1.5 overflow-hidden items-center flex-shrink-0">
                                      {topic.supervisor_topic_interests.slice(0, 2).map((e: any) =>
                                        e?.supervisors.users.image ? (
                                          <img
                                            className="inline-block h-6 w-6 rounded-full border object-cover"
                                            src={`https://resources.thesisnavigator.com/${e.supervisors.users.image}`}
                                            alt=""
                                          />
                                        ) : (
                                          <img
                                            className="inline-block h-6 w-6 rounded-full border object-cover"
                                            src={e.supervisors.users.picture}
                                            alt=""
                                          />
                                        )
                                      )}
                                    </div>
                                    <div className="text-gray-500 text-xs inline-block">
                                      {topic.supervisor_topic_interests.length === 1 ? t("topics.supervisor.interestedOne", { value: `${topic.supervisor_topic_interests[0].supervisors.users.first_name} ${topic.supervisor_topic_interests[0].supervisors.users.last_name}` }) : t("topics.supervisor.interestedMany", { value1: `${topic.supervisor_topic_interests[0].supervisors.users.first_name} ${topic.supervisor_topic_interests[0].supervisors.users.last_name}`, value2: topic.supervisor_topic_interests.length - 1 })}
                                    </div>
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                          {/* <div className="rounded-full bg-white px-2.5 py-1 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 group-hover:bg-gray-50">
                            {t("dashboard.students.courses.favorites.view")}
                          </div> */}
                        </NavLink>
                      </li>
                    ))}
                  </ul>
                </>
              )}
              {isAuthenticated && !isLoading && (
                <div className="mt-10 flex items-center justify-center gap-x-6">
                  <NavLink
                    to="/topics"
                    className="rounded-md bg-gray-900 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-gray-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
                  >
                    {t("dashboard.students.topics.button")}
                  </NavLink>
                </div>
              )}
            </div>
            <div className="pt-12 lg:pl-6 lg:pt-0">
              <div className="relative isolate overflow-hidden sm:mx-auto sm:max-w-2xl sm:rounded-3xl sm:pr-0 lg:mx-0 lg:max-w-none">
                <h2 className="text-xl font-bold tracking-tight text-gray-900 sm:text-2xl">
                  {t("dashboard.students.courses.title")}
                </h2>
                <p className="mx-auto mt-6 leading-6 text-gray-500 text-sm">
                  {t("dashboard.students.courses.description")}
                </p>
                {isAuthenticated && !isLoading && isFetchingCourses && (
                  <div className="my-10 flex justify-center items-center">
                    <Loader />
                  </div>
                )}
                {isAuthenticated && !isLoading && !isFetchingCourses && (
                  <>
                    <div className="flex justify-between mt-10 mb-2">
                      <div className="flex items-center font-bold">
                        <BookmarkIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-yellow-400" aria-hidden="true" />
                        {t("dashboard.students.courses.favorites.title")}
                      </div>
                      {courses.length > 1 && (
                        <NavLink
                          onClick={() => localStorage.setItem("bookmarked-courses", "true")}
                          to="/courses"
                          className="text-sm underline font-semibold text-gray-900 hover:text-gray-800 sm:block"
                        >
                          {t("dashboard.students.courses.favorites.viewAll")}
                        </NavLink>
                      )}
                    </div>
                    {courses.length === 0 && <NoSavedItems />}
                    <ul role="list" className="divide-y divide-gray-100">
                      {courses.slice(0, 3).map((course: any) => (
                        <li key={course.id}>
                          <NavLink
                            to={`/courses/${course.id}`}
                            className="flex items-center justify-between text-left gap-x-6 py-5 group"
                          >
                            <img
                              className="h-12 w-16 flex-none rounded-md bg-gray-50 border border-gray-200"
                              src={`${process.env.REACT_APP_RESOURCES_URL}/${course.preview}`}
                              alt=""
                            />
                            <div className="min-w-0 flex-auto">
                              <p className="text-sm font-bold leading-6 text-gray-900">{course.title}</p>

                              <div className="relative pt-0.5">
                                <div className="flex items-center justify-center gap-x-3">
                                  {course.resource && (
                                    <div className="text-xs leading-5 text-gray-400 w-28">
                                      ({getCourseDuration(course.resource)} min)
                                    </div>
                                  )}
                                  {course.resource && getCourseDurationInSeconds(course.resource) > 0 && (
                                    <>
                                      <div className="w-full overflow-hidden h-1 text-xs flex rounded bg-gray-100">
                                        <div
                                          style={{
                                            width: `${((getCourseMetric(course)?.course_time || 0) /
                                              getCourseDurationInSeconds(course.resource)) *
                                              100
                                              }%`,
                                          }}
                                          className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-yellow-400"
                                        ></div>
                                      </div>
                                      {!getCourseMetric(course)?.completed && (
                                        <div className="text-xs text-gray-400 pl-2 ml-auto">
                                          {Math.round(
                                            ((getCourseMetric(course)?.course_time || 0) /
                                              getCourseDurationInSeconds(course.resource)) *
                                            100
                                          )}
                                          %
                                        </div>
                                      )}
                                      {getCourseMetric(course)?.completed && (
                                        <div className="pl-2 ml-auto">
                                          <CheckCircleIconSolid
                                            className="h-6 w-6 text-yellow-400"
                                            aria-hidden="true"
                                          />
                                        </div>
                                      )}
                                    </>
                                  )}
                                </div>
                              </div>

                              <div className="mt-0">
                                <span className="inline-flex items-center rounded-full bg-gray-100 px-3 py-1.5 text-xs font-medium text-gray-600 mr-2 mt-1">
                                  {course.modules?.name?.filter((e: any) => e.language === currentLanguage)[0]?.name}
                                </span>{" "}
                              </div>
                            </div>
                            {/* <div className="rounded-full bg-white px-2.5 py-1 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 group-hover:bg-gray-50">
                              {t("dashboard.students.courses.favorites.view")}
                            </div> */}
                          </NavLink>
                        </li>
                      ))}
                    </ul>
                  </>
                )}
                {isAuthenticated && !isLoading && (
                  <div className="mt-10 flex items-center justify-center gap-x-6">
                    <NavLink
                      to="/courses"
                      className="rounded-md bg-gray-900 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-gray-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
                    >
                      {t("dashboard.students.courses.button")}
                    </NavLink>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
