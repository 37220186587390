import { useAuth0 } from "@auth0/auth0-react";
import { Fragment, useContext, useEffect, useState } from "react";
import { CurrentUserContext } from "../../contexts/userContext";
import Loader from "../../components/Loader";
import { useTranslation } from "react-i18next";
import { CalendarIcon, CheckIcon, ChevronDownIcon, PencilIcon } from "@heroicons/react/24/solid";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import "moment/locale/de";
import { Menu, MenuButton, MenuItem, MenuItems, Tab, TabGroup, TabList, TabPanel, TabPanels, Transition } from "@headlessui/react";
import { MessageType, useMessagesContext } from "../../contexts/messagesContext";
import ConfirmationDialog, { ConfirmationDialogType } from "../../components/ConfirmationDialog";
import Modal from "../../components/Modal";
import TopicSupervisorInterests from "../topics/TopicSupervisorInterests";
import Api from "../../api/client";
import type { Topic } from "@api/generated";
import { Breadcrumb, BreadcrumbList, BreadcrumbListItem, BreadcrumbSeparator } from "../../components/breadcrumb";

export default function SupervisorTopic() {
  const { currentUser } = useContext(CurrentUserContext);
  const { id } = useParams();
  const { isAuthenticated, isLoading } = useAuth0();
  const [isFetching, setIsFetching] = useState(true);
  const [isEditable, setIsEditable] = useState<boolean>(false);
  const [topic, setTopic] = useState<Topic | undefined>(undefined);
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const currentLanguage = i18n.language;
  const location = useLocation();
  const isUniversityRoute = location.pathname.includes("/university/topics");
  const { addMessage } = useMessagesContext();
  const [isDeleteTopicConfirmationOpen, setIsDeleteTopicConfirmationOpen] = useState<boolean>(false);

  const getTopic = async (id: string) => {
    setIsFetching(true);
    await Api.SupervisorTopics.findSupervisorTopicById({
      id: id
    })
      .then((response) => {
        setTopic(response.data);
        setIsFetching(false);
      })
      .catch((error) => {
        setIsFetching(false);
        if (error.response && error.response.status === 403) {
          navigate("/error/forbidden");
        }
        console.error("Error fetching data:", error);
      });
  };

  const deleteTopic = async () => {
    try {
      await Api.SupervisorTopics.deleteSupervisorTopic({
        id: id
      })
      addMessage({
        type: MessageType.Success,
        title: "topics.topic.delete.notification.title",
        description: "topics.topic.delete.notification.description",
      });
      if (isUniversityRoute) {
        navigate("/university/topics");
      } else {
        navigate("/supervisor/topics");
      }
    } catch {
      addMessage({
        type: MessageType.Error,
        title: "common.error.title",
        description: "common.error.description",
      });
    }
  };

  const publishTopic = async () => {
    try {
      const response = await Api.SupervisorTopics.updateSupervisorTopic({
        id: id,
        supervisorTopicUpdate: {
          published: true
        }
      })
      setTopic(response.data);
      addMessage({
        type: MessageType.Success,
        title: "topics.topic.publish.notification.title",
        description: "topics.topic.publish.notification.description",
        retain: true,
      });
    } catch {
      addMessage({
        type: MessageType.Error,
        title: "common.error.title",
        description: "common.error.description",
      });
    }
  };

  const unPublishTopic = async () => {
    try {
      const response = await Api.SupervisorTopics.updateSupervisorTopic({
        id: id,
        supervisorTopicUpdate: {
          published: false
        }
      })
      setTopic(response.data);
      addMessage({
        type: MessageType.Success,
        title: "topics.topic.unpublish.notification.title",
        description: "topics.topic.unpublish.notification.description",
      });
    } catch {
      addMessage({
        type: MessageType.Error,
        title: "common.error.title",
        description: "common.error.description",
      });
    }
  };

  const closeDeleteTopicConfirmation = () => {
    setIsDeleteTopicConfirmationOpen(false);
  };

  useEffect(() => {
    setIsEditable(topic?.supervisors?.some(user => user.id === currentUser.id) || false);
  }, [currentUser, topic]);

  useEffect(() => {
    if (id) {
      getTopic(id);
    }
  }, [id]);

  const classNames = (...classes: any) => {
    return classes.filter(Boolean).join(" ");
  };

  return (
    <div className="bg-white pb-6">
      <Breadcrumb>
        <BreadcrumbList>
          <BreadcrumbListItem>
            <NavLink to={isUniversityRoute ? `/university/profile` : `/user/profile`}>
              {isUniversityRoute ? t("company.profile") : t("common.myProfile")}
            </NavLink>
          </BreadcrumbListItem>
          <BreadcrumbSeparator />
          <BreadcrumbListItem>
            <NavLink to={isUniversityRoute ? "/university/topics" : "/supervisor/topics"}>
              {isUniversityRoute ? t("company.topics") : t("common.myTopics")}
            </NavLink>
          </BreadcrumbListItem>
        </BreadcrumbList>
      </Breadcrumb>
      {isFetching && (
        <div className="flex justify-center items-center mt-6">
          <Loader />
        </div>
      )}
      {isAuthenticated && !isLoading && !isFetching && (
        <>
          <div className="lg:flex lg:items-start lg:justify-between lg:space-x-6">
            <div className="min-w-0 flex-1">
              <h2 className="my-2 text-2xl font-bold leading-8 text-gray-900 tracking-tight">{topic.title}</h2>
              <div className="flex flex-wrap gap-x-4 gap-y-1">
                <div className="flex items-center text-sm text-gray-400 space-x-4">
                  <CalendarIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                  {
                    topic.created === topic.updated
                      ? <>{moment(topic.created).locale(currentLanguage).format("lll")}</>
                      : <>{t("common.updated")} {moment(topic.updated).locale(currentLanguage).format("lll")}</>
                  }
                </div>
                {topic.supervisors?.length > 0 && (
                  <div className="ml-2 flex -space-x-1.5 overflow-hidden flex-shrink-0">
                    {topic.supervisors?.map((user) =>
                      user.image ? (
                        <img key={user.id}
                          className="inline-block h-6 w-6 rounded-full border object-cover"
                          src={`https://resources.thesisnavigator.com/${user.image}`}
                          alt=""
                        />
                      ) : (
                        <img key={user.id}
                          className="inline-block h-6 w-6 rounded-full border object-cover"
                          src={user.picture}
                          alt=""
                        />
                      )
                    )}
                  </div>
                )}
                {!topic.published && (
                  <span className="inline-flex items-center rounded-full bg-yellow-100 px-3 py-1.5 text-xs font-medium text-yellow-800">
                    {t("common.draft")}
                  </span>
                )}
              </div>
            </div>
            <div className="mt-6 lg:mt-2 flex space-x-3">
              {isEditable && (
                <span className="sm:block">
                  <button
                    type="button"
                    className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                    onClick={() =>
                      navigate(isUniversityRoute ? `/university/topics/${topic.id}/edit` : `/supervisor/topics/${topic.id}/edit`)
                    }
                  >
                    <PencilIcon className="-ml-0.5 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                    {t("common.edit")}
                  </button>
                </span>
              )}
              <div className="inline-flex rounded-md shadow-sm divide-x divide-white">
                {isEditable && topic.published && (
                  <span className="sm:block">
                    <button
                      className={classNames(
                        isEditable ? "rounded-l-md" : "rounded-md",
                        "inline-flex items-center bg-gray-900 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
                      )}
                      onClick={unPublishTopic}
                    >
                      {t("common.unpublish")}
                    </button>
                  </span>
                )}
                {isEditable && !topic.published && (
                  <span className="sm:block">
                    <button
                      type="button"
                      className="inline-flex items-center rounded-l-md bg-gray-900 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
                      onClick={publishTopic}
                    >
                      <CheckIcon className="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" />
                      {t("common.publish")}
                    </button>
                  </span>
                )}
                {isEditable && (
                  <Menu as="div" className="relative -ml-px block">
                    <MenuButton
                      className="inline-flex items-center rounded-r-md bg-gray-900 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900">
                      <span className="sr-only">Open options</span>
                      <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
                    </MenuButton>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <MenuItems
                        className="absolute right-0 z-10 mt-3 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none divide-y divide-gray-100">
                        {topic.published && (
                          <div className="py-1">
                            <MenuItem>
                              {({ active }) => (
                                <NavLink
                                  to={`/topics?id=${id}`}
                                  type="button"
                                  className={classNames(
                                    active ? "bg-gray-50" : "",
                                    "block w-full px-4 py-1 text-sm leading-6 text-gray-900 text-left"
                                  )}
                                >
                                  {t("common.view")}
                                </NavLink>
                                // <button
                                //   className={classNames(
                                //     active ? "bg-gray-50" : "",
                                //     "block w-full px-4 py-1 text-sm leading-6 text-gray-900 text-left"
                                //   )}
                                //   onClick={unPublishTopic}
                                // >
                                //   {t("common.unpublish")}
                                // </button>
                              )}
                            </MenuItem>
                          </div>
                        )}
                        <div className="py-1">
                          <MenuItem>
                            {({ active }) => (
                              <button
                                className={classNames(
                                  active ? "bg-gray-50" : "",
                                  "block w-full px-4 py-1 text-sm leading-6 text-left text-red-600"
                                )}
                                onClick={() => {
                                  setIsDeleteTopicConfirmationOpen(true);
                                }}
                              >
                                {t("common.delete")}
                              </button>
                            )}
                          </MenuItem>
                        </div>
                      </MenuItems>
                    </Transition>
                  </Menu>
                )}
              </div>
            </div>
          </div>
          <div>
            <TabGroup className="mt-6">
              <div className="">
                <div className="flex-auto border-b border-gray-200">
                  <TabList className="-mb-px flex space-x-6">
                    <Tab
                      key="topic"
                      className="whitespace-nowrap border-b-2 border-transparent px-1 py-4 font-medium text-sm text-gray-500 hover:border-gray-300 data-[selected]:font-semibold data-[selected]:border-gray-900 data-[selected]:text-gray-900 outline-none flex items-center"
                    >
                      {t("topics.form.description.title")}
                    </Tab>
                  </TabList>
                </div>
              </div>

              <TabPanels as={Fragment}>
                <TabPanel key={"topic"} className="space-y-6">
                  <dl className="grid grid-cols-1 sm:grid-cols-2 whitespace-pre-line linkify">
                    {topic.internship && (
                      <div className="py-6 sm:col-span-1">
                        <dt className="font-semibold text-gray-900">{t("topics.form.internship.title")}</dt>
                        <dd className="mt-1 text-sm/6 text-gray-700 sm:mt-2">{t(`topics.form.internship.${topic.internship}`)}</dd>
                      </div>
                    )}
                    {topic.levels?.length > 0 && (
                      <div className="border-t sm:border-none border-gray-100 py-6 sm:col-span-1">
                        <dt className="font-semibold text-gray-900">{t("Level")}</dt>
                        <dd className="mt-1 text-sm/6 text-gray-700 sm:mt-2">{topic.levels.map((e: any) => t(`level.${e}`)).join(", ")}</dd>
                      </div>
                    )}
                    {topic.description && (
                      <div className="border-t border-gray-100 py-6 sm:col-span-2">
                        <dt className="font-semibold text-gray-900">{t("topics.form.description.title")}</dt>
                        <dd className="mt-1 text-sm/6 text-gray-700 sm:mt-2">
                          <p className="editor" dangerouslySetInnerHTML={{ __html: t(topic.description) }} />
                        </dd>
                      </div>
                    )}
                    {topic.fields?.length > 0 && (
                      <div className="border-t border-gray-100 py-6 sm:col-span-2">
                        <div className="font-semibold text-gray-900">{t("topics.form.fields.title")}</div>
                        <div className="flex flex-wrap gap-4 mt-3">
                          {topic.fields?.map((field: any) =>
                            <span
                              key={field.id}
                              className="items-center rounded-full px-3 py-1.5 text-xs font-medium border border-gray-300 text-gray-900"
                            >
                              {t(`fields.${field.name}`)}
                            </span>
                          )}
                        </div>
                      </div>
                    )}
                    {topic.requirements && (
                      <div className="border-t border-gray-100 py-6 sm:col-span-2">
                        <dt className="font-semibold text-gray-900">{t("topics.form.requirements.title")}</dt>
                        <dd className="mt-1 text-sm/6 text-gray-700 sm:mt-2">
                          <p className="editor" dangerouslySetInnerHTML={{ __html: t(topic.requirements) }} />
                        </dd>
                      </div>
                    )}
                  </dl>
                  {topic.supervisor_topic_interests?.length > 0 && (
                    <TopicSupervisorInterests interests={topic.supervisor_topic_interests} />
                  )}
                </TabPanel>
              </TabPanels>
            </TabGroup>
          </div>
        </>
      )}
      <Modal isOpen={isDeleteTopicConfirmationOpen} onClose={closeDeleteTopicConfirmation}>
        <ConfirmationDialog
          type={ConfirmationDialogType.Delete}
          title={t("topics.topic.delete.confirmation.title")}
          body={t("topics.topic.delete.confirmation.description")}
          cancel={t("common.cancel")}
          save={t("common.delete")}
          onCancel={closeDeleteTopicConfirmation}
          onSave={deleteTopic}
        />
      </Modal>
    </div>
  );
}
