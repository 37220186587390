import { AcademicCapIcon, ArrowTopRightOnSquareIcon } from "@heroicons/react/24/solid";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CheckBadgeIcon } from "@heroicons/react/24/solid";
import moment from "moment";
import "moment/locale/de";
import { NavLink, useNavigate } from "react-router-dom";
import { CurrentUserContext } from "../../contexts/userContext";
import { UserType, thesisProjectStates } from "../../models";
import httpsClient from "../../config/https";
import { ExclamationTriangleIcon } from "@heroicons/react/20/solid";
import Loader from "../../components/Loader";
import ConfirmationDialog, { ConfirmationDialogType } from "../../components/ConfirmationDialog";
import Modal from "../../components/Modal";
import { isMobile } from "react-device-detect";
import LegacyTopicSupervisorInterests from "./LegacyTopicSupervisorInterests";

interface Props {
  topic: any;
  onClose: any;
  onBookmark?: any;
}

const Topic: React.FC<Props> = ({ topic, onClose, onBookmark }) => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const { currentUser } = useContext(CurrentUserContext);
  const [parentWidth, setParentWidth] = useState(0);
  const [isFetching, setIsFetching] = useState(false);
  const [userThesisProjectsState, setUserThesisProjectsState] = useState<any>();
  const isStudent = currentUser?.type === UserType.Student;
  const isExpert = currentUser?.type === UserType.Expert;
  const [isApplicationModalOpen, setIsApplicationModalOpen] = useState<boolean>(false);
  const navigate = useNavigate();

  const getUserThesisProjectsState = async () => {
    setIsFetching(true);
    await httpsClient
      .get(`/api/v1/user/thesis-projects/state?topic_id=${topic.id}`)
      .then((response) => {
        setUserThesisProjectsState(response.data);
        setIsFetching(false);
      })
      .catch((error) => {
        setIsFetching(false);
      });
  };

  const openApplicationModal = () => {
    setIsApplicationModalOpen(true);
  };

  const closeApplicationModal = () => {
    setIsApplicationModalOpen(false);
  };

  const apply = () => {
    if (isMobile) {
      document.body.style.overflow = "auto";
    }
    navigate(`/user/thesis-projects/new?topic=${topic.id}`);
  };

  const navigateToCompany = () => {
    if (isMobile) {
      document.body.style.overflow = "auto";
    }
    navigate(`/companies/${topic.company?.id}`);
  };

  useEffect(() => {
    setUserThesisProjectsState(null);
    if (isStudent) {
      getUserThesisProjectsState();
    }
  }, [topic]);

  useEffect(() => {
    const updateParentWidth = () => {
      const parentDiv = document.getElementById("topic");
      if (parentDiv) {
        setParentWidth(parentDiv.offsetWidth);
      }
    };
    updateParentWidth();
    window.addEventListener("resize", updateParentWidth);
    return () => {
      window.removeEventListener("resize", updateParentWidth);
    };
  }, []);

  const classNames = (...classes: any) => {
    return classes.filter(Boolean).join(" ");
  };

  return (
    <div className="w-full h-full bg-white" id="topic">
      <div className="px-8 pt-8 pb-4 border-b border-gray-100 bg-slate-50">
        {topic.university && (
          <div className="flex flex-wrap items-center gap-x-2 leading-5 text-xs leading-6 font-semibold text-gray-900">
            <p className="whitespace-nowrap">{topic.university}</p>
            {topic.faculty?.name && (
              <>
                <svg viewBox="0 0 2 2" className="h-0.5 w-0.5 fill-gray-300">
                  <circle cx={1} cy={1} r={1} />
                </svg>
                <p className="text-gray-500 font-normal">
                  {topic.faculty?.name} <span className="inline">({topic.faculty?.abbreviation})</span>
                </p>
              </>
            )}
          </div>
        )}
        {topic.company && (
          <div className="flex flex-wrap items-center gap-x-2 leading-5 text-xs leading-6 text-gray-900">
            <button
              onClick={navigateToCompany}
              className="whitespace-nowrap font-semibold flex items-center gap-x-1 hover:opacity-75"
            >
              {topic.company?.name} <CheckBadgeIcon className="h-5 text-yellow-400" />
            </button>
            {topic.company?.addresses && (
              <>
                <svg viewBox="0 0 2 2" className="h-0.5 w-0.5 fill-gray-300">
                  <circle cx={1} cy={1} r={1} />
                </svg>
                <div className="flex flex-wrap items-center gap-x-2">
                  <span className="truncate leading-6 text-gray-500">{topic.company?.addresses?.city}</span>
                </div>
              </>
            )}
            {topic.company?.size && (
              <>
                <svg viewBox="0 0 2 2" className="h-0.5 w-0.5 fill-gray-300">
                  <circle cx={1} cy={1} r={1} />
                </svg>
                <div className="flex flex-wrap items-center gap-x-2">
                  <span className="leading-6 text-gray-500">{t(`company.size.type.${topic.company?.size}`)}</span>
                </div>
              </>
            )}
            {currentUser.fields?.some((item: any) => topic.topic_fields?.map((e: any) => e.fields.id)?.includes(item)) && (
              <div className="sm:flex flex-wrap items-center ml-2">
                <span className="items-center rounded-full px-3 py-1 text-xs font-medium bg-gray-900 text-white">
                  Match
                </span>
              </div>
            )}
          </div>
        )}
        <h2 className="text-2xl font-bold text-gray-900 pb-3 mt-2 tracking-tight">{topic.title}</h2>
        <div className="flex flex-wrap gap-x-4 gap-y-2 items-center text-xs text-gray-500 pb-4">
          <time className="text-gray-400 mr-2">
            {moment(topic.updated).locale(currentLanguage).format("MMM DD, YYYY")}
          </time>
          {topic.topic_experts && topic.topic_experts[0] && (
            <div className="flex shrink-0 items-center gap-x-6">
              <div className="flex min-w-0 flex-1 items-center space-x-3">
                <div className="flex-shrink-0">
                  {topic.topic_experts[0]?.experts.users.image ? (
                    <img
                      className="w-6 h-6 rounded-full border object-cover"
                      src={`https://resources.thesisnavigator.com/${topic.topic_experts[0]?.experts.users.image}`}
                      alt=""
                    />
                  ) : (
                    <img className="w-6 h-6 rounded-full" src={topic.topic_experts[0]?.experts.users.picture} alt="" />
                  )}
                </div>
                <div className="min-w-0 flex-1">
                  {topic.topic_experts[0] && (
                    <div className="flex items-center space-x-3">
                      <p className="truncate text-xs text-gray-500 leading-6">
                        {topic.topic_experts[0]?.experts.users.first_name}{" "}
                        {topic.topic_experts[0]?.experts.users.last_name}{" "}
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
          {topic.supervisors && topic.supervisors[0] && (
            <div className="flex shrink-0 items-center gap-x-6">
              <div className="flex min-w-0 flex-1 items-center space-x-3">
                <div className="flex-shrink-0">
                  {topic.supervisors[0]?.image ? (
                    <img
                      className="w-6 h-6 rounded-full border object-cover"
                      src={`https://resources.thesisnavigator.com/${topic.supervisors[0]?.image}`}
                      alt=""
                    />
                  ) : (
                    <img className="w-6 h-6 rounded-full" src={topic.supervisors[0]?.picture} alt="" />
                  )}
                </div>
                <div className="min-w-0 flex-1">
                  {topic.supervisors[0] && (
                    <div className="flex items-center space-x-3">
                      <p className="truncate text-xs text-gray-500 leading-6">
                        {topic.supervisors[0]?.first_name}{" "}
                        {topic.supervisors[0]?.last_name}{" "}
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
          {topic.topic_fields?.map((field: any) =>
            <span
              key={field.fields.id}
              className={classNames(
                currentUser.fields.some((e: any) => e === field.fields.id)
                  ? "bg-gray-900 text-white border-transparent"
                  : "border-gray-300 text-gray-900",
                "items-center rounded-full px-3 py-1.5 text-xs font-medium border"
              )}
            >
              {t(`fields.${field.fields.name}`)}
            </span>
          )}
          {topic.research_field && (
            <span className="inline-flex items-center rounded-full bg-gray-100 px-3 py-1.5 text-xs font-medium text-gray-600">
              {topic.research_field}
            </span>
          )}
          {topic.university && !topic.active && <>({t("topics.search.inactive")})</>}

        </div>
      </div>
      <div className="px-8 pb-36">
        {topic.company?.id &&
          isStudent &&
          !userThesisProjectsState?.eligible &&
          userThesisProjectsState?.topic?.eligible && (
            <div className="border-l-4 border-yellow-400 bg-yellow-50 p-4 my-6">
              <div className="flex">
                <div className="flex-shrink-0">
                  <ExclamationTriangleIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
                </div>
                <div className="ml-3">
                  <p className="text-sm text-yellow-700 font-medium">{t("topics.topic.apply.notEligible")}</p>
                </div>
              </div>
            </div>
          )}
        {topic.company?.id &&
          isStudent &&
          !userThesisProjectsState?.topic?.eligible &&
          userThesisProjectsState?.topic?.thesis_projects?.length === 0 && (
            <div className="border-l-4 border-yellow-400 bg-yellow-50 p-4 my-6">
              <div className="flex">
                <div className="flex-shrink-0">
                  <ExclamationTriangleIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
                </div>
                <div className="ml-3">
                  <p className="text-sm text-yellow-700">
                    {t("topics.topic.apply.notAvailable.title")}{" "}
                    <button
                      onClick={onBookmark}
                      className="font-medium text-left text-yellow-700 underline hover:text-yellow-600"
                    >
                      {t("topics.topic.apply.notAvailable.action")}
                    </button>
                  </p>
                </div>
              </div>
            </div>
          )}
        <dl className="grid grid-cols-1 sm:grid-cols-2 whitespace-pre-line linkify">
          {topic.internship && (
            <div className="py-6 sm:col-span-1">
              <dt className="font-semibold text-gray-900">{t("topics.form.internship.title")}</dt>
              <dd className="mt-1 text-sm/6 text-gray-700 sm:mt-2">{t(`topics.form.internship.${topic.internship}`)}</dd>
            </div>
          )}
          {topic.levels?.length > 0 && (
            <div className="border-t sm:border-none border-gray-100 py-6 sm:col-span-1">
              <dt className="font-semibold text-gray-900">{t("Level")}</dt>
              <dd className="mt-1 text-sm/6 text-gray-700 sm:mt-2">{topic.levels.map((e: any) => t(`level.${e}`)).join(", ")}</dd>
            </div>
          )}
          {topic.description && (
            <div className="border-t border-gray-100 py-6 sm:col-span-2">
              <dt className="font-semibold text-gray-900">{t("topics.form.description.title")}</dt>
              <dd className="mt-1 text-sm/6 text-gray-700 sm:mt-2">
                <p className="editor" dangerouslySetInnerHTML={{ __html: t(topic.description) }} />
              </dd>
            </div>
          )}
          {topic.requirements && (
            <div className="border-t border-gray-100 py-6 sm:col-span-2">
              <dt className="font-semibold text-gray-900">{t("topics.form.requirements.title")}</dt>
              <dd className="mt-1 text-sm/6 text-gray-700 sm:mt-2">
                <p className="editor" dangerouslySetInnerHTML={{ __html: t(topic.requirements) }} />
              </dd>
            </div>
          )}
        </dl>
        {topic.supervisor_topic_interests?.length > 0 && (
          <LegacyTopicSupervisorInterests interests={topic.supervisor_topic_interests} />
        )}
        {topic.keywords && topic.keywords.length > 0 && (
          <div className="flex flex-wrap items-center gap-x-2 gap-y-2 text-xs py-6">
            {topic.keywords?.map((keyword: any) => (
              <span
                key={keyword}
                className="inline-flex items-center rounded-full bg-gray-100 px-3 py-1.5 text-xs font-medium text-gray-600 capitalize"
              >
                {keyword}
              </span>
            ))}
          </div>
        )}
      </div>
      <div
        style={{
          width: parentWidth ? `${parentWidth}px` : "100%",
        }}
        className="border-t border-gray-100 bg-white bg-opacity-80 px-8 py-6 fixed bottom-0 right-0 w-full"
        id="topic-actions"
      >
        <div className="flex justify-end space-x-4">
          {isFetching && <Loader />}
          {!isFetching &&
            !(
              topic.company?.id &&
              isStudent &&
              // !userThesisProjectsState?.eligible &&
              !userThesisProjectsState?.topic?.eligible &&
              userThesisProjectsState?.topic?.thesis_projects?.length > 0
            ) && (
              <button
                type="button"
                className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-100"
                onClick={onClose}
              >
                {t("common.close")}
              </button>
            )}

          {!isFetching && !topic.company?.id && topic.deeplink && (
            <a
              href={topic.deeplink}
              target="_blank"
              className="inline-flex justify-center rounded-md bg-gray-900 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
            >
              <span className="flex items-center">
                <span>{t("topics.topic.openSource")}</span>
                <ArrowTopRightOnSquareIcon className="ml-1 w-4 h-4 stroke-2" />
              </span>
            </a>
          )}
          {!isFetching &&
            topic.company?.id &&
            isStudent &&
            userThesisProjectsState?.eligible &&
            userThesisProjectsState?.topic?.eligible && (
              <button
                className="inline-flex justify-center rounded-md bg-gray-900 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
                onClick={openApplicationModal}
              >
                {t("common.apply")}
              </button>
            )}
          {!isFetching &&
            topic.company?.id &&
            isStudent &&
            // !userThesisProjectsState?.eligible &&
            !userThesisProjectsState?.topic?.eligible &&
            userThesisProjectsState?.topic?.thesis_projects?.length > 0 && (
              <div className="flex items-center space-x-6 w-full">
                <div className="flex items-center space-x-6 mr-auto">
                  {/* <FolderIcon className="h-7 text-gray-400" /> */}
                  <div>
                    <div className="flex items-center space-x-2 overflow-hidden">
                      <div className="text-xs font-medium text-gray-900">
                        {moment(userThesisProjectsState?.topic?.thesis_projects[0].created)
                          .locale(currentLanguage)
                          .format("MMM DD, YYYY")}
                      </div>
                      <div
                        className={classNames(
                          thesisProjectStates[userThesisProjectsState?.topic?.thesis_projects[0].state],
                          "items-start rounded-full px-3 py-1.5 text-xs font-medium truncate"
                        )}
                      >
                        {t(`thesisProjects.state.${userThesisProjectsState?.topic?.thesis_projects[0].state}`)}
                      </div>
                    </div>
                    {userThesisProjectsState?.topic?.thesis_projects[0].updated && (
                      <div className="text-xs text-gray-500 mt-1">
                        {t("common.updated")}{" "}
                        {moment(userThesisProjectsState?.topic?.thesis_projects[0].updated)
                          .locale(currentLanguage)
                          .format("MMM DD, YYYY")}
                      </div>
                    )}
                  </div>
                </div>
                <NavLink
                  type="submit"
                  className="inline-flex justify-center rounded-md bg-gray-900 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900 capitalize ml-auto"
                  to={`/user/thesis-projects/${userThesisProjectsState?.topic?.thesis_projects[0].id}`}
                >
                  {t("common.view")}
                </NavLink>
              </div>
            )}
          {!isFetching &&
            topic.company?.id &&
            isStudent &&
            ((!userThesisProjectsState?.eligible && userThesisProjectsState?.topic?.eligible) ||
              (!userThesisProjectsState?.topic?.eligible &&
                userThesisProjectsState?.topic?.thesis_projects?.length === 0)) && (
              <button
                className="inline-flex justify-center rounded-md bg-gray-900 px-3 py-2 text-sm font-semibold text-white shadow-sm capitalize disabled:opacity-50"
                disabled
              >
                {t("common.apply")}
              </button>
            )}
        </div>
      </div>
      <Modal isOpen={isApplicationModalOpen} onClose={closeApplicationModal}>
        <ConfirmationDialog
          type={ConfirmationDialogType.Confirm}
          title={t("topics.topic.apply.confirm.title")}
          body={t("topics.topic.apply.confirm.description")}
          cancel={t("common.cancel")}
          save={t("common.continue")}
          onCancel={closeApplicationModal}
          onSave={apply}
        />
      </Modal>
    </div>
  );
};

export default Topic;
