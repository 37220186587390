import {useFieldArray, useForm} from "react-hook-form";
import {array, object, string} from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {useAuth0} from "@auth0/auth0-react";
import {useState} from "react";
import Loader from "../../../components/Loader";
import {useTranslation} from "react-i18next";
import {MinusCircleIcon, PlusCircleIcon} from "@heroicons/react/24/outline";
import {MessageType, useMessagesContext} from "../../../contexts/messagesContext";
import {NavLink, useNavigate} from "react-router-dom";
import Api from '../../../api/client';
import {AdminCompanyCreate} from "@api/generated";
import {Breadcrumb, BreadcrumbList, BreadcrumbListItem} from "../../../components/breadcrumb";
import {DOMAIN_REGEXP} from "../../../utils/validators";

export default function AdminCompanyNew() {
  const {t, i18n} = useTranslation();
  const {addMessage} = useMessagesContext();

  // Define the validation schema
  const validationSchema = object({
    name: string().required(),
    domains: array().of(string().min(5).required().matches(DOMAIN_REGEXP)).min(1).required(),
  }).required();

  const {
    control,
    reset,
    register,
    handleSubmit,
    formState: {errors},
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      name: "",
      domains: [],
    },
    mode: "onChange"
  });
  const {fields: fieldsDomain, append: appendDomain, remove: removeDomain} = useFieldArray({
    control: control,
    name: "domains",
  });

  const {isAuthenticated, isLoading} = useAuth0();
  const navigate = useNavigate();
  const [isFetching, setIsFetching] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);


  const createCompany = async (data: AdminCompanyCreate) => {
    setIsUpdating(true);
    try {
      const response = await Api.AdminCompanies.createAdminCompany({adminCompanyCreate: data});
      addMessage({
        type: MessageType.Success,
        title: "company.notification.create.title",
        description: "company.notification.create.description",
      });
      setIsUpdating(false);
      navigate(`/admin/companies/${response.data.id}`)
    } catch {
      addMessage({
        type: MessageType.Error,
        title: "common.error.title",
        description: "common.error.description",
      });
      setIsUpdating(false);
    }
  };


  const onCancel = () => {
    navigate("/admin/companies");
  };

  const onSubmit = async (data) => {
    await createCompany(data);
  };

  return (
    <div className="bg-white">
      <Breadcrumb>
        <BreadcrumbList>
          <BreadcrumbListItem>
            <NavLink to={'/admin/companies'}>
              {t("common.companies")}
            </NavLink>
          </BreadcrumbListItem>
        </BreadcrumbList>
      </Breadcrumb>
      {isFetching && (
        <div className="flex justify-center items-center">
          <Loader/>
        </div>
      )}
      {isAuthenticated && !isLoading && !isFetching && (
        <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
          <div className="pb-6 border-b border-gray-900/10">
            <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl tracking-tight mt-2">
              {t("company.form.new")}
            </h2>
            <p className="mt-1 text-sm leading-6 text-gray-500">
              {t("company.form.subtitle")}
            </p>
            <div className="mt-12 grid grid-cols-1 gap-x-6 gap-y-6 sm:grid-cols-6">
              <div className="sm:col-span-full">
                <label htmlFor="name" className="block text-sm font-medium leading-6 text-gray-900">
                  {t("company.form.name.label")}
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    autoComplete="given-name"
                    placeholder={t("company.form.name.placeholder")}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-900 text-sm sm:leading-6 disabled:opacity-75 disabled:bg-gray-100"
                    {...register("name")}
                  />
                  {errors.name && <p className="mt-2 text-xs text-red-600">{errors.name?.message?.toString()}</p>}
                </div>
              </div>

              <div className="sm:col-span-4">
                <label htmlFor="domain" className="block text-sm font-medium leading-6 text-gray-900">
                  {t("company.form.domains.label")}
                </label>
                <div className="mt-2">
                  {fieldsDomain.map((field, index) => (
                    <div key={field.id} className="pb-4">
                      <div className="flex items-center space-x-2">
                        <input
                          type="text"
                          {...register(`domains.${index}`)}
                          placeholder={t("company.form.domains.placeholder")}
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-900 text-sm sm:leading-6 disabled:opacity-75 disabled:bg-gray-100"
                        />
                        <button
                          type="button"
                          onClick={() => removeDomain(index)}
                        >
                          <MinusCircleIcon className="text-gray-900 h-6"/>
                        </button>
                      </div>
                      {errors.domains?.[index] && (<p className="mt-2 text-xs text-red-600">{errors.domains[index]?.message}</p>)}
                    </div>
                  ))}
                  <button
                    type="button"
                    onClick={() => appendDomain('')}
                    className="inline-flex items-center rounded-md text-sm font-semibold text-gray-900 mt-1 disabled:opacity-75"
                  >
                    <PlusCircleIcon className="-mt-0.5 mr-1.5 h-6" aria-hidden="true"/>
                    {t("company.domain.button")}
                  </button>
                  {errors.domains && (<p className="mt-2 text-xs text-red-600">{errors.domains.message}</p>)}
                </div>
              </div>
            </div>
          </div>

          <div className="mt-6 flex items-center justify-end gap-x-6">
            {isUpdating && <Loader/>}
            {!isUpdating && (
              <>
                <button
                  type="button"
                  onClick={onCancel}
                  className="text-sm font-semibold leading-6 text-gray-900"
                >
                  {t("common.cancel")}
                </button>
                <button
                  type="submit"
                  className="rounded-md bg-gray-900 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
                >
                  {t("common.save")}
                </button>
              </>
            )}
          </div>
        </form>
      )}
    </div>
  );
}
