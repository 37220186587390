import React, {PropsWithChildren} from "react";
import {ChevronRightIcon} from "@heroicons/react/24/solid";

// Define the prop types
export interface BreadcrumbSeparatorProps {
  className?: string;
}

// Functional component with props
const BreadcrumbSeparator: React.FC<PropsWithChildren<BreadcrumbSeparatorProps>> = ({className = '', children}) => {
  return (
    <li className={`${className}`}>
      <ChevronRightIcon className={`h-5 w-5 flex-shrink-0 text-gray-400`} aria-hidden="true" />
    </li>

  );
};

// Export the component
export default React.memo(BreadcrumbSeparator);
