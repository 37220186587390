/* tslint:disable */
/* eslint-disable */
/**
 * Thesis Navigator API
 * All documentation for the Thesis Navigator API.
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import type { AdminCompanyExpert } from '../model';
// @ts-ignore
import type { AdminCompanyExpertCreate } from '../model';
// @ts-ignore
import type { AdminCompanyExpertPage } from '../model';
// @ts-ignore
import type { AdminCompanyExpertUpdate } from '../model';
/**
 * AdminCompanyExpertsApi - axios parameter creator
 * @export
 */
export const AdminCompanyExpertsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create a Company Expert.
         * @param {string} companyId 
         * @param {AdminCompanyExpertCreate} adminCompanyExpertCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAdminCompanyExpert: async (companyId: string, adminCompanyExpertCreate: AdminCompanyExpertCreate, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('createAdminCompanyExpert', 'companyId', companyId)
            // verify required parameter 'adminCompanyExpertCreate' is not null or undefined
            assertParamExists('createAdminCompanyExpert', 'adminCompanyExpertCreate', adminCompanyExpertCreate)
            const localVarPath = `/api/v2/admin/companies/{companyId}/experts`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminCompanyExpertCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a Company Expert
         * @param {string} companyId 
         * @param {string} expertId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAdminCompanyExpert: async (companyId: string, expertId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('deleteAdminCompanyExpert', 'companyId', companyId)
            // verify required parameter 'expertId' is not null or undefined
            assertParamExists('deleteAdminCompanyExpert', 'expertId', expertId)
            const localVarPath = `/api/v2/admin/companies/{companyId}/experts/{expertId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"expertId"}}`, encodeURIComponent(String(expertId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find a Company Expert by ID
         * @param {string} companyId 
         * @param {string} expertId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAdminCompanyExpertById: async (companyId: string, expertId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('findAdminCompanyExpertById', 'companyId', companyId)
            // verify required parameter 'expertId' is not null or undefined
            assertParamExists('findAdminCompanyExpertById', 'expertId', expertId)
            const localVarPath = `/api/v2/admin/companies/{companyId}/experts/{expertId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"expertId"}}`, encodeURIComponent(String(expertId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find all Company Experts
         * @param {string} companyId 
         * @param {number} [page] Page number, default is 1
         * @param {number} [size] Page size, default is 50
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllAdminCompanyExperts: async (companyId: string, page?: number, size?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('findAllAdminCompanyExperts', 'companyId', companyId)
            const localVarPath = `/api/v2/admin/companies/{companyId}/experts`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a Company Expert.
         * @param {string} companyId 
         * @param {string} expertId 
         * @param {AdminCompanyExpertUpdate} adminCompanyExpertUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAdminCompanyExpert: async (companyId: string, expertId: string, adminCompanyExpertUpdate: AdminCompanyExpertUpdate, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('updateAdminCompanyExpert', 'companyId', companyId)
            // verify required parameter 'expertId' is not null or undefined
            assertParamExists('updateAdminCompanyExpert', 'expertId', expertId)
            // verify required parameter 'adminCompanyExpertUpdate' is not null or undefined
            assertParamExists('updateAdminCompanyExpert', 'adminCompanyExpertUpdate', adminCompanyExpertUpdate)
            const localVarPath = `/api/v2/admin/companies/{companyId}/experts/{expertId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"expertId"}}`, encodeURIComponent(String(expertId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminCompanyExpertUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminCompanyExpertsApi - functional programming interface
 * @export
 */
export const AdminCompanyExpertsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminCompanyExpertsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create a Company Expert.
         * @param {string} companyId 
         * @param {AdminCompanyExpertCreate} adminCompanyExpertCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAdminCompanyExpert(companyId: string, adminCompanyExpertCreate: AdminCompanyExpertCreate, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminCompanyExpert>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createAdminCompanyExpert(companyId, adminCompanyExpertCreate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminCompanyExpertsApi.createAdminCompanyExpert']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Delete a Company Expert
         * @param {string} companyId 
         * @param {string} expertId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAdminCompanyExpert(companyId: string, expertId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAdminCompanyExpert(companyId, expertId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminCompanyExpertsApi.deleteAdminCompanyExpert']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Find a Company Expert by ID
         * @param {string} companyId 
         * @param {string} expertId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAdminCompanyExpertById(companyId: string, expertId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminCompanyExpert>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAdminCompanyExpertById(companyId, expertId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminCompanyExpertsApi.findAdminCompanyExpertById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Find all Company Experts
         * @param {string} companyId 
         * @param {number} [page] Page number, default is 1
         * @param {number} [size] Page size, default is 50
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAllAdminCompanyExperts(companyId: string, page?: number, size?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminCompanyExpertPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAllAdminCompanyExperts(companyId, page, size, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminCompanyExpertsApi.findAllAdminCompanyExperts']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update a Company Expert.
         * @param {string} companyId 
         * @param {string} expertId 
         * @param {AdminCompanyExpertUpdate} adminCompanyExpertUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAdminCompanyExpert(companyId: string, expertId: string, adminCompanyExpertUpdate: AdminCompanyExpertUpdate, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminCompanyExpert>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAdminCompanyExpert(companyId, expertId, adminCompanyExpertUpdate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminCompanyExpertsApi.updateAdminCompanyExpert']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AdminCompanyExpertsApi - factory interface
 * @export
 */
export const AdminCompanyExpertsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminCompanyExpertsApiFp(configuration)
    return {
        /**
         * 
         * @summary Create a Company Expert.
         * @param {AdminCompanyExpertsApiCreateAdminCompanyExpertRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAdminCompanyExpert(requestParameters: AdminCompanyExpertsApiCreateAdminCompanyExpertRequest, options?: RawAxiosRequestConfig): AxiosPromise<AdminCompanyExpert> {
            return localVarFp.createAdminCompanyExpert(requestParameters.companyId, requestParameters.adminCompanyExpertCreate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a Company Expert
         * @param {AdminCompanyExpertsApiDeleteAdminCompanyExpertRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAdminCompanyExpert(requestParameters: AdminCompanyExpertsApiDeleteAdminCompanyExpertRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteAdminCompanyExpert(requestParameters.companyId, requestParameters.expertId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find a Company Expert by ID
         * @param {AdminCompanyExpertsApiFindAdminCompanyExpertByIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAdminCompanyExpertById(requestParameters: AdminCompanyExpertsApiFindAdminCompanyExpertByIdRequest, options?: RawAxiosRequestConfig): AxiosPromise<AdminCompanyExpert> {
            return localVarFp.findAdminCompanyExpertById(requestParameters.companyId, requestParameters.expertId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find all Company Experts
         * @param {AdminCompanyExpertsApiFindAllAdminCompanyExpertsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllAdminCompanyExperts(requestParameters: AdminCompanyExpertsApiFindAllAdminCompanyExpertsRequest, options?: RawAxiosRequestConfig): AxiosPromise<AdminCompanyExpertPage> {
            return localVarFp.findAllAdminCompanyExperts(requestParameters.companyId, requestParameters.page, requestParameters.size, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a Company Expert.
         * @param {AdminCompanyExpertsApiUpdateAdminCompanyExpertRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAdminCompanyExpert(requestParameters: AdminCompanyExpertsApiUpdateAdminCompanyExpertRequest, options?: RawAxiosRequestConfig): AxiosPromise<AdminCompanyExpert> {
            return localVarFp.updateAdminCompanyExpert(requestParameters.companyId, requestParameters.expertId, requestParameters.adminCompanyExpertUpdate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createAdminCompanyExpert operation in AdminCompanyExpertsApi.
 * @export
 * @interface AdminCompanyExpertsApiCreateAdminCompanyExpertRequest
 */
export interface AdminCompanyExpertsApiCreateAdminCompanyExpertRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminCompanyExpertsApiCreateAdminCompanyExpert
     */
    readonly companyId: string

    /**
     * 
     * @type {AdminCompanyExpertCreate}
     * @memberof AdminCompanyExpertsApiCreateAdminCompanyExpert
     */
    readonly adminCompanyExpertCreate: AdminCompanyExpertCreate
}

/**
 * Request parameters for deleteAdminCompanyExpert operation in AdminCompanyExpertsApi.
 * @export
 * @interface AdminCompanyExpertsApiDeleteAdminCompanyExpertRequest
 */
export interface AdminCompanyExpertsApiDeleteAdminCompanyExpertRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminCompanyExpertsApiDeleteAdminCompanyExpert
     */
    readonly companyId: string

    /**
     * 
     * @type {string}
     * @memberof AdminCompanyExpertsApiDeleteAdminCompanyExpert
     */
    readonly expertId: string
}

/**
 * Request parameters for findAdminCompanyExpertById operation in AdminCompanyExpertsApi.
 * @export
 * @interface AdminCompanyExpertsApiFindAdminCompanyExpertByIdRequest
 */
export interface AdminCompanyExpertsApiFindAdminCompanyExpertByIdRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminCompanyExpertsApiFindAdminCompanyExpertById
     */
    readonly companyId: string

    /**
     * 
     * @type {string}
     * @memberof AdminCompanyExpertsApiFindAdminCompanyExpertById
     */
    readonly expertId: string
}

/**
 * Request parameters for findAllAdminCompanyExperts operation in AdminCompanyExpertsApi.
 * @export
 * @interface AdminCompanyExpertsApiFindAllAdminCompanyExpertsRequest
 */
export interface AdminCompanyExpertsApiFindAllAdminCompanyExpertsRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminCompanyExpertsApiFindAllAdminCompanyExperts
     */
    readonly companyId: string

    /**
     * Page number, default is 1
     * @type {number}
     * @memberof AdminCompanyExpertsApiFindAllAdminCompanyExperts
     */
    readonly page?: number

    /**
     * Page size, default is 50
     * @type {number}
     * @memberof AdminCompanyExpertsApiFindAllAdminCompanyExperts
     */
    readonly size?: number
}

/**
 * Request parameters for updateAdminCompanyExpert operation in AdminCompanyExpertsApi.
 * @export
 * @interface AdminCompanyExpertsApiUpdateAdminCompanyExpertRequest
 */
export interface AdminCompanyExpertsApiUpdateAdminCompanyExpertRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminCompanyExpertsApiUpdateAdminCompanyExpert
     */
    readonly companyId: string

    /**
     * 
     * @type {string}
     * @memberof AdminCompanyExpertsApiUpdateAdminCompanyExpert
     */
    readonly expertId: string

    /**
     * 
     * @type {AdminCompanyExpertUpdate}
     * @memberof AdminCompanyExpertsApiUpdateAdminCompanyExpert
     */
    readonly adminCompanyExpertUpdate: AdminCompanyExpertUpdate
}

/**
 * AdminCompanyExpertsApi - object-oriented interface
 * @export
 * @class AdminCompanyExpertsApi
 * @extends {BaseAPI}
 */
export class AdminCompanyExpertsApi extends BaseAPI {
    /**
     * 
     * @summary Create a Company Expert.
     * @param {AdminCompanyExpertsApiCreateAdminCompanyExpertRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminCompanyExpertsApi
     */
    public createAdminCompanyExpert(requestParameters: AdminCompanyExpertsApiCreateAdminCompanyExpertRequest, options?: RawAxiosRequestConfig) {
        return AdminCompanyExpertsApiFp(this.configuration).createAdminCompanyExpert(requestParameters.companyId, requestParameters.adminCompanyExpertCreate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a Company Expert
     * @param {AdminCompanyExpertsApiDeleteAdminCompanyExpertRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminCompanyExpertsApi
     */
    public deleteAdminCompanyExpert(requestParameters: AdminCompanyExpertsApiDeleteAdminCompanyExpertRequest, options?: RawAxiosRequestConfig) {
        return AdminCompanyExpertsApiFp(this.configuration).deleteAdminCompanyExpert(requestParameters.companyId, requestParameters.expertId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find a Company Expert by ID
     * @param {AdminCompanyExpertsApiFindAdminCompanyExpertByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminCompanyExpertsApi
     */
    public findAdminCompanyExpertById(requestParameters: AdminCompanyExpertsApiFindAdminCompanyExpertByIdRequest, options?: RawAxiosRequestConfig) {
        return AdminCompanyExpertsApiFp(this.configuration).findAdminCompanyExpertById(requestParameters.companyId, requestParameters.expertId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find all Company Experts
     * @param {AdminCompanyExpertsApiFindAllAdminCompanyExpertsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminCompanyExpertsApi
     */
    public findAllAdminCompanyExperts(requestParameters: AdminCompanyExpertsApiFindAllAdminCompanyExpertsRequest, options?: RawAxiosRequestConfig) {
        return AdminCompanyExpertsApiFp(this.configuration).findAllAdminCompanyExperts(requestParameters.companyId, requestParameters.page, requestParameters.size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a Company Expert.
     * @param {AdminCompanyExpertsApiUpdateAdminCompanyExpertRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminCompanyExpertsApi
     */
    public updateAdminCompanyExpert(requestParameters: AdminCompanyExpertsApiUpdateAdminCompanyExpertRequest, options?: RawAxiosRequestConfig) {
        return AdminCompanyExpertsApiFp(this.configuration).updateAdminCompanyExpert(requestParameters.companyId, requestParameters.expertId, requestParameters.adminCompanyExpertUpdate, options).then((request) => request(this.axios, this.basePath));
    }
}

